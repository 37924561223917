import React, { useState } from "react";
import { FaChevronCircleLeft, FaChevronLeft } from "react-icons/fa";
import "../../lecture/lecture-video/LectureVideo.scss";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-hot-toast";
import LiveLectureDetailSkeleton from "components/skeleton/LiveLectureDetailSkeleton";
import { getUserIp } from "services/third-party";
import { errorResponse, failResponse, normalDecryptData } from "config/config";
import { useCourse } from "context/context";
import { gpsDisabled } from "assets/images";
import LectureNotFound from "components/Not-Found/LectureNotFound";
import { lectureTimeTracking, userLectureDetail } from "services/lecture";
import OnDemandPlayer from "components/video-player/OnDemandPlayer";

let MAIN_PERCENTAGE = 100;

function OnDemandLectureDetail() {
  const [loading, setLoading] = useState(false);
  const [lectureDetail, setLectureDetail] = useState({});
  const [seekBar, setSeekBar] = useState(0);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [accessStatus, setAccessStatus] = useState(true);
  const [locationStatus, setLocationStatus] = useState(true);

  const { sectionId, lectureId } = useParams();
  const { course } = useCourse();
  const location = useLocation();
  const navigate = useNavigate();
  // const baseUrl = window.location.protocol + "//" + window.location.host;

  const mainLectureId = normalDecryptData(lectureId, "route")?.split(",");

  // next functionality with 100 percentage completion
  const lectureCompleteHandler = async (type = "duration") => {
    if (mainLectureId[1]) {
      try {
        const { data: ip } = await getUserIp();
        const res = await lectureTimeTracking({
          Platform: "web",
          courseId: course.courseId,
          IPAddress: ip.ip,
          playTime: 0,
          TotalCoverdTime: 0,
          TotalPlayedTime: 0,
          lastPlay: 0,
          lectureId: mainLectureId[1],
          sectionId,
        });

        if (res.code === 200) {
          if (type === "allocate") {
            navigate(`/dashboard/lecture/${sectionId}`);
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    } else {
      try {
        const { data: ip } = await getUserIp();
        const res = await lectureTimeTracking({
          Platform: "web",
          courseId: course.courseId,
          IPAddress: ip.ip,
          playTime: 0,
          TotalCoverdTime: 0,
          TotalPlayedTime: 0,
          lastPlay: 0,
          lectureId: mainLectureId[0],
          sectionId,
        });

        if (res.code === 200) {
          navigate(`/dashboard/lecture/${sectionId}`);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    }
  };

  // ----------------------- getting lecture detail data -------------------------//
  React.useLayoutEffect(() => {
    // get lecture series detail
    const getLectureDetails = async (lat, long) => {
      try {
        setLoading(true);

        const { data: ip } = await getUserIp();

        const res = await userLectureDetail({
          lecture_id: mainLectureId[0],
          section_id: sectionId,
          is_exceptional: 0,
          courseId: course.courseId,
          latitude: lat,
          longitude: long,
          ip: ip.ip,
        });

        if (res.code === 200) {
          setLectureDetail(res.data.lectureDetail?.LectureDetail || {});
          setSeekBar(+res.data.lectureDetail?.lastPlay || 0);
          if (!res.data.lectureDetail?.LectureDetail?.IsYoutube) {
            setOtp(res.data.vdocipherOtp.otp);
            setPlaybackInfo(res.data.vdocipherOtp.playbackInfo);
          }
          setLoading(false);
        } else if (res.code === 101) {
          setAccessStatus(false);
          failResponse(res);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    if (course.courseId) {
      // checks gps is enabled
      if (course.isGps) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
      }
      // if gps enable is false
      if (!course.isGps) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
        //getLectureDetails();
      }
    }

    // eslint-disable-next-line
  }, [course]);

  return (
    <>
      {accessStatus ? (
        <>
          {!location?.state?.auth ? (
            <Navigate to="/dashboard/home" />
          ) : (
            <section className="LectureVideo liveLecture_video spacing">
              <div className="container">
                {/* loading component */}
                {loading && <LiveLectureDetailSkeleton />}

                {/* main component */}
                {!loading && (
                  <>
                    <div className="course_video">
                      <div className="video_wrap">
                        {/* video crypt video player */}
                        {playbackInfo && otp && (
                          <>
                            <OnDemandPlayer
                              otp={otp}
                              playbackInfo={playbackInfo}
                              isTimeSpentApplicable={true}
                              autoPlay={true}
                              sectionId={sectionId}
                              MAIN_PERCENTAGE={MAIN_PERCENTAGE}
                              lectureDetail={lectureDetail}
                              onClose={() => {}}
                              lastSeekBar={seekBar}
                              lectureCompleteHandler={lectureCompleteHandler}
                            />
                          </>
                        )}

                        <div className="about_video">
                          <div className="content">
                            <h5>{lectureDetail.LectureTitle}</h5>
                          </div>
                        </div>
                        <div className="back_arrow">
                          <Link
                            to={`/dashboard/lecture/${sectionId}`}
                            className="dashboard_button has_arrow next"
                          >
                            <FaChevronLeft /> back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          )}
        </>
      ) : (
        <section className="LectureVideo liveLecture_video spacing">
          <div className="container">
            <div className="course_video">
              <div className="video_wrap">
                <LectureNotFound
                  note={
                    locationStatus
                      ? `This live lecture is not allowed to play at this location`
                      : `location is required, please allow location`
                  }
                  image={gpsDisabled}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default OnDemandLectureDetail;
