import React, { useState } from "react";

import { BsFillPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { toast } from "react-hot-toast";
import {
  countArray,
  momentDateFormat,
  testAccess,
  testIsExpired,
  testIsStarted,
  timeFormat,
  timeFormatWith12Hour,
} from "config/utils";
import { exceptionalLectureList } from "services/lecture";
import { useCourse } from "context/context";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import moment from "moment";
import Pagination from "react-js-pagination";
import { getSignedUrl } from "services/master";

const AppliedExceptional = () => {
  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);

  // get course lectures
  const getLecturesList = async () => {
    try {
      setLoading(true);

      const res = await exceptionalLectureList({
        type: 2,
        course_id: course.courseId,
        page: activePage,
      });

      if (res.code === 200) {
        const { exceptionalLecture, count } = res.data;

        setListLectures(exceptionalLecture || []);
        setTotal(count || 0);

        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // navigation handler
  const navigationHandler = (id, startDate, endDate) => {
    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (testIsStarted(startDate)) {
      toast.error("Lecture is not started");
      return;
    }
    if (testIsExpired(endDate)) {
      toast.error("Lecture is expired");
      return;
    }
    if (testAccess(startDate, endDate)) {
      navigate(
        `/dashboard/lecture/detail/${normalEncryptData(`${id},1`, "route")}`,
        {
          state: { auth: true },
        }
      );
    }
  };

  // notes download handler
  const notesDownloadHandler = async (notes, startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      setSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: notes }));
      setSubmitLoading(false);
    } else {
      toast.error("you can't download notes");
    }
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useLayoutEffect(() => {
    if (course.courseId) {
      getLecturesList();
    }
    // eslint-disable-next-line
  }, [course, activePage]);

  return (
    <>
      <div className="daily_lecture">
        <div className="meeting_content">
          <ul>
            <li className="lecture-card heading">
              <h6>Lecture Name</h6>
              <div className="play_btn">
                <b>Video</b>
              </div>
              <div className="date">
                <b>Applied Date</b>
              </div>
              <div className="date">
                <b>End Date</b>
              </div>

              <div className="date">
                <b>Class Notes</b>
              </div>
            </li>
            {/* ************ Loading Component *********** */}
            {loading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <li key={data} className="p-0">
                      <ListCourseSkeleton height="90" />
                    </li>
                  );
                })}
              </>
            )}
            {/* iterate lectures */}
            {!loading && listLectures.length > 0 ? (
              listLectures.map(
                ({ exceptionalLectureData, courseLectures }, i) => {
                  return (
                    <li
                      key={i}
                      className={`lecture-card ${
                        !testAccess(
                          exceptionalLectureData?.ScheduleDate,
                          moment(exceptionalLectureData?.ScheduleDate).add(
                            24,
                            "hours"
                          )
                        ) && "disabled"
                      }`}
                    >
                      <h6
                        onClick={() =>
                          navigationHandler(
                            exceptionalLectureData?.LectureID,
                            exceptionalLectureData?.ScheduleDate,
                            exceptionalLectureData?.EndDate
                          )
                        }
                      >
                        {courseLectures?.LectureDetail?.LectureTitle || "N/A"}
                      </h6>
                      <div className="play_btn">
                        <span
                          onClick={() =>
                            navigationHandler(
                              exceptionalLectureData?.LectureID,
                              exceptionalLectureData?.ScheduleDate,
                              exceptionalLectureData?.EndDate
                            )
                          }
                        >
                          <span className="play-icon">
                            <BsFillPlayFill />
                          </span>
                        </span>
                      </div>
                      <div className="date">
                        <b>
                          {`${momentDateFormat(
                            exceptionalLectureData?.ScheduleDate
                          )}`}
                        </b>
                        <br />
                        <b>
                          {`${timeFormatWith12Hour(
                            timeFormat(exceptionalLectureData?.ScheduleDate)
                          )}`}
                        </b>
                      </div>
                      <div className="date red">
                        <b>
                          {`${momentDateFormat(
                            exceptionalLectureData?.EndDate
                          )}`}
                        </b>
                        <br />
                        <b>
                          {`${timeFormatWith12Hour(
                            timeFormat(exceptionalLectureData?.ScheduleDate)
                          )}`}
                        </b>
                      </div>

                      <div className="date">
                        <button
                          className="button px-2"
                          onClick={() =>
                            notesDownloadHandler(
                              courseLectures?.LectureDetail?.ClassNotes,
                              exceptionalLectureData?.ScheduleDate,
                              exceptionalLectureData?.EndDate
                            )
                          }
                          disabled={
                            !testAccess(
                              exceptionalLectureData?.ScheduleDate,
                              exceptionalLectureData?.EndDate
                            ) ||
                            !courseLectures?.LectureDetail?.ClassNotes ||
                            submitLoading
                          }
                        >
                          Download
                        </button>
                      </div>
                    </li>
                  );
                }
              )
            ) : (
              <>
                <LectureNoteFound title="Lectures" />
              </>
            )}
          </ul>
        </div>
      </div>
      {/* pagination */}
      {total > 10 ? (
        <div className="pagination_wrap">
          <Pagination
            activePage={activePage}
            previousLabel={"previous"}
            nextLabel={"next"}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            activeLinkClass={"active"}
            onChange={(page) => setActivePage(page)}
          />
        </div>
      ) : null}
    </>
  );
};

export default AppliedExceptional;
