import React from "react";
import QuizResult from "./QuizResult";
import SuperTag from "components/SuperTag/SuperTag";
import { ALPHABETS_COUNT, countArray } from "config/utils";

const QuizSummery = ({ attemptedAnswers = [] }) => {
  const classFinder = (option, correct, attempt) => {
    if (option === correct) {
      return "correct_ans";
    }
    if (option === attempt) {
      return "selected_ans";
    }
  };

  return (
    <>
      <div className="Quiz_summery_wrap">
        <QuizResult attemptedAnswers={attemptedAnswers} />
        <div className="Quiz_summery">
          {attemptedAnswers &&
            attemptedAnswers.map((question, i) => {
              return (
                <div className="question" key={question.id}>
                  <p>
                    <SuperTag>{`Q${i + 1}. ${question.question}`}</SuperTag>
                  </p>
                  <ul className="option">
                    {countArray(4).map((data) => {
                      return (
                        <li
                          className={classFinder(
                            ALPHABETS_COUNT[data],
                            question.correctOption,
                            question.attemptAnswer
                          )}
                        >
                          {question?.[`option${ALPHABETS_COUNT[data]}`]}
                        </li>
                      );
                    })}

                    {/* <li className="selected_ans">Bal Gangadhar Tilak</li> */}
                  </ul>
                  <p className="explanation">
                    <SuperTag>{question.description}</SuperTag>
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default QuizSummery;
