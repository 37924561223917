import React, { useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaQuestion } from "react-icons/fa";
import { GoogleBlogimg } from "assets/icons";
import LearnWithToppers from "components/learnwith-toppers/LearnWithToppers";
import DatePicker from "react-datepicker";
import "./DailyQuiz.scss";
import { subDays } from "config/dayUtils";
import toast from "react-hot-toast";
import { getQuizQuestions } from "services/dailyQuiz";
import moment from "moment";
import SuperTag from "components/SuperTag/SuperTag";
import { ALPHABETS_COUNT, countArray } from "config/utils";
import QuizSummery from "./section/QuizSummery";
import QuizResult from "./section/QuizResult";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { QuizImage } from "assets/images";
import { errorResponse, failResponse } from "config/config";

const learnWithToppers = {
  image: GoogleBlogimg,
  title: "Learn with the nation’s top educators ",
  description:
    "Enroll yourself today with India’s best Civil Services Coaching center",
  buttonText: "Register Today",
  buttonLink: "/signup",
};

const DailyQuiz = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [totalDays, setTotalDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);

  const [step, setStep] = useState(0);

  const quizSectionRef = useRef(null);

  const [screen, setScreen] = useState("quiz");

  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [attemptAnswer, setAttemptAnswer] = useState("");

  //   next handler
  const nextHandler = () => {
    // switch to next question
    if (!attemptAnswer) {
      toast.error("Please select options");
    } else {
      const data = allQuestions[step];
      setSelectedAnswers([...selectedAnswers, { ...data, attemptAnswer }]);
      setAttemptAnswer("");
    }
    if (attemptAnswer) {
      if (step + 1 !== allQuestions.length) {
        setStep(step + 1);
      }

      // display result if all question is attempt
      if (step + 1 === allQuestions.length) {
        setScreen("result");
      }
    }
  };

  //   option select handler
  const optionSelectHandler = (value) => {
    setAttemptAnswer(value);
  };

  // ----------------------- get all the quiz -------------------------//
  const getMainQuizQuestions = async (date) => {
    try {
      setLoading(true);
      const res = await getQuizQuestions({
        currentDate: moment(date).format("YYYY-MM-DD"),
      });

      if (res.code === 200) {
        setAllQuestions(res.data?.dailyQuiz?.questions || []);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // date selection handle
  const dateSelectionHandler = (date) => {
    setStartDate(date);
    getMainQuizQuestions(date);
    setScreen("quiz");
    setSelectedAnswers([]);
    setAttemptAnswer("");
    setStep(0);
  };

  React.useLayoutEffect(() => {
    // get quiz dates
    const getMainQuizQuestions = async () => {
      try {
        setCalendarLoading(true);
        setLoading(true);
        const res = await getQuizQuestions({
          currentDate: moment(startDate).format("YYYY-MM-DD"),
        });

        if (res.code === 200) {
          setAllQuestions(res.data?.dailyQuiz?.questions || []);
          setTotalDays(res.data?.dailyQuizDateLimit);
          setCalendarLoading(false);
          setLoading(false);
        } else {
          failResponse(res);
          setCalendarLoading(false);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setCalendarLoading(false);
        setLoading(false);
      }
    };

    getMainQuizQuestions();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    quizSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [screen]);

  return (
    <div className="dailyQuiz">
      {/* Banner  */}
      <div className="common_banner spacing">
        <div className="container">
          <div className="banner_content">
            <h3>THE DAILY QUIZ</h3>
            <p>
              Take our daily quiz & help sharpen your skills for the upcoming
              exams.
            </p>
          </div>
        </div>
      </div>
      <div className="dailyQuiz_section spacing" ref={quizSectionRef}>
        <div className="container">
          <div className="quiz_wrapper">
            <div className="date_section">
              <h5>View QUIZ BY DATE</h5>
              <div className="form_field">
                {calendarLoading ? (
                  <CourseSkeleton width={60} height={270} />
                ) : (
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => dateSelectionHandler(date)}
                    minDate={subDays(new Date(), totalDays)}
                    maxDate={new Date()}
                    inline
                  />
                )}
              </div>
            </div>

            {/* loading component */}
            {loading && (
              <div className="question_section">
                <div className="content">
                  <CourseSkeleton width={100} height={500} />
                </div>
              </div>
            )}

            {/* main component */}
            {!loading && (
              <div className="question_section">
                <div className="content">
                  {/* if data is available */}
                  {allQuestions.length > 0 && (
                    <>
                      {/* quiz attempt screen */}
                      {screen === "quiz" && (
                        <>
                          <div className="question_header">
                            <span className="icon">
                              <FaQuestion />
                            </span>
                            <div className="text">
                              <h5>Quiz Instructions</h5>
                              <p>
                                The quiz consists of {allQuestions.length}{" "}
                                MCQ’s. You can view the results after answering
                                all{" "}
                                {`${allQuestions.length} / ${allQuestions.length}`}
                                questions.
                              </p>
                            </div>
                          </div>
                          <div className="questions">
                            <b>
                              Question {step + 1} of {allQuestions.length} :
                            </b>
                            <p>
                              <SuperTag>
                                {allQuestions[step]?.question}
                              </SuperTag>
                            </p>

                            <ul>
                              {countArray(4).map((data) => {
                                return (
                                  <li key={data}>
                                    <input
                                      type="radio"
                                      name="quiz"
                                      id={`question${data}`}
                                      checked={
                                        attemptAnswer ===
                                        `${ALPHABETS_COUNT[data]}`
                                      }
                                      onChange={() =>
                                        optionSelectHandler(
                                          `${ALPHABETS_COUNT[data]}`
                                        )
                                      }
                                    />
                                    <label htmlFor={`question${data}`}>
                                      {
                                        allQuestions[step]?.[
                                          `option${ALPHABETS_COUNT[data]}`
                                        ]
                                      }
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>

                            <div className="button_wrap padding_bottom  padding">
                              <button
                                onClick={nextHandler}
                                className="button"
                                disabled={!attemptAnswer}
                              >
                                Next
                                <span className="icon">
                                  <BsArrowRight />
                                </span>
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {/* Quiz Result */}
                      {screen === "result" && (
                        <QuizResult
                          attemptedAnswers={selectedAnswers}
                          showResult={() => setScreen("summary")}
                        />
                      )}

                      {/* Quiz Quiz Summery */}
                      {screen === "summary" && (
                        <QuizSummery attemptedAnswers={selectedAnswers} />
                      )}
                    </>
                  )}

                  {/* if data is not available */}
                  {allQuestions.length <= 0 && (
                    <div className="quizNotFound">
                      <div className="image_wrap">
                        <img src={QuizImage} alt="Not Found" loading="lazy" />
                      </div>
                      <h5>Quiz not available</h5>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Learn with the nation’s top educators  */}
      <LearnWithToppers props={learnWithToppers} />
      {/* Other free resources */}
      {/*  <OtherFreeResource /> */}
    </div>
  );
};

export default DailyQuiz;
