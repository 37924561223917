import React, { useState, useEffect } from "react";
import { MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import "./EditProfile.scss";
import { userProfile, updatekycflagbyStudent } from "services/dashboard/index";
import { Select, TextArea } from "components/Form";
import { getCourseVerticals, getAllStates, getdetailsbyPincode } from "services/master";
import { CourseBookImg } from "assets/images";
import { BiRupee } from "react-icons/bi";
import { FcTabletAndroid } from "react-icons/fc";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';


import useCookie from "hooks/useCookie";
import {
    useNavigate, useNavigationType,
    useParams, Link,

} from "react-router-dom";
import CourseSubscriptionSkeleton from "components/skeleton/CourseSubscriptionSkeleton";
import { Input } from "components/Form";

import { addressFormatter } from "config/utils";
import UpdateDrawer from "./UpdateDrawer";
import UploadDoc from "./UploadDoc";
import CommonModal from "components/modal-box/CommonModal";
import { FaExclamationCircle, FaUserCircle } from "react-icons/fa";
import CoursePackages from "./CoursePackages";
import { userLogout } from "services/auth";
import { useCourse } from "context/context";
import useButtonLoader from "hooks/useButtonLoader";
import { errorResponse, failResponse } from "config/config";
import useDebounce from "hooks/useDebounce";
import { useAddons } from "context/context";
import { updateUserProfile } from "services/dashboard";





function UpdateKYCDetails() {
    const [show, setShow] = useState(false);
    const [logout, setLogout] = useState(false);
    // const [file, setFileModal] = useState(false);

    const { setCourse } = useCourse();


    const [userUpdateData, setUserUpdateData] = useState({});

    const { removeCookie } = useCookie();

    //   edit profile off canvas handler
    const openUpdateUserProfile = () => {
        setUserUpdateData(userData);
        setShow(true);
    };

    //   logout handler function
    const logoutHandler = async () => {
        try {
            setSubmitLoading(true);
            const res = await userLogout();
            if (res.code === 200) {
                removeCookie();
                sessionStorage.removeItem("course");
                setCourse({});
                navigate("/");
                setSubmitLoading(false);
            } else {
                failResponse(res);
                setSubmitLoading(false);
            }
        } catch (err) {
            setSubmitLoading(false);
            errorResponse(err);
        }
    };

    // get user profile
    const getUserProfile = async () => {
        try {
            const res = await userProfile();
            //const { IDProof, addressProof,IDPhoto, ...rest } = userData;
            if (res.code === 200) {
                setUserData(res.data.userDetails || []);
                //setUserData({ ...res.data.userDetails, ...rest } || {});

                // checks user's profile is editable or not
                setIsEditable({
                    profile: res.data.userDetails.PHouseNo ? false : true,
                    idProof: res.data.userDetails.IDProof ? false : true,
                    addressProof: res.data.userDetails.addressProof ? false : true,
                });
            } else {
                failResponse(res);
            }
        } catch (err) {
            errorResponse(err);
        }
    };

    const { courseId, courseName } = useParams();
    const navType = useNavigationType();
    const navigate = useNavigate();
    const [couponToggle, setCouponToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [appliedCoupon, setAppliedCoupon] = useState("");
    const [couponList, setCouponList] = useState([]);

    const [tabData, setTabData] = useState([]);
    const [tablet, setTablet] = useState(true);

    const debouncedTablet = useDebounce(tablet);

    const [isReadOnly, setIsReadOnly] = useState(false);


    const [courseDetails, setCourseDetails] = useState({
        admissionCenter: "",
        conveyanceFee: 0,
        couponCode: "",
        couponCodeApplied: 0,
        courseFee: 0,
        gst: 0,
        isOldStudent: 0,
        newStudentCourseFee: 0,
        oldStudentCourseFee: 0,
        title: " English",
        total: 0,
        gstFee: 0,
    });

    const { addons } = useAddons();
    const [buttonRef, setSubmitLoading] = useButtonLoader("Save");

    const [isEditable, setIsEditable] = useState({
        // profile: false,
        idProof: false,
        addressProof: false,
        idphoto: false
    });

    const [userData, setUserData] = useState({});
    const [mobError, setMobError] = useState("");
    const [emobError, seteMobError] = useState("");
    const [allStates, setallStates] = useState([]);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");



    const [errorField, setErrorField] = React.useState({
        firstName: "",
        lastName: "",
        Mobile: "",
        EmergencyContactNo: "",
        Education: "",
        PHouseNo: "",
        PStreet: "",
        PLandmark: "",
        PCity: "",
        PState: "",
        PZipCode: "",
        THouseNo: "",
        TStreet: "",
        TLandmark: "",
        TCity: "",
        TState: "",
        TZipCode: "",
        IDProof: "",
        IDPhoto: "",
        addressProof: ""
    });

    const [isSameAddress, setIsSameAddress] = React.useState(
        userData.PHouseNo === userData.THouseNo &&
        userData.PStreet === userData.TStreet &&
        userData.PLandmark === userData.TLandmark &&
        userData.PCity === userData.TCity &&
        userData.PState === userData.TState &&
        userData.PZipCode === userData.TZipCode
    );

    //*********************Handel Validation********************************** */
    const handleValidation = () => {
        let errorField = {};
        let formIsValid = true;

        if (!userData.firstName) {
            errorField["firstName"] = "Please Enter First Name";
            formIsValid = false;
        }
        if (!userData.lastName) {
            errorField["lastName"] = "Please Enter Last Name";
            formIsValid = false;
        }

        if (!userData.Mobile) {
            errorField["Mobile"] = "Please Enter Mobile No.";
            formIsValid = false;
        }
        if (!userData.EmailID) {
            errorField["EmailID"] = "Please Enter Email ID";
            formIsValid = false;
        }
        if (!userData.EmergencyContactNo) {
            errorField["EmergencyContactNo"] = "Please Enter Emergency No.";
            formIsValid = false;
        }
        if (!userData.Education) {
            errorField["Education"] = "Please Enter Education";
            formIsValid = false;
        }

        if (!userData.PHouseNo) {
            errorField["PHouseNo"] = "Please Enter House No.";
            formIsValid = false;
        }
        if (!userData.PStreet) {
            errorField["PStreet"] = "Please Enter Street Name";
            formIsValid = false;
        }
        if (!userData.PLandmark) {
            errorField["PLandmark"] = "Please Enter Landmark";
            formIsValid = false;
        }
        if (!userData.PCity) {
            errorField["PCity"] = "Please Enter City";
            formIsValid = false;
        }
        if (!userData.PState) {
            errorField["PState"] = "Please Enter State";
            formIsValid = false;
        }
        if (!userData.PZipCode) {
            errorField["PZipCode"] = "Please Enter Zipcode";
            formIsValid = false;
        }

        if (!userData.THouseNo) {
            errorField["THouseNo"] = "Please Enter House No.";
            formIsValid = false;
        }
        if (!userData.TStreet) {
            errorField["TStreet"] = "Please Enter Street Name";
            formIsValid = false;
        }
        if (!userData.TLandmark) {
            errorField["TLandmark"] = "Please Enter Landmark";
            formIsValid = false;
        }
        if (!userData.TCity) {
            errorField["TCity"] = "Please Enter City";
            formIsValid = false;
        }
        if (!userData.TState) {
            errorField["TState"] = "Please Enter State";
            formIsValid = false;
        }
        if (!userData.TZipCode) {
            errorField["TZipCode"] = "Please Enter Zipcode";
            formIsValid = false;
        }

        if (isEditable.idProof) {
            formIsValid = false;
        }

        if (isEditable.addressProof) {
            formIsValid = false;
        }

        if (isEditable.idphoto) {
            formIsValid = false;
        }

        setErrorField(errorField);
        return formIsValid;
    };

    //   onchange handler for edit profile
    const onChangeHandler = (e) => {
        const { name, value } = e.target;

        // Define a function to update the state with sanitized values
        const sanitizeInput = (name, value) => {
            let updatedValue = value;

            if (["TZipCode", "PZipCode", "EmergencyContactNo"].includes(name)) {
                updatedValue = value.replace(/\D/g, ""); // Remove non-digit characters
            }

            if (["TState", "PState", "TCity", "PCity"].includes(name)) {
                updatedValue = value.replace(/[^A-Za-z ]/g, "").replace(/\s+/g, " "); // Allow only letters and spaces
            }

            return updatedValue;
        };
        if (name === "TZipCode") {
            const pcode = value.replace(/\D/g, ""); // Replace 'name' with 'value' to get the actual input value

            // Mobile number validation
            const getdetailsbypincode = async (pincode = pcode) => {
                try {
                    const data = { pincode: pincode };
                    const res = await getdetailsbyPincode(data);
                    if (res.code === 200) {
                        const stateValue = res.data.State || [];
                        const cityValue = res.data.City || [];
                        setState(stateValue);
                        setCity(cityValue);
                        onChangeHandler({ target: { name: "TState", value: stateValue } });
                        onChangeHandler({ target: { name: "TCity", value: cityValue } });
                    } else {
                        console.log(res.message);
                    }
                } catch (err) {
                    console.log(err?.response?.data?.message || err.message);
                }
            };

            getdetailsbypincode(pcode); // Call the function with the pincode
        }


        if (name === "Mobile") {
            // Mobile number validation
            const numericValue = value.replace(/\D/g, "");
            if (numericValue.length === 10) {
                setMobError("");
            } else {
                setMobError("Mobile number must be 10 digits");
            }
        }
        if (name === "EmergencyContactNo") {
            // Mobile number validation
            const numericValue = value.replace(/\D/g, "");
            if (numericValue.length === 10) {
                seteMobError("");
            } else {
                seteMobError("Mobile number must be 10 digits");
            }
        }
        // Sanitize the input value
        const sanitizedValue = sanitizeInput(name, value);

        // Update the state with sanitized values
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: sanitizedValue,
        }));

        // If same address flag is true and one of the temporary address fields is changed, set the flag to false
        if (isSameAddress && ["THouseNo", "TState", "TCity", "TZipCode", "TLandmark", "TStreet"].includes(name)) {
            setIsSameAddress(false);
        }
    };

    const handleProfileUpdate = async () => {
        const res = await updateUserProfile({
            firstName: userData.firstName,
            middleName: userData.middleName,
            lastName: userData.lastName,
            Mobile: userData.Mobile,
            EmergencyContactNo: userData.EmergencyContactNo,
            Education: userData.Education,
            PHouseNo: userData.PHouseNo,
            PStreet: userData.PStreet,
            PLandmark: userData.PLandmark,
            PCity: userData.PCity,
            PState: userData.PState,
            PZipCode: userData.PZipCode,
            THouseNo: userData.THouseNo,
            TStreet: userData.TStreet,
            TLandmark: userData.TLandmark,
            TCity: userData.TCity,
            TState: userData.TState,
            TZipCode: userData.TZipCode,
        });

        if (res.code === 200) {
            // toast.success(res.message);
        } else {
            // toast.error(res.message);
        }
        // if (res.code === 200) {
        //   console.log("success")
        // } else {
        //   console.log("failed")
        // }
        setSubmitLoading(false);
    };

    // Use an effect to trigger the profile update after the userData state is updated
    // useEffect(() => {
    //     if (userData, state, city) {
    //         handleProfileUpdate();
    //     }
    // }, [userData], [state], [city]);

    const saveInformation = async (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;

        const requiredFields = [
            { field: 'firstName', message: 'Please Enter First Name' },
            { field: 'lastName', message: 'Please Enter Last Name' },
            { field: 'EmailID', message: 'Please Enter Email ID' },
            { field: 'EmergencyContactNo', message: 'Please Enter Emergency No.' },
            { field: 'Education', message: 'Please Enter Education' },
            { field: 'PHouseNo', message: 'Please Enter House No.' },
            { field: 'PStreet', message: 'Please Enter Street Name' },
            { field: 'PLandmark', message: 'Please Enter Landmark' },
            { field: 'PCity', message: 'Please Enter City' },
            { field: 'PState', message: 'Please Enter State' },
            { field: 'PZipCode', message: 'Please Enter Zipcode' },
            { field: 'THouseNo', message: 'Please Enter House No.' },
            { field: 'TStreet', message: 'Please Enter Street Name' },
            { field: 'TLandmark', message: 'Please Enter Landmark' },
            { field: 'TCity', message: 'Please Enter City' },
            { field: 'TState', message: 'Please Enter State' },
            { field: 'TZipCode', message: 'Please Enter Zipcode' },
            { field: 'IDProof', message: 'Please upload IDProof' },
            { field: 'IDPhoto', message: 'Please upload IDPhoto' },
            { field: 'addressProof', message: 'Please upload Address Proof' },
        ];

        requiredFields.forEach(({ field, message }) => {
            if (!userData[field]) {
                errors[field] = message;
                formIsValid = false;
            }
        });

        setErrorField(errors);

        if (formIsValid) {
            await handleProfileUpdate();
            try {
                const res = await updatekycflagbyStudent({ UserID: userData.UserID });
                if (res.code === 200) {
                    toast.success(res.message);
                    navigate('/dashboard/home')
                } else {
                    toast.error(res.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating the KYC flag.");
            } finally {
                setSubmitLoading(false);
            }
        } else {
            Object.values(errors).forEach((message) => {
                toast.error(message);
            });
        }
    }


    const handleUploadComplete = () => {
        // After successful upload, fetch user profile to update data
        getUserProfile();
    };

    React.useLayoutEffect(() => {
        // get user profile
        getUserProfile();
        // eslint-disable-next-line
    }, [flag]);

    //   user selection same as present function
    React.useEffect(() => {
        if (isSameAddress) {
            const { THouseNo, TStreet, TLandmark, TCity, TState, TZipCode } =
                userData;
            setUserData({
                ...userData,
                PHouseNo: THouseNo,
                PStreet: TStreet,
                PLandmark: TLandmark,
                PCity: TCity,
                PState: TState,
                PZipCode: TZipCode,
            });
        } else {
            setUserData({
                ...userData,
                PHouseNo: "",
                PStreet: "",
                PLandmark: "",
                PCity: "",
                PState: "",
                PZipCode: "",
            });
        }
        // eslint-disable-next-line
    }, [isSameAddress]);

    // Use an effect to trigger the profile update after the userData state is updated



    const iconStyle = {
        borderRadius: '50%',
        border: '1px solid #000',
        padding: '5px',
        height: '45px',
        width: '50px'
    };

    // get user profile



    // --------------- getting all required master data ----------------//
    React.useLayoutEffect(() => {
        // get user profile
        const getAllStatesList = async () => {
            try {
                const res = await getAllStates();
                if (res.code === 200) {
                    setallStates(res.data.stateslist.rows || []);
                    console.log("setallStates", allStates);
                } else {
                    toast.error(res.message);
                }
            } catch (err) {
                toast.error(err?.response?.data?.message || err.message);
            }
        };
        getAllStatesList();
        getUserProfile();
        // eslint-disable-next-line
    }, []);

    return (
        <>

            <div className="dashboard edit_profile spacing">
                <div className="container">
                    <div className="edit_profile_content">

                        <div className="profile_content" style={{ width: "calc(100% - -20px)" }}>
                            <h4 style={{ textAlign: "center" }}>
                                Update KYC Details
                            </h4><br />
                            <h6>Student Information</h6>
                            <div className="form_group">
                                <div className="form_field_wrap">

                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="firstName"
                                            value={userData.firstName || ""}
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={onChangeHandler}
                                            placeholder="Enter First Name"
                                            className={`input form-control`}
                                            error={errorField.firstName}
                                            maxLength={50}
                                            label={"First Name "}
                                            required
                                        />
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="middleName"
                                            placeholder="Enter Middle Name"
                                            className={`input form-control `}
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            label={"Middle Name"}
                                            value={userData.middleName || ""}
                                            onChange={onChangeHandler}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="lastName"
                                            value={userData.lastName || ""}
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={onChangeHandler}
                                            placeholder="Enter Last Name"
                                            className={`input form-control`}
                                            error={errorField.lastName}
                                            maxLength={50}
                                            label={"Last Name "}
                                            required
                                        />
                                    </div>



                                </div>



                                <div className="form_field_wrap">

                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="Mobile"
                                            placeholder="Enter Mobile"
                                            className={`input form-control ${mobError && "is-invalid"}`}
                                            maxLength={10}
                                            value={userData.Mobile || ""}
                                            onChange={onChangeHandler}
                                            label={"Mobile Number "}
                                            // error={errorField.Mobile}
                                            required
                                            readOnly={userData.mobileVerified === true}

                                        />
                                        {mobError && (
                                            <span className="error" style={{ color: '#dc3545', fontSize: '13px', marginLeft: '1px' }}>
                                                {mobError}
                                            </span>
                                        )}
                                    </div>

                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="EmergencyContactNo"
                                            placeholder="Enter Emergency Contact No"
                                            className={`input form-control ${emobError && "is-invalid"}`}
                                            value={userData.EmergencyContactNo || ""}
                                            maxLength={10}
                                            onChange={onChangeHandler}
                                            label={"Emergency Number "}
                                            // error={errorField.EmergencyContactNo}
                                            required

                                        />
                                        {emobError && (
                                            <span className="error" style={{ color: '#dc3545', fontSize: '13px', marginLeft: '1px' }}>
                                                {emobError}
                                            </span>
                                        )}
                                    </div>







                                </div>

                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="EmailID"
                                            value={userData.EmailID || ""}
                                            placeholder="Enter Email Id"
                                            className={`input form-control `}
                                            maxLength={50}
                                            label={"E-mail id "}
                                            required
                                            readOnly
                                        />
                                    </div>


                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="Education"
                                            value={userData.Education || ""}
                                            onChange={onChangeHandler}
                                            error={errorField.Education}
                                            placeholder="Enter Education"
                                            className={`input form-control `}
                                            maxLength={50}
                                            label={"Education "}
                                            required
                                        />
                                    </div>






                                </div>
                            </div>



                            <h6>Address</h6>
                            <div className="form_group">
                                <b className="_title">Present address</b>
                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="THouseNo"
                                            value={userData.THouseNo || ""}
                                            placeholder="Enter House No."
                                            onChange={onChangeHandler}
                                            className={`input form-control `}
                                            error={errorField.THouseNo}
                                            maxLength={20}
                                            label={"House No. "}
                                            required
                                        />
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="TStreet"
                                            value={userData.TStreet || ""}
                                            placeholder="Enter Street Name"
                                            className={`input form-control `}
                                            error={errorField.TStreet}
                                            maxLength={20}
                                            onChange={onChangeHandler}
                                            label={"Street Name "}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="TLandmark"
                                            value={userData.TLandmark || ""}
                                            error={errorField.TLandmark}
                                            placeholder="Enter Landmark"
                                            className={`input form-control `}
                                            onChange={onChangeHandler}
                                            maxLength={30}
                                            label={"Landmark "}
                                            required
                                        />
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="TZipCode"
                                            value={userData.TZipCode || ""}
                                            error={errorField.TZipCode}
                                            placeholder="Enter Zipcode"
                                            className={`input form-control `}
                                            maxLength={6}
                                            label={"Zipcode "}
                                            onChange={onChangeHandler}
                                            required
                                        />
                                    </div>
                                </div>
                                <p style={{ color: "rgb(255, 0, 0)", marginBottom:"4px" }}>
                                    Note: <span style={{ color: "#909090" }}>The state and city fields will automatically populate upon entering the zipcode.</span>
                                </p>
                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        {/* <Input
                        type="text"
                        name="TState"
                        value={userData.TState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.TState}
                        required
                      /> */}
                                        <Select
                                            name="TState"
                                            value={userData.TState || ""}
                                            //value={selectedTState}
                                            onChange={onChangeHandler}
                                            className={`input form-control`}
                                            error={errorField.TState}
                                            label={"State "}
                                            readOnly
                                            disabled
                                        >
                                            <option value="">{state || userData.TState}</option>
                                            {/* {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))} */}
                                        </Select>
                                        {/* Error message for TState */}
                                        {/* {errorField.TState && <span className="error">{errorField.TState}</span>} */}

                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="TCity"
                                            value={userData.TCity || city || ""}
                                            placeholder="Enter City"
                                            onChange={onChangeHandler}
                                            className={`input form-control `}
                                            error={errorField.TCity}
                                            maxLength={20}
                                            label={"City "}
                                            required
                                        />
                                    </div>
                                </div>
                                <p className="checkbox_wrap">
                                    <Input
                                        type="checkbox"
                                        className="input"
                                        id="check"
                                        checked={isSameAddress}
                                        onChange={() => {
                                            setIsSameAddress(!isSameAddress);
                                        }}
                                    // disabled={!isEditable.profile}
                                    />
                                    <label htmlFor="check">
                                        Permanent Address same as Present Address
                                    </label>
                                </p>
                                <b className="_title">Permanent address</b>
                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="PHouseNo"
                                            value={userData.PHouseNo || ""}
                                            placeholder="Enter House No."
                                            onChange={onChangeHandler}
                                            className={`input form-control `}
                                            error={errorField.PHouseNo}
                                            maxLength={20}
                                            label={"House No. "}
                                            required
                                            disabled={isSameAddress}
                                        />
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="PStreet"
                                            value={userData.PStreet || ""}
                                            placeholder="Enter Street Name"
                                            onChange={onChangeHandler}
                                            className={`input form-control `}
                                            error={errorField.PStreet}
                                            maxLength={20}
                                            label={"Street Name "}
                                            required
                                            disabled={isSameAddress}
                                        />
                                    </div>
                                </div>

                                <div className="form_field_wrap">
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="PLandmark"
                                            value={userData.PLandmark || ""}
                                            placeholder="Enter Landmark"
                                            className={`input form-control `}
                                            error={errorField.PLandmark}
                                            onChange={onChangeHandler}
                                            maxLength={30}
                                            label={"Landmark "}
                                            required
                                            disabled={isSameAddress}
                                        />
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="PZipCode"
                                            value={userData.PZipCode || ""}
                                            placeholder="Enter Zipcode"
                                            onChange={onChangeHandler}
                                            className={`input form-control `}
                                            error={errorField.PZipCode}
                                            maxLength={6}
                                            label={"Zipcode "}
                                            required
                                            disabled={isSameAddress}
                                        />
                                    </div>
                                </div>
                                
                                <div className="form_field_wrap">

                                    <div className="form_field">
                                        {/* <Input
                        type="text"
                        name="PState"
                        value={userData.PState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.PState}
                        required
                        disabled={isSameAddress}
                      /> */}

                                        <Select
                                            name="PState"
                                            value={userData.PState || ""}
                                            //value={selectedTState}
                                            onChange={onChangeHandler}
                                            className={`input form-control`}
                                            error={errorField.PState}
                                            label={"State "}
                                            readOnly
                                            disabled
                                        >
                                            <option value="">{state || userData.PState}</option>
                                            {/* {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))} */}
                                        </Select>
                                        {/* Error message for PState */}
                                        {/* {errorField.PState && <span className="error">{errorField.PState}</span>} */}
                                    </div>
                                    <div className="form_field">
                                        <Input
                                            type="text"
                                            name="PCity"
                                            value={userData.PCity || ""}
                                            onChange={onChangeHandler}
                                            placeholder="Enter City"
                                            className={`input form-control `}
                                            error={errorField.PCity}
                                            maxLength={20}
                                            label={"City "}
                                            disabled={isSameAddress}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* upload doc component */}
                            <UploadDoc
                                addressDoc={userData.addressProof}
                                idDoc={userData.IDProof}
                                idPhoto={userData.IDPhoto}
                                isEditable={isEditable}
                                flag={() => { }}
                                onUploadComplete={handleUploadComplete}


                            /><br /><br />
                            <center>
                                <div className="button_wrap">
                                    <button
                                        type="button"
                                        className="dashboard_button fill"
                                        onClick={saveInformation}
                                        ref={buttonRef}
                                        style={{ width: "200px", height: "40px", fontSize: '17px' }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </center>
                            {/* Course package listing */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Drawer */}


        </>
    );
}

export default UpdateKYCDetails;
