import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// Lecture List view
// export const lectureListView = async (data = {}) => {
//   const token = isLoggedIn();
//   return decryptData(
//     await axios.get(
//       `/lecture-list-view?reqData=${encryptData({
//         ...data,
//         platformType: getBrowserName(),
//         access_token: token,
//       })}`
//     )
//   );
// };

export const lectureListView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-list-view-new?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get calender lecture
export const lectureCalenderView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-calender-view?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get notes by lecture
export const noteByLectureView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-by-notes?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// Not watched Lecture
export const notWatchedLecture = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-list-view?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// Exception Lectures
export const exceptionalLectureList = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/exception-lecture?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ apply for exceptional lecture__________
export const applyExceptionalLecture = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/assign-exception-lecture`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// On Demand Lectures
export const onDemandLectureSection = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-list-view?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ Lecture detail video __________
export const userLectureDetail = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-detail?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ related Lecture video __________
export const userRelatedLectures = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/related-lectures?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ vdo cipher Lecture video otp generate with auth __________
export const vdoCipherGenerateOtp = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/play-lecture-otp`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

export const PlayApexLecture = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/play-apex-lecture`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// __________ vdo cipher Lecture video otp generate without auth __________
export const vdoCipherGenerateOtpWOAuth = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`without-auth-play-lecture-otp`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// __________ course section list __________
export const getCourseSectionList = async (data) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/course-section-list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ lecture time tracking api __________
export const lectureTimeTracking = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/lecture/time/tracking`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};


export const OnDemandlectureListView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/ondemandlecture-list-view?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const OnDemandViewedListView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/ondemandlecture-viewed-list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const OnDemandUpcomingListView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/ondemandlecture-upcoming-list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

