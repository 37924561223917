import React, { useRef, useState } from "react";
import "./Dashboard.scss";
import LiveLectures from "./section/LiveLectures";
import GroupMeetings from "./section/GroupMeetings";
import { liveLectureList } from "services/liveLecture";
import { useCourse } from "context/context";
import ProgressBar from "./section/ProgressBar";
import { errorResponse, failResponse } from "config/config";
import { charLimit } from "config/utils";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import TestPerformance from "./section/TestPerformance";
import { getTestByCourse } from "services/dashboard";

function Dashboard({ courseDrawerShow, setCourseDrawerShow }) {
  const { course } = useCourse();
  const [liveLectures, setLiveLectures] = useState([]);
  const [loading, setLoading] = useState(false);

  const [allTests, setAllTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const testRef = useRef();

  const testSelectionHandler = ({ target }) => {
    setSelectedTest(target.value);
    testRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // get users live lecture
  const getLiveLectures = async () => {
    try {
      setLoading(true);
      const res = await liveLectureList({ courseIds: [course.courseId] });

      if (res.code === 200) {
        setLiveLectures(res.data.lectures || []);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // ----------------------- getting live lecture data -------------------------//
  React.useEffect(() => {
    const getAllTestByCourseId = async () => {
      try {
        const res = await getTestByCourse({ courseId: course.courseId });
        if (res.code === 200) {
          setAllTests(res.data);
          setSelectedTest(res.data[0]?.SectionTestID || "");
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (course?.courseId) {
      getLiveLectures();
      getAllTestByCourseId();
    }
    // eslint-disable-next-line
  }, [course]);

  return (
    <section className="dashboard_home ">
      <div className="container">
        {/* My Courses */}
        
        <div className="mycourses">
          <div className="mycourses_inner mb-2">
            {/* live lecture */}
           
            <LiveLectures
              loading={loading}
              lectures={liveLectures}
              getLiveLectures={getLiveLectures}
            />

            <div className="_progress_wrapper">
              <GroupMeetings />
            </div>
            {/* user lecture continue watching */}
            {/* <ContinueWatching /> */}
          </div>
        </div>

        <div className=" _box course_wise_progress">
          <h4>Course wise progress</h4>
          <div className="form_field">
            <span
              className="input_selection"
              onClick={() => setCourseDrawerShow(true)}
            >
              {charLimit(course?.courseName, 30) || "My Courses"}
              <FaAngleDown />
            </span>

            <select
              className="input_selection"
              value={selectedTest}
              onChange={testSelectionHandler}
            >
              <option value="">Select test</option>
              {allTests.map((data) => {
                return (
                  <option value={data.SectionTestID} key={data.SectionTestID}>
                    {data.TestTitle}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="_progress_card_wrap">
            <ProgressBar />
            <div className="button_wrap">
              <Link className="" to={"/dashboard/report"}>
                View report
              </Link>
            </div>
          </div>
        </div>

        {selectedTest && (
          <TestPerformance selectedTest={selectedTest} testRef={testRef} />
        )}
      </div>
    </section>
  );
}

export default Dashboard;
