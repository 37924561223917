import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import Slider from "react-slick";
import "./Testimonial.scss";
import { Placeholder } from "react-bootstrap";
import { countArray, sliderItem } from "config/utils";
import VideoPlayModal from "components/video-play-modal/VideoPlayModal";
import { Courses } from "assets/images";

const Testimonials = ({ testimonials = [], loading }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const handleShow = () => {
    setModalShow(true);
  };
  const handleClose = () => {
    setModalShow(false);
  };

  var TestimonialSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: sliderItem(testimonials, 6),
    slidesToScroll: 1,
    speed: 900,
    // autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: sliderItem(testimonials, 6),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="testimonials_section spacing">
      <div className="container">
        <div className="page_title">
          <h3>Testimonials from Our Alumni</h3>
          <p>
            Hear what they have to say about their preparation journey and
            experience with Shubhra Ranjan
          </p>
        </div>

        <div className="testimonials">
          {/* loading cards */}
          {loading && (
            <div className="d-flex">
              {countArray(2).map((data) => {
                return (
                  <div className="w-50 mx-1" key={data}>
                    <Placeholder as="div" animation="wave" aria-hidden="true">
                      <Placeholder xs={12} style={{ height: "50vh" }} />
                    </Placeholder>
                  </div>
                );
              })}
            </div>
          )}

          {/************** not found component **************/}
          {!loading && testimonials.length <= 0 && (
            <div className="listing_card not_found">
              <div className="not_fount_image">
                <img src={Courses} alt="" loading="lazy" />
              </div>
              <h5>No testimonials added</h5>
            </div>
          )}

          {/* testimonial slider */}
          {!loading && testimonials.length > 0 && (
            <>
              <Slider {...TestimonialSlider}>
                {/* iterate all faculties */}
                {testimonials.map(
                  (
                    {
                      id,
                      title,
                      description,
                      media,
                      studentImage,
                      studentName,
                      yearOfStudy,
                    },
                    i
                  ) => {
                    return (
                      <div className="item" key={id}>
                        <div className="testimonial_card">
                          <div className="active_card">
                            <div className="image_wrap video_popup">
                              {/* <img src={BannerSlick1} alt="" loading="lazy"/> */}
                              <div
                                className="playicon"
                                onClick={() => {
                                  setSelectedVideo(media && media);
                                  handleShow();
                                }}
                              >
                                <span className="videoplay_icon">
                                  <FaPlay />
                                </span>
                              </div>
                              {/* <FancyVideo
                                key={i}
                                source={media && media}
                                poster={studentImage}
                                id={`sintel-${i}`}
                              /> */}
                              <img src={studentImage} alt="" loading="lazy" />
                            </div>
                            <p>{description}</p>
                          </div>
                          <div className="content">
                            <h5>{studentName}</h5>
                            <span>{title}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </Slider>
              <VideoPlayModal
                modalShow={modalShow}
                modalHide={handleClose}
                video={selectedVideo}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
