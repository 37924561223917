import React, { useState } from "react";
import { Input } from "components/Form";
import { errorResponse, failResponse } from "config/config";
import Drawer from "dashboard/components/drawer/Drawer";
import useButtonLoader from "hooks/useButtonLoader";
import { toast } from "react-hot-toast";
import { updateUserProfile } from "services/dashboard";
import { getAllStates } from "services/master";
import { Select,TextArea } from "components/Form";


const UpdateDrawer = ({
  userUpdateData,
  setUserUpdateData,
  show,
  onClose,
  getUserProfile,
  isEditable,
}) => {
  const [errorField, setErrorField] = React.useState({
    firstName: "",
    lastName: "",
    Mobile: "",
    EmergencyContactNo: "",
    Education: "",
    PHouseNo: "",
    PStreet: "",
    PLandmark: "",
    PCity: "",
    PState: "",
    PZipCode: "",
    THouseNo: "",
    TStreet: "",
    TLandmark: "",
    TCity: "",
    TState: "",
    TZipCode: "",
  });

  const [isSameAddress, setIsSameAddress] = React.useState(
    userUpdateData.PHouseNo === userUpdateData.THouseNo &&
      userUpdateData.PStreet === userUpdateData.TStreet &&
      userUpdateData.PLandmark === userUpdateData.TLandmark &&
      userUpdateData.PCity === userUpdateData.TCity &&
      userUpdateData.PState === userUpdateData.TState &&
      userUpdateData.PZipCode === userUpdateData.TZipCode
  );

  const [buttonRef, setButtonLoading] = useButtonLoader("Update");

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!userUpdateData.firstName) {
      errorField["firstName"] = "Please Enter First Name";
      formIsValid = false;
    }
    if (!userUpdateData.lastName) {
      errorField["lastName"] = "Please Enter Last Name";
      formIsValid = false;
    }

    if (!userUpdateData.Mobile) {
      errorField["Mobile"] = "Please Enter Mobile No.";
      formIsValid = false;
    }
    if (!userUpdateData.EmailID) {
      errorField["EmailID"] = "Please Enter Email ID";
      formIsValid = false;
    }
    if (!userUpdateData.EmergencyContactNo) {
      errorField["EmergencyContactNo"] = "Please Enter Emergency No.";
      formIsValid = false;
    }
    if (!userUpdateData.Education) {
      errorField["Education"] = "Please Enter Education";
      formIsValid = false;
    }

    if (!userUpdateData.PHouseNo) {
      errorField["PHouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userUpdateData.PStreet) {
      errorField["PStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userUpdateData.PLandmark) {
      errorField["PLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userUpdateData.PCity) {
      errorField["PCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userUpdateData.PState) {
      errorField["PState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userUpdateData.PZipCode) {
      errorField["PZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    if (!userUpdateData.THouseNo) {
      errorField["THouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userUpdateData.TStreet) {
      errorField["TStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userUpdateData.TLandmark) {
      errorField["TLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userUpdateData.TCity) {
      errorField["TCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userUpdateData.TState) {
      errorField["TState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userUpdateData.TZipCode) {
      errorField["TZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  //   onchange handler for edit profile
  const onChangeHandler = ({ target }) => {
    const { name, value } = target;
    setUserUpdateData({ ...userUpdateData, [name]: value });
    if (name === "TZipCode" || name === "PZipCode") {
      setUserUpdateData({
        ...userUpdateData,
        [name]: value.replace(/\D/g, ""),
      });
    }
    if (name === "TState" || name === "PState" || name === "TCity" || name === "PCity")  {
      setUserUpdateData({
        ...userUpdateData,
        [name]: value.replace(/[^A-Za-z ]/gi, "").replace(/\s+/g, " "),
      });
    }
    if (isSameAddress) {
      if (
        name === "THouseNo" ||
        name === "TState" ||
        name === "TCity" ||
        name === "TZipCode" ||
        name === "TLandmark" ||
        name === "TStreet"
      ) {
        setIsSameAddress(false);
      }
    }
  };

  //   profile data update handler
  const profileUpdateHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      try {
        setButtonLoading(true);

        const {
          IDPhoto,
          IDProof,
          ProfileImage,
          UserID,
          addressProof,
          ...rest
        } = userUpdateData;
        const res = await updateUserProfile(rest);
        if (res.code === 200) {
          toast.success("Profile updated successfully");
          setButtonLoading(false);
          getUserProfile();
          onClose();
        } else {
          failResponse(res);
          setButtonLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setButtonLoading(false);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  //   user selection same as present function
  React.useEffect(() => {
    if (isSameAddress) {
      const { THouseNo, TStreet, TLandmark, TCity, TState, TZipCode } =
        userUpdateData;
      setUserUpdateData({
        ...userUpdateData,
        PHouseNo: THouseNo,
        PStreet: TStreet,
        PLandmark: TLandmark,
        PCity: TCity,
        PState: TState,
        PZipCode: TZipCode,
      });
    } else {
      setUserUpdateData({
        ...userUpdateData,
        PHouseNo: "",
        PStreet: "",
        PLandmark: "",
        PCity: "",
        PState: "",
        PZipCode: "",
      });
    }
    // eslint-disable-next-line
  }, [isSameAddress]);


  const [allStates, setallStates] = useState([]);
  const [selectedTState, setSelectedTState] = useState('');
  const [selectedPState, setSelectedPState] = useState('');
  React.useLayoutEffect(() => {
    const getAllStatesList = async () => {
      try {
        const res = await getAllStates();
        if (res.code === 200) {
          setallStates(res.data.stateslist.rows || []);
          console.log("setallStates",allStates);
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    // calls all the master functions
    getAllStatesList();

    // eslint-disable-next-line
  }, []);

  const handleTStateChange = (e) => {
    setSelectedTState(e.target.value);
    // Update userData with selected TState
    setUserUpdateData({ ...userUpdateData, TState: e.target.value });
  };

  const handlePStateChange = (e) => {
    setSelectedPState(e.target.value);
    // Update userData with selected PState
    setUserUpdateData({ ...userUpdateData, PState: e.target.value });
  };

  return (
    <>
      <Drawer
        show={show}
        handleClose={onClose}
        title={isEditable.profile ? "Edit Profile" : "View Profile"}
      >
        <form className="form" onSubmit={profileUpdateHandler}>
          <h6>Personal details</h6>

          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                name="firstName"
                value={userUpdateData.firstName || ""}
                onChange={onChangeHandler}
                placeholder="Enter First Name"
                className={`input form-control `}
                maxLength={50}
                label={"First Name "}
                error={errorField.firstName}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="middleName"
                value={userUpdateData.middleName || ""}
                onChange={onChangeHandler}
                placeholder="Enter Middle Name"
                className={`input form-control `}
                maxLength={50}
                label={"Middle Name"}
              />
            </div>
          </div>

          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                name="lastName"
                value={userUpdateData.lastName || ""}
                onChange={onChangeHandler}
                placeholder="Enter Last Name"
                className={`input form-control `}
                maxLength={50}
                label={"Last Name"}
                error={errorField.lastName}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="Mobile"
                value={userUpdateData.Mobile || ""}
                placeholder="Enter Mobile"
                className={`input form-control `}
                maxLength={10}
                label={"Mobile Number "}
                error={errorField.Mobile}
                required
                readOnly
              />
            </div>
          </div>

          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                name="EmergencyContactNo"
                value={userUpdateData.EmergencyContactNo || ""}
                onChange={({ target }) =>
                  setUserUpdateData({
                    ...userUpdateData,
                    EmergencyContactNo: target.value.replace(/\D/g, ""),
                  })
                }
                placeholder="Emergency number"
                className={`input form-control `}
                maxLength={10}
                label={" Emergency number "}
                error={errorField.EmergencyContactNo}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="EmailID"
                value={userUpdateData.EmailID || ""}
                placeholder="Enter Email Id"
                className={`input form-control `}
                maxLength={50}
                label={"E-mail id "}
                error={errorField.EmailID}
                required
                readOnly
              />
            </div>
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="Education"
              value={userUpdateData.Education || ""}
              onChange={onChangeHandler}
              placeholder="Enter Education"
              className={`input form-control `}
              maxLength={50}
              label={"Education "}
              error={errorField.Education}
              required
            />
          </div>
          <h6>Address</h6>

          <div className="form_field">
            <div className="form_field">
              <label className="label" htmlFor="Permanentaddress">
                Present address
              </label>
              <Input
                type="text"
                name="THouseNo"
                value={userUpdateData.THouseNo || ""}
                placeholder="Enter House No."
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"House No. "}
                error={errorField.THouseNo}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="TStreet"
                value={userUpdateData.TStreet || ""}
                placeholder="Enter Street Name"
                className={`input form-control `}
                maxLength={20}
                onChange={onChangeHandler}
                label={"Street Name "}
                error={errorField.TStreet}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="TLandmark"
                value={userUpdateData.TLandmark || ""}
                placeholder="Enter Landmark"
                className={`input form-control `}
                onChange={onChangeHandler}
                maxLength={30}
                label={"Landmark "}
                error={errorField.TLandmark}
                required
              />
            </div>
            <div className="form_field">
              {/* <Input
                type="text"
                name="TState"
                value={userUpdateData.TState || ""}
                placeholder="Enter State"
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"State "}
                error={errorField.TState}
                required
              /> */}
              <Select
              name="TState"
              value={userUpdateData.TState || ""}
              //value={selectedTState}
              onChange={handleTStateChange}
              className={`input form-control`}
              label={"State "}
            >
              <option value="">Select State</option>
              {allStates.map((state) => (
                <option key={state.StateID} value={state.StateName}>
                  {state.StateName}
                </option>
              ))}
            </Select>
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="TCity"
                value={userUpdateData.TCity || ""}
                placeholder="Enter City"
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"City "}
                error={errorField.TCity}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="TZipCode"
                onChange={onChangeHandler}
                value={userUpdateData.TZipCode || ""}
                placeholder="Enter Zipcode"
                className={`input form-control `}
                maxLength={6}
                label={"Zipcode "}
                error={errorField.TZipCode}
                required
              />
            </div>
          </div>

          <p className="checkbox_wrap">
            <Input
              type="checkbox"
              className="input"
              id="check"
              checked={isSameAddress}
              onChange={() => setIsSameAddress(!isSameAddress)}
            />
            <label htmlFor="check">
              Permanent Address same as Present Address
            </label>
          </p>
          <div className="form_field">
            <label className="label" htmlFor="PermanentAddress">
              Permanent address
            </label>
            <div className="form_field">
              <Input
                type="text"
                name="PHouseNo"
                value={userUpdateData.PHouseNo || ""}
                placeholder="Enter House No."
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"House No. "}
                error={errorField.PHouseNo}
                required
                disabled={isSameAddress}
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="PStreet"
                value={userUpdateData.PStreet || ""}
                placeholder="Enter Street Name"
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"Street Name "}
                error={errorField.PStreet}
                required
                disabled={isSameAddress}
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="PLandmark"
                value={userUpdateData.PLandmark || ""}
                placeholder="Enter Landmark"
                className={`input form-control `}
                onChange={onChangeHandler}
                maxLength={30}
                label={"Landmark "}
                error={errorField.PLandmark}
                required
                disabled={isSameAddress}
              />
            </div>
            <div className="form_field">
              {/* <Input
                type="text"
                name="PState"
                value={userUpdateData.PState || ""}
                placeholder="Enter State"
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={20}
                label={"State "}
                error={errorField.PState}
                required
                disabled={isSameAddress}
              /> */}
              <Select
              name="PState"
              value={selectedPState}
             // value={userUpdateData.PState || ""}
              onChange={handlePStateChange}
              className={`input form-control`}
              required
              label={"State"}
            >
              <option value="">Select State</option>
              {allStates.map((state) => (
                <option key={state.StateID} value={state.StateName}>
                  {state.StateName}
                </option>
              ))}
            </Select>

            </div>
            <div className="form_field">
              <Input
                type="text"
                name="PCity"
                value={userUpdateData.PCity || ""}
                onChange={onChangeHandler}
                placeholder="Enter City"
                className={`input form-control `}
                maxLength={20}
                label={"City "}
                error={errorField.PCity}
                disabled={isSameAddress}
                required
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="PZipCode"
                value={userUpdateData.PZipCode || ""}
                placeholder="Enter Zipcode"
                onChange={onChangeHandler}
                className={`input form-control `}
                maxLength={6}
                label={"Zipcode "}
                error={errorField.PZipCode}
                required
                disabled={isSameAddress}
              />
            </div>
          </div>

          <div className="button_wrap">
            {isEditable.profile && (
              <button
                className="dashboard_button fill w-100"
                type="submit"
                ref={buttonRef}
              >
                Update
              </button>
            )}
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default React.memo(UpdateDrawer);
