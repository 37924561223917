import CommonModal from "components/modal-box/CommonModal";
import { encryptData, errorResponse, normalDecryptData } from "config/config";
import {
  getBrowserName,
  getLocalTrackingTime,
  setLocalTrackingTime,
} from "config/utils";
import { useCourse } from "context/context";
import useCookie from "hooks/useCookie";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getUserIp } from "services/third-party";

import io from "socket.io-client";

let API_HIT_TIME = 60000;

const OnDemandTimeTracking = ({
  videoRef,
  isAPIReady,
  lastSeekBar = 0,
  sectionId,
  onClose,
  MAIN_PERCENTAGE,
  destroyCipher,
  lectureDetail,
  lectureCompleteHandler,
  lctSession
}) => {
  const { course } = useCourse();
  const navigate = useNavigate();
  const { removeCookie } = useCookie();

  const { lectureId } = useParams();
  const [socket, setSocket] = useState(null);

  const mainLectureId = normalDecryptData(lectureId, "route")?.split(",");

  let localTrackingData =
    getLocalTrackingTime(
      course.courseId,
      lectureDetail?.LectureID,
      sectionId,
      "shub-playtime"
    ) || 0;

  let localTrackingCoverData =
    getLocalTrackingTime(
      course.courseId,
      lectureDetail?.LectureID,
      sectionId,
      "shub-cover-time"
    ) || 0;

  const trackingRef = useRef(0);
  const timeRef = useRef(+localTrackingData || 0);

  const ipRef = useRef(0);
  const { cookies } = useCookie();

  const trackingCoverRef = useRef(0);
  const timeCoverRef = useRef(+localTrackingCoverData || 0);

  const localDataRef = useRef(0);

  const [stopModal, setStopModal] = useState(false);

  const [, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);

  //   time tracking api
  const timeTrackerHandler = async (
    playtime,
    coverTime,
    lastSeekBar,
    play = 1
  ) => {
    if (+mainLectureId[0] === +lectureDetail?.LectureID) {
      try {
        let finalPlayTime = 0;
        // removed previous saved time from actual time
        if (play === 0) {
          finalPlayTime = +playtime - +localDataRef.current;
        }
        // if time is getting negative value then assign this to positive
        if (finalPlayTime <= 0) {
          finalPlayTime = finalPlayTime * -finalPlayTime;
        }
        if (!ipRef.current) {
          const { data: ip } = await getUserIp();
          ipRef.current = ip.ip;
        }

        const payload = encryptData({
          Platform: "web",
          courseId: course.courseId,
          IPAddress: ipRef.current,
          playTime: finalPlayTime >= 0 ? finalPlayTime : 0,
          TotalCoverdTime: coverTime,
          TotalPlayedTime: finalPlayTime >= 0 ? finalPlayTime : 0,
          lastPlay: lastSeekBar,
          lectureId: lectureDetail?.LectureID,
          sectionId,
          play,
          platformType: getBrowserName(),
          access_token: cookies,
          lctSession
        });

        // send data to db
        socket.emit("lectureTimeTracking", payload);

        // assign play time if video is paused
        if (play === 0) {
          localDataRef.current = playtime;
        }

        // clear all states n values
        trackingRef.current = 0;
        trackingCoverRef.current = 0;
        setLocalTrackingTime(course.courseId, lectureId, "shub-playtime", 0);
        setLocalTrackingTime(course.courseId, lectureId, "shub-cover-time", 0);

        socket.on("response", (data) => {
          if (data.code === 402) {
            removeCookie();
            sessionStorage.removeItem("course");
            navigate("/");
            toast.error(data.message, { id: "fail" });
          }
          if (data.code === 401) {
            setStopModal(true);
            player.video.pause();
            return;
          }
          if (data?.data?.playVideoPercantageByDuration >= MAIN_PERCENTAGE) {
            lectureCompleteHandler("duration");
            return;
          }
          if (data?.data?.VideoPercantage >= MAIN_PERCENTAGE) {
            toast.success(
              `You consumed your ${MAIN_PERCENTAGE}% allocated time.`
            );
            destroyCipher();
            lectureCompleteHandler("allocate");
          }
        });

        // const res = await lectureTimeTracking({
        //   Platform: "web",
        //   courseId: course.courseId,
        //   IPAddress: ip.ip,
        //   playTime: finalPlayTime >= 0 ? finalPlayTime : 0,
        //   TotalCoverdTime: coverTime,
        //   TotalPlayedTime: finalPlayTime >= 0 ? finalPlayTime : 0,
        //   lastPlay: lastSeekBar,
        //   lectureId: lectureDetail?.LectureID,
        //   sectionId,
        // });

        // if (res.code === 200) {
        //   localDataRef.current = playtime;
        //   trackingRef.current = 0;
        //   trackingCoverRef.current = 0;
        //   timeRef.current = 0;
        //   timeCoverRef.current = 0;
        //   setLocalTrackingTime(
        //     course.courseId,
        //     lectureDetail?.LectureID,
        //     sectionId,
        //     "shub-playtime",
        //     0
        //   );
        //   setLocalTrackingTime(
        //     course.courseId,
        //     lectureDetail?.LectureID,
        //     sectionId,
        //     "shub-cover-time",
        //     0
        //   );
        //   if (res.data.playVideoPercantageByDuration >= MAIN_PERCENTAGE) {
        //     lectureCompleteHandler("duration");
        //     return;
        //   }
        //   if (res.data.VideoPercantage >= MAIN_PERCENTAGE) {
        //     toast.success(
        //       `You consumed your ${MAIN_PERCENTAGE}% allocated time.`
        //     );
        //     destroyCipher();
        //     lectureCompleteHandler("allocate");
        //   }
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    }
  };

  //   load the player
  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      setPlayer(null);
      return;
    }
    // timer for tracking
    let timer;

    // pause event
    const pauseEvent = () => {
      // setStatus("Paused");
      timeTrackerHandler(
        +trackingRef.current + +timeRef.current,
        +trackingCoverRef.current + +timeCoverRef.current,
        player.video.currentTime,
        0
      );
    };

    // play event
    const playEvent = () => {
      setStatus("Playing");
      timeTrackerHandler(
        +trackingRef.current + +timeRef.current,
        +trackingCoverRef.current + +timeCoverRef.current,
        player.video.currentTime,
        1
      );
      // player.video.currentTime = lastSeekBar;
    };

    // time update event
    const timeUpdateEvent = async () => {
      // playtime
      window?.player.api.getTotalPlayed().then((time) => {
        trackingRef.current = time;

        setLocalTrackingTime(
          course.courseId,
          lectureDetail?.LectureID,
          sectionId,
          "shub-playtime",
          trackingRef.current
        );
      });

      // cover time
      window?.player.api.getTotalCovered().then((time) => {
        trackingCoverRef.current = time;

        setLocalTrackingTime(
          course.courseId,
          lectureDetail?.LectureID,
          sectionId,
          "shub-cover-time",
          trackingCoverRef.current
        );
      });

      // call event for token checking
      socket.emit("checkUser", {
        reqData: encryptData({ access_token: cookies }),
      });

      socket.on("userResponse", (data) => {
        if (data.code === 402) {
          removeCookie();
          sessionStorage.removeItem("course");
          navigate("/");
          toast.error(data.message, { id: "fail" });
        }
      });

      if (!timer) {
        timer = setTimeout(() => {
          timeTrackerHandler(
            +trackingRef.current + +timeRef.current,
            +trackingCoverRef.current + +timeCoverRef.current,
            player.video.currentTime,
            0
          );
          timer = null;
        }, API_HIT_TIME);
      }

      // if (!timer) {
      //   timer = setTimeout(() => {
      //     timeTrackerHandler(
      //       +trackingRef.current + +timeRef.current,
      //       +trackingCoverRef.current + +timeCoverRef.current,
      //       player.video.currentTime,
      //       lectureDetail?.LectureID
      //     );
      //     timer = null;
      //   }, 120000); // 2 minute interval
      // }
    };
    // const debounceTimeUpdateEvent = debounce(timeUpdateEvent, 1000);

    const player = new window.VdoPlayer(videoRef);

    window.player = player;
    setPlayer(player);

    player.video.addEventListener("play", playEvent);
    player.video.addEventListener("pause", pauseEvent);
    // player.video.addEventListener("ended", () => {
    //   setStatus("ended");
    //   timeTrackerHandler(
    //     +trackingRef.current + +timeRef.current,
    //     +trackingCoverRef.current + +timeCoverRef.current,
    //     player.video.currentTime
    //   );
    // });
    // player.video.addEventListener("canplay", () => setStatus("Ready"));
    player.video.addEventListener("timeupdate", timeUpdateEvent);
    window.player = player;

    return () => {
      player.video.removeEventListener("pause", pauseEvent);
      player.video.removeEventListener("play", playEvent);
      player.video.removeEventListener("timeupdate", timeUpdateEvent);
    };

    // eslint-disable-next-line
  }, [isAPIReady, videoRef]);

  // ----------------------- disabled inspect and right click-------------------------//
  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };

  useEffect(() => {
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    return () => {
      document.removeEventListener(
        "contextmenu",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
    //eslint-disable-next-line
  }, []);

  // setup socket instance
  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(socketInstance);

    // listen for events emitted by the server

    socketInstance.on("connect", () => {
      // console.log("Connected to server");
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  return player && player.video ? (
    <>
      <div className="api-controls inline">
        {/* <div>Status: {status}</div> */}
        {/* <div>CurrentTime: {currentTime}</div> */}
      </div>
      <CommonModal
        className=""
        show={stopModal}
        handleClose={() => {
          navigate("/dashboard/lecture");
        }}
        size="md"
        closeIcon={false}
      >
        <div className="logout">
          <span className="logout_icon">
            <FaExclamationCircle />
          </span>
          <h3>Alert !</h3>
          <p>this lecture is already playing</p>

          <div className="button_wrap center">
            <button
              className="dashboard_button green"
              onClick={() => navigate("/dashboard/lecture")}
            >
              OK
            </button>
          </div>
        </div>
      </CommonModal>
    </>
  ) : null;
};

export default OnDemandTimeTracking;
