import React, { useEffect } from 'react';

function Implementscript() {

    useEffect(() => {
        // Create a script element
        const scriptElement = document.createElement("script");
        
        // Set script attributes
        scriptElement.type = "text/javascript";
        scriptElement.async = true;
        scriptElement.src = "https://chatbot.in8.nopaperforms.com/en-gb/backend/bots/niaachtbtscpt.js/82eadf4d6e674ad6b676c3ba054099ee/7785ab67ada9453eace70e07bbac563f";
        
        // Append the script element to the body
        document.body.appendChild(scriptElement);
    
        // Cleanup function to remove the script when the component is unmounted
        return () => {
          document.body.removeChild(scriptElement);
        };
      }, []); // Empty dependency array to ensure the effect runs only once on mount


  return (
    <>
      <div class="npf_chatbots" data-w="7785ab67ada9453eace70e07bbac563f" style={{display:"none"}}></div>
    </>
  )
}

export default Implementscript
