import React, { useState } from "react";
import { MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import "./EditProfile.scss";
import { userProfile } from "services/dashboard/index";
import useCookie from "hooks/useCookie";
import { useNavigate } from "react-router-dom";
import { addressFormatter } from "config/utils";
import UpdateDrawer from "./UpdateDrawer";
import UploadDoc from "./UploadDoc";
import CommonModal from "components/modal-box/CommonModal";
import { FaExclamationCircle, FaUserCircle } from "react-icons/fa";
import CoursePackages from "./CoursePackages";
import { userLogout } from "services/auth";
import { useCourse } from "context/context";
import useButtonLoader from "hooks/useButtonLoader";
import { errorResponse, failResponse } from "config/config";
function EditProfile() {
  const [show, setShow] = useState(false);
  const [logout, setLogout] = useState(false);
  const [isEditable, setIsEditable] = useState({
    profile: false,
    idProof: false,
    addressProof: false,
  });
  // const [file, setFileModal] = useState(false);

  const { setCourse } = useCourse();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Yes, log me out");

  const [userData, setUserData] = useState({
    addressProof: null,
    IDProof: null,
  });

  const [userUpdateData, setUserUpdateData] = useState({});
  const navigate = useNavigate();

  const { removeCookie } = useCookie();

  //   edit profile off canvas handler
  const openEditProfile = () => {
    setUserUpdateData(userData);
    setShow(true);
  };

  //   logout handler function
  const logoutHandler = async () => {
    try {
      setSubmitLoading(true);
      const res = await userLogout();
      if (res.code === 200) {
        removeCookie();
        sessionStorage.removeItem("course");
        setCourse({});
        navigate("/");
        setSubmitLoading(false);
      } else {
        failResponse(res);
        setSubmitLoading(false);
      }
    } catch (err) {
      setSubmitLoading(false);
      errorResponse(err);
    }
  };

  // get user profile
  const getUserProfile = async () => {
    try {
      const res = await userProfile();

      if (res.code === 200) {
        setUserData(res.data.userDetails || []);

        // checks user's profile is editable or not
        setIsEditable({
          profile: res.data.userDetails.PHouseNo ? false : true,
          idProof: res.data.userDetails.IDProof ? false : true,
          addressProof: res.data.userDetails.addressProof ? false : true,
        });
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // --------------- getting all required master data ----------------//
  React.useLayoutEffect(() => {
    // get user profile
    getUserProfile();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="dashboard edit_profile spacing">
        <div className="container">
          <div className="edit_profile_content">
            <div className="lefside_bar">
              <div className="content">
                <div className="profile_image">
                  {/* <img src={Userimg} alt="" loading="lazy"/> */}
                  <span className="user_icon">
                    <FaUserCircle />
                  </span>
                  <span className="edit_icon" onClick={openEditProfile}>
                    {isEditable.profile ? (
                      <MdOutlineEdit />
                    ) : (
                      <MdOutlineRemoveRedEye />
                    )}
                  </span>
                </div>
                <h6>
                  {userData.firstName} {userData.middleName} {userData.lastName}
                </h6>
                <span>{userData.EmailID || "N/A"}</span>
                <span>{userData.Mobile || "N/A"}</span>
                {userData.EmergencyContactNo && (
                  <span>{userData.EmergencyContactNo || "N/A"}</span>
                )}

                <hr />

                <div className="address">
                  <h5>Address</h5>
                  <p className="my-1">Present Address</p>
                  <p>{addressFormatter(userData, "T")}</p>
                  <p className="my-1">Permanent Address</p>
                  <p>{addressFormatter(userData, "P")}</p>
                </div>
              </div>
              <div className="button_wrap">
                <button
                  className="button"
                  type="button"
                  onClick={() => setLogout(true)}
                >
                  Logout
                </button>
              </div>
            </div>
            <div className="profile_content">
              {/* upload doc component */}
              <UploadDoc
                addressDoc={userData.addressProof}
                idDoc={userData.IDProof}
                idPhoto={userData.IDPhoto}
                isEditable={isEditable}
                flag={() => {}}
              />

              {/* Course package listing */}
              <CoursePackages />
            </div>
          </div>
        </div>
      </div>

      {/* Drawer */}

      <UpdateDrawer
        userUpdateData={userUpdateData}
        setUserUpdateData={setUserUpdateData}
        show={show}
        onClose={() => setShow(false)}
        getUserProfile={getUserProfile}
        isEditable={isEditable}
      />

      {/* logout modal */}
      <CommonModal show={logout} handleClose={() => setLogout(false)} size="md">
        <div className="logout">
          <span className="logout_icon">
            <FaExclamationCircle />
          </span>
          <h3>Logout</h3>
          <p>Are you sure want to logout?</p>

          <div className="button_wrap center">
            <button
              className="dashboard_button"
              onClick={() => setLogout(false)}
            >
              No
            </button>
            <button
              className="dashboard_button green"
              onClick={logoutHandler}
              ref={buttonRef}
            >
              Yes, log me out
            </button>
          </div>
        </div>
      </CommonModal>
    </>
  );
}

export default EditProfile;
