import React from "react";
import "./NotFound.scss";
import { NotFound as NotFoundImg } from "assets/images";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not_found spacing">
      <img src={NotFoundImg} alt="404" />
      <h4>Oops! Page not found...</h4>
      <p>Look like you're looking for something that doesn't exist. </p>
      <Link className="button" to="/">
        Go Home
      </Link>
    </div>
  );
};

export default NotFound;
