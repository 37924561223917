import React from "react";

const YoutubeVideo = ({ src, height = "300", width = "100%" }) => {
  // const handleFullscreen = () => {
  //   const player = document.getElementById("youtube-player");
  //   player.requestFullscreen(); // Enter fullscreen mode
  // };
  return (
    <>
      <iframe
        id="youtub-player"
        className="rounded"
        title="Youtube"
        width={width}
        height={height}
        src={`${process.env.REACT_APP_YOUTUBE_URI}/${src}?rel=0`}
        allowFullScreen
      ></iframe>
    </>
  );
};

export default YoutubeVideo;
