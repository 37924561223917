import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Pageimg } from "assets/icons";
import { Courseimg4 } from "assets/images";
import { errorResponse, failResponse } from "config/config";
import { countArray } from "config/utils";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { getAllCourses, getOurSpecialCourses } from "services/courses/courses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook  } from "@fortawesome/free-solid-svg-icons";
import { faLaptop, faPlay } from '@fortawesome/free-solid-svg-icons';
import "./OurCourses.scss";
// import redbackgroundimage from 'assets/images/red background for card heading.jpg';




import {
  getMasterCourseCategories,
  getMasterCourseSubCategories,
} from "services/master";
const DemoCourses = require('assets/images/GS DEMO COURSES.png');
const OptionalDemoCourses = require('assets/images/OPTIONAL DEMO COURSES.png');
const GeneralStudies = require('assets/images/SOCIOLOGY.png');
const GENERALSTUDIESMENTORSHIP = require('assets/images/7.png');
const GSPOLITY = require('assets/images/17.png');
const ANTHROPOLOGYOPTIONALCOURSES = require('assets/images/22.png');
const Csat = require('assets/images/Csat.png');
const Essay = require('assets/images/ESSAY.png');
const Modular = require('assets/images/MODULAR.png');
const QEPGSCrashCourse = require('assets/images/QEP GS Crash Course For Prelims.png')
const ncert = require('assets/images/ncert.png')
const IGSOptionalClassroom = require('assets/images/Integrated GS And Optional Classroom Course.png')
const IGSOptionalTablet = require('assets/images/Integrated GS And Optional Tablet Course.png')
const HistoryOptional = require('assets/images/history optional course.png')
const Ondemandcourse = require('assets/images/ondemand optional course.png')
const PSIROptional = require('assets/images/psir optional courses.png')
const SociologyOptional = require('assets/images/sociology optional course.png')
const RegionalCenter = require('assets/images/Regional Centre.png')
const GSFoundationCourse = require('assets/images/4.png')
const GSStudyCourse = require('assets/images/5.png')
const GSTestSeries = require('assets/images/6.png')
const BhubaneswarCentreCourse = require('assets/images/Bhubaneswar Centre Course.png')
const LucknowCenterCourse = require('assets/images/Lucknow Centre Course.png')
const MumbaiCenterCourse = require('assets/images/Mumbai Centre Course.png')
const PuneCenterCourse = require('assets/images/Pune Centre Course.png')
const Ethics = require('assets/images/Ethics.png')
const HistoryLiteratureCourses = require('assets/images/History Literature Courses.png')
const HistoryOptionalCourses = require('assets/images/History Optional Courses.png')
const GeographyOptionalCourses = require('assets/images/Geography Optional Courses.png')

// import other image assets as needed...


const TYPES = [
  { id: "UPSC", name: "UPSC" },
  { id: "SPSC", name: "State PSC" },
];

const TableStyles = {
  categoryHeading: {
    //backgroundImage: `url("assets/images/red background for card heading.png")`,
    backgroundColor: "#DD5D59",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "0px",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "145px"


  },

  headingCard: {
    backgroundColor: "#DD5D59",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    marginTop: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    height: "97px",
    zIndex: 1
  },
  tableWrapper: {
    flex: "0 0 23%", // Prevent stretching
    width: "300px", // Set fixed width for the table
    borderRadius: "10px",
    border: "1px solid #ddd",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    width: "100%",
    borderCollapse: "collapse",
    overflowY: "hidden", // Hide scroll by default
    flex: "1",
    display: "block",
    maxHeight: "280px", // Initial height before hover
    transition: "max-height 0.3s ease", // Smooth transition
  },

  tableRow: {
    borderBottom: "0px solid #ddd",
  },
  tableCell: {
    padding: "15px",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  iconCell: {
    width: "50px",
    textAlign: "center",
  },
  subCategoryImage: {
    width: "50px",
    height: "auto",
    marginRight: "10px",
  },
};



const OurCourses = () => {
  const [type, setType] = useState(TYPES[0]?.id);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  const typSelectionHandler = (type) => {
    setType(type);
    setAllCategories([]);
    setAllCourses([]);
  };

  useEffect(() => {
    // Fetch course categories and subcategories based on the selected type
    const getCourseCategoriesWithSubcategories = async () => {
      try {
        const res = await getMasterCourseCategories({ type });
        if (res.code === 200) {
          const categories = res.data.courseCategories || [];
          const categoriesWithSubcategories = await Promise.all(
            categories.map(async (category) => {
              const subCatRes = await getOurSpecialCourses({
                language: "EN",
                page: 1,
                examType: [],
                courseType: [],
                stateId: [],
                courseCategory: category.id,
                CourseSubCategory: "",
                search: "",
                platformType: "chrome",
              });
              return {
                ...category,
                subcategories: subCatRes.code === 200 ? subCatRes.data.courses.rows || [] : [],
              };
            })
          );
          setAllCourses(categoriesWithSubcategories);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    getCourseCategoriesWithSubcategories();
  }, [type]);

  return (
    <div className="our_courses spacing">
      <div className="container">
        <div className="page_title">
          <h3>Our special courses</h3>
          <p>Our specially designed and most recommended courses for upcoming exams</p>
        </div>
        <div className="course_wrap">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            {/* <div className="nav_tab">
              <Nav variant="pills">
                {TYPES.map((data) => (
                  <Nav.Item key={data.id} onClick={() => typSelectionHandler(data.id)}>
                    <Nav.Link className={type === data.id ? "active" : ""}>{data.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div> */}
            <div className="navtab_content">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="ourcoursesscrollContainer">
                      {allCourses.map((category) => (
                        <div className="tableWrapper" key={category.id} style={TableStyles.tableWrapper}>
                          {/* Category Heading */}
                          <div style={{ ...TableStyles.headingCard, position: 'relative', overflow: 'hidden' }}>
                            {/* <img
                              src={redbackgroundimage}
                              alt="Background"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                zIndex: 1
                              }}
                            /> */}
                            <div style={{
                              ...TableStyles.categoryHeading,
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 2,
                              color: 'white',
                              textAlign: 'center'
                            }}>
                              {category.name}
                            </div>
                          </div>

                          {/* Courses List */}
                          <table style={TableStyles.tableContainer}>
                            <tbody>
                              {category.subcategories.length === 0 ? (
                                <tr style={TableStyles.tableRow}>
                                  <td colSpan="2" style={TableStyles.tableCell}>No Data Available</td>
                                </tr>
                              ) : (
                                category.subcategories.map((course) => (
                                  <tr key={course.CourseID} style={TableStyles.tableRow}>
                                    {/* <td style={TableStyles.iconCell}>
                                      <div style={{ position: 'relative', display: 'inline-block', width: '18px', height: '50px', marginTop: "20px" }}>
                                        <FontAwesomeIcon icon={faLaptop} size="2x" style={{ color: '#3A69B0' }} />
                                        <div style={{
                                          position: 'absolute',
                                          top: '28%',
                                          left: '50%',
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: '#3A69B0',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          transform: 'translate(50%, -50%)',
                                        }}>
                                          <FontAwesomeIcon
                                            icon={faPlay}
                                            size="1x"
                                            style={{ color: 'white', width: "3px" }}
                                          />
                                        </div>
                                      </div>
                                    </td> */}
                                    <td style={TableStyles.iconCell}>
                                      <div style={{ position: 'relative', display: 'inline-block',height: '50px', marginTop: "20px", marginLeft: "28px" }}>
                                      <img src={Pageimg} alt="" loading="lazy" style={{width:"22px"}}/>
                                      </div>
                                    </td>

                                    <td style={TableStyles.tableCell}>
                                      <Link to={course.staticurlcourses[0]?.staticurl || '#'}>
                                        {course.DisplayName}
                                      </Link>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};


export default OurCourses;