import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";
const updatedEndpoint = "/api/v1/admin/site-setting-list?reqData=";

export const getSiteSetting = async (data = {}) => {
    return decryptData(
      await axios.get(
        `https://nodesrapi.shubhraranjan.com${updatedEndpoint}${{
          ...data,
          platformType: getBrowserName(),
          access_token: "",
        }}`
      )
    );
    
  };