import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../drawer/Drawer.scss";
function LectureDrawer({ show, handleClose, children, title, className }) {
	return (
		<div className="drawer">
			<Offcanvas className={`drawer ${className} ${title ? "" : "withoutTitle"}`} show={show} onHide={handleClose} placement="end">
				<Offcanvas.Header closeButton>
					{title && <Offcanvas.Title>{title}</Offcanvas.Title>}
				</Offcanvas.Header>
				<div style={{
					display:"flex",
					justifyContent:"center",
					alignItems:"center",
				}}>
					Clicking on course will redirect to recorded lecture screen

				</div>
				<Offcanvas.Body>{children}</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
}
export default LectureDrawer;