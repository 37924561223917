import { nographDataImg } from "assets/images";
import { errorResponse, failResponse } from "config/config";
import React, { useMemo, useState } from "react";
import Chart from "react-google-charts";
import { Link } from "react-router-dom";
import { getTestGraph } from "services/dashboard";
import { getSignedUrl } from "services/master";

const TestPerformance = ({ selectedTest, testRef }) => {
  const [testScore, setTestScore] = useState([
    ["Test Performance", "Score", { role: "style" }],
  ]);

  const [pieScore, setPieScore] = useState([]);

  const [bestCopies, setBestCopies] = useState([]);
  const [myScore, setMyScore] = useState(0);

  // Data Chart//
  const peiOptions = {
    title: "",
  };

  const options = {
    chart: {
      title: "Test Performance",
      subtitle: "Score",
    },
  };

  const isPieAvailable = useMemo(() => {
    return pieScore.slice(1).every((data) => data[1] && data[1] > 0);
  }, [pieScore]);

  // ----------------------- getting live lecture data -------------------------//
  React.useEffect(() => {
    const getTestGraphNBestCopy = async () => {
      try {
        const res = await getTestGraph({ testId: selectedTest });

        if (res.code === 200) {
          const { bestcopies, myscore, avg, toppers, piChart } = res.data;
          setBestCopies(bestcopies || []);
          setMyScore(+myscore || 0);

          const [first, second, third] = toppers;

          // setTestScore([
          //   ["Test Performance", "Score", { role: "style" }],
          //   ["My score", +myscore || 0, "color: #000"],
          //   ["Average score", avg || 0, "color: #1BB62E"],
          //   ["First topper", first?.TotalMarks || 0, "color: #F02641"],
          //   ["Second topper", second?.TotalMarks || 0, "color: #C0C0C0"],
          //   ["Third topper", third?.TotalMarks || 0, "color: #FF4E02"],
          // ]);
          setTestScore([
            ["Test Performance", "Score", { role: "style" }],
            ["My score", +myscore || 0, "color: #000"],
            ["Average score", avg || 0, "color: #1BB62E"],
            ["First topper", first?.Marks || 0 || first?.MarksObtained || 0, "color: #F02641"],
            ["Second topper", second?.Marks || 0 || second?.MarksObtained || 0, "color: #C0C0C0"],
            ["Third topper", third?.Marks ||  0 || third?.MarksObtained || 0, "color: #FF4E02"],
          ]);

          const pieData = piChart?.map((data) => {
            return [data.renge, data.value];
          });

          setPieScore([["Score", "Test Result"], ...pieData]);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (selectedTest) {
      getTestGraphNBestCopy();
    }
  }, [selectedTest]);

  // const handlebestcopy = async (fileurl) => {
  //   if (fileurl) {
  //     try {
  //       const signedUrl = await getSignedUrl({ pdfUrl: fileurl });
  //       window.open(signedUrl);
  //     } catch (error) {
  //       console.error('Error fetching signed URL:', error);
  //     }
  //   } else {
  //     console.error("error");
  //   }
  // };


  return (
    <>
      <div className="chart_wrapper" ref={testRef}>
        <div className="_box _chart">
          <h4>Test result performance</h4>

          {testScore ? (
            <>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={testScore}
                options={options}
              />
            </>
          ) : (
            <>
              <div className="not_found">
                <img src={nographDataImg} alt="" />
                <h6>Progress Not Available</h6>
                <p>
                  Whoops... this information is not <br />
                  available
                </p>
              </div>
            </>
          )}
        </div>

        <div className="_box _chart">
          <h4>Test copy result</h4>
          {isPieAvailable ? (
            <>
              <Chart
                chartType="PieChart"
                data={pieScore}
                options={peiOptions}
                width={"100%"}
                height={"400px"}
              />
            </>
          ) : (
            <div className="not_found">
              <img src={nographDataImg} alt="" />
              <h6>Result Not Found</h6>
              <p>
                Whoops... this information is not <br />
                available for a moment
              </p>
            </div>
          )}
          <ul className="my_score">
            <li>My Score : {myScore}</li>
            {bestCopies.map((data, i) => {
              return (
                <li key={i}>
                  <Link to={data.BestCopyFile} 
                  target="_blank"
                  // onClick={() => handlebestcopy(data.BestCopyFile)}
                  >
                    Best Copy {++i}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TestPerformance;
