import React from "react";
import { Placeholder } from "react-bootstrap";

const CourseDetailSkeleton = () => {
  return (
    <>
      <div className="banner spacing">
        <div className="container">
          <div className="banner_content">
            <div className="content">
              {/* <span className="_tag">MOST POPULAR</span> */}
              <h3>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
              </h3>

              <Placeholder as="div" animation="wave" aria-hidden="true">
                <Placeholder
                  xs={12}
                  className="rounded"
                  style={{ height: `30vh` }}
                />
              </Placeholder>

              <div className="button_wrap">
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder
                    xs={4}
                    className="rounded"
                    style={{ height: `7vh` }}
                  />
                </Placeholder>
              </div>
            </div>
            <div className="video_section">
              <Placeholder as="div" animation="wave" aria-hidden="true">
                <Placeholder
                  xs={12}
                  style={{ height: `50vh` }}
                  className="rounded"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>

      {/* number  data  */}
      <div className="spacing">
        <Placeholder as="div" animation="wave" aria-hidden="true">
          <Placeholder xs={12} className="rounded" style={{ height: `30vh` }} />
        </Placeholder>
      </div>

      {/* tab content */}
      <div className="tab_content multitab spacing">
        <Placeholder as="div" animation="wave" aria-hidden="true">
          <Placeholder xs={12} className="rounded" style={{ height: `70vh` }} />
        </Placeholder>
      </div>
    </>
  );
};

export default CourseDetailSkeleton;
