import { countArray } from "config/utils";
import React from "react";
import { Placeholder } from "react-bootstrap";

const TestUnitSkeleton = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-9">
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder
                xs={12}
                style={{ height: `3vh`, borderRadius: "5px" }}
              />
              <Placeholder
                xs={12}
                style={{
                  height: `30vh`,
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              />
              {/* options */}
              {countArray(2).map((data) => {
                return (
                  <div className="row my-4" key={data}>
                    <div className="col-sm-4  mx-auto">
                      <Placeholder
                        xs={12}
                        style={{
                          height: `5vh`,
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mx-auto">
                      <Placeholder
                        xs={12}
                        style={{
                          height: `5vh`,
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  </div>
                );
              })}

              {/* footer buttons */}
              <div className="row my-5">
                <div className="col-sm-6 text-center">
                  <Placeholder
                    xs={12}
                    className="mx-2"
                    style={{
                      height: `5vh`,
                      width: "30%",
                      borderRadius: "5px",
                    }}
                  />
                  <Placeholder
                    xs={12}
                    className="mx-2"
                    style={{
                      height: `5vh`,
                      width: "30%",
                      borderRadius: "5px",
                    }}
                  />
                </div>

                <div className="col-sm-6 text-center">
                  <Placeholder
                    xs={12}
                    className="mx-2"
                    style={{
                      height: `5vh`,
                      width: "30%",
                      borderRadius: "5px",
                    }}
                  />
                  <Placeholder
                    xs={12}
                    className="mx-2"
                    style={{
                      height: `5vh`,
                      width: "30%",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            </Placeholder>
          </div>
          <div className="col-sm-3">
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder
                xs={12}
                style={{ height: `70vh`, borderRadius: "10px" }}
              />
            </Placeholder>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestUnitSkeleton;
