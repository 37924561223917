import { noPurchesCourse } from "assets/images";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { countArray, momentDateFormat } from "config/utils";
import React, { useState } from "react";
import toast from "react-hot-toast";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { userPurchaseBillingCourses } from "services/dashboard";
import { errorResponse, failResponse } from "config/config";

const CoursePackages = () => {
  const [allPackages, setAllPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  // get course packages list
  const getCoursePackages = async () => {
    try {
      setLoading(true);
      const res = await userPurchaseBillingCourses();
      setLoading(false);

      if (res.code === 200) {
        setAllPackages(res.data || []);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // download invoice function
  const downloadInvoiceHandler = async (invoice) => {
    if (invoice) {
      window.open(invoice);
    } else {
      toast.error("file is not available");
    }
  };

  // --------------- getting all required master data ----------------//
  React.useLayoutEffect(() => {
    // get user course packages
    getCoursePackages();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="course_package">
        <h5>Course package</h5>

        {/* loading component */}
        {loading && (
          <>
            <ul>
              {countArray(4).map((num) => {
                return (
                  <li key={num} className="py-1">
                    <ListCourseSkeleton height={80} />
                  </li>
                );
              })}
            </ul>
          </>
        )}

        {/* main component */}
        {!loading && allPackages?.length > 0 && (
          <>
            <ul>
              {allPackages.map(
                ({ CourseData, CourseID, BTransactionID, invoice_path }, i) => {
                  return (
                    <li key={i}>
                      <h6>{CourseData?.CourseTitle || "N/A"}</h6>
                      <div className="date">
                        <span>Start date</span>
                        <b>{momentDateFormat(CourseData?.CourseStartDate)}</b>
                      </div>
                      <div className="date">
                        <span>End date</span>
                        <b>{momentDateFormat(CourseData?.CourseEndDate)}</b>
                      </div>
                      <div className="button_wrap">
                        <button
                          type="button"
                          className="dashboard_button"
                          onClick={() => downloadInvoiceHandler(invoice_path)}
                          disabled={!invoice_path}
                        >
                          Download invoice
                        </button>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </>
        )}

        {/* not found component */}
        {!loading && allPackages?.length <= 0 && (
          <>
            <LectureNoteFound
              note="No Course Purchase"
              image={noPurchesCourse}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CoursePackages;
