import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const GetUPSCcontent = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/get-upsc-content?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
export const GetPrelimsPYQ = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/get-prelims-pyq?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

