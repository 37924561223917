import React from "react";
import { Courses } from "assets/images";

const CourseNotFound = ({ title = "" }) => {
  return (
    <>
      <div className="listing_card not_found">
        <div className="not_fount_image">
          <img src={Courses} alt="" loading="lazy" />
        </div>
        <h5>{title} not available</h5>
      </div>
    </>
  );
};

export default CourseNotFound;
