import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const userLogin = async (data) => {
  return decryptData(
    await axios.post(`/login`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const userLogout = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/logout?reqData=${encryptData({
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const userRegister = async (data) => {
  return decryptData(
    await axios.post(`/register`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const userwebRegister = async (data) => {
  return decryptData(
    await axios.post(`/websiteRegister`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const userSendOTP = async (data) => {
  return decryptData(
    await axios.post(`/sendOTP`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const userVerifyOTP = async (data) => {
  return decryptData(
    await axios.post(`/verifyOTP`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const userVerifmobileyOTP = async (data) => {
  return decryptData(
    await axios.post(`/verifyMobileOTPWebsite`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const registermobilewithnumber = async (data) => {
  return decryptData(
    await axios.post(`/registerMobileNumberWithEmail`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const forgotPassword = async (data) => {
  return decryptData(
    await axios.post(`/forgot-password`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const changePassword = async (data) => {
  return decryptData(
    await axios.post(`/change-password`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};

export const resetPassword = async (data) => {
  return decryptData(
    await axios.post(`/reset-password`, {
      reqData: encryptData({ ...data, platformType: getBrowserName() }),
    })
  );
};
