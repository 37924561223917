import { encryptData, errorResponse, failResponse } from "config/config";
import { dateFormat, getBrowserName } from "config/utils";
import { useCourse } from "context/context";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { getReUploadAnswerSheet, reuploadMainsTest } from "services/dashboard";
import "../course-report/CourseReport.scss";
import "./our-toppers.scss";
import useButtonLoader from "hooks/useButtonLoader";
import CommonModal from "components/modal-box/CommonModal";
import toast from "react-hot-toast";
import useCookie from "hooks/useCookie";
import Pagination from "react-js-pagination";
import { FaCommentAlt, FaDownload, FaUpload } from "react-icons/fa";
import FileUploader from "components/FileUploader/FileUploader";
import PercentageLoader from "Loader/PercentageLoader";
import { getSignedUrl } from "services/master";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";

import {
    testpapericon
  } from "assets/images";

const OurToppers = () => {
  

  return (
    <>
    <div className="ourStory">
      <div className="our_beliefs spacing">
        <div className="container">
          <div className="page_title">
            <h3>Topper's Copies</h3>
          </div>

          <div className="topperdiv1 height125">
          <h5>RAVULA JAYASIMHA REDDY (AIR - 104, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638493765101296795.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638493765101437151.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638493765101686909.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638493765101796945.pdf">Test 4, PSIR Crash Course</a></div>
                   
                   
                
          </div>

          <div className="sepdiv">&nbsp;</div>

          <div className="topperdiv2 height100">
          <h5>SHASHWAT AGRAWAL (AIR - 121, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638493777902955801.pdf">Test 1, PSIR Crash Course</a></div>
                   
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height150">
          <h5>KUNAL RASTOGI (AIR - 15, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490479669611092.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490479670070946.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490479670211153.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490479670351117.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490479670500983.pdf">Test 6, PSIR Crash Course</a></div>
                   
                
          </div>

          <div className="sepdiv">&nbsp;</div>

          <div className="topperdiv2 height125">
          <h5>SWATI SHARMA (AIR - 17, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490481521618702.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490481522180772.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490481522779702.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490481522938770.pdf">Test 4, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height100">
          <h5>SURABHI SRIVASTAVA (AIR - 56, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490545015297233.pdf">Test 3, PSIR Crash Course</a></div>
                   
                   
                
          </div>

          <div className="sepdiv">&nbsp;</div>

          <div className="topperdiv2 height150">
          <h5>SRISHTI DABAS (AIR - 6, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490472562580793.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490477069410037.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490477069770176.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490477070059149.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490477070299714.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490494894978338.pdf">Test 2, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height125">
          <h5>ABHIMANYU MALIK (AIR - 60, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490547338227430.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490547338677354.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490547339347578.pdf">Test 5, PSIR Crash Course</a></div>
                   
            </div>
            <div className="sepdiv">&nbsp;</div>
            <div className="topperdiv2 height125">
          <h5>KRISHNA JOSHI (AIR - 73, 2023)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490549194365973.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490549194685939.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490549194936960.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638490549195286259.pdf">Test 4, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height150">
          <h5>Ishita Kishore (AIR - 1, 2022)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331956460879.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331956773263.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331957398302.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331957554561.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331957867071.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218331958023266.pdf">Test 8, PSIR Crash Course</a></div>
            </div>
            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv2 height150">
          <h5>Ayasha Fatima (AIR - 184, 2022)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218344325187751.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218344325344221.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218344325500422.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218344325657255.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218344325812751.pdf">Test 7, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height150">
          <h5>Kanika Goyal (AIR - 9, 2022)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218340508485490.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218340508798205.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218340509110640.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218340509267053.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/638218340509579382.pdf">Test 5, PSIR Crash Course</a></div>
            </div>
            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv2 height125">
          <h5>Ankita Agarwal (AIR - 2, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896751435741708.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896751435931743.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896751436161719.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896751436501709.pdf">Test 8, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height125">
          <h5>Arpit Chauhan (AIR - 20, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896770972175162.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896770972385222.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896770972625171.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896770972805204.pdf">Test 8, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>
            <div className="topperdiv2 height125">
          <h5>Bhavishya (AIR - 29, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896836007663085.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896836008009600.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896836008293028.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896836008443021.pdf">Test 8, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height125">
          <h5>Utkarsh Dwivedi (AIR - 5, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896755485970938.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896755486290966.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896755486551008.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896755486800945.pdf">Test 5, PSIR Crash Course</a></div>
            </div>
            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv2 height125">
          <h5>Samyak S Jain (AIR - 7, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896758082716106.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896758082806088.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896758083206081.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896758083466105.pdf">Test 7, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height125">
          <h5>Charu (AIR - 76, 2021)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896840833992266.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896840834248194.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896840834538393.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637896840834728436.pdf">Test 6, PSIR Crash Course</a></div>
            </div>
            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv2 height125">
          <h5>Satyam Gandhi (AIR - 10, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686037536560694.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686037536716974.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686037536873209.pdf">Test 4, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height175">
          <h5>Ria Dabi (AIR - 15, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045488595250.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045488907694.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489064088.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489220286.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489376549.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489532698.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489689010.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686045489845339.pdf">Test 8, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>
            <div className="topperdiv2 height150">
          <h5>Sadaf Choudhary (AIR - 23, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047039670766.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047039827087.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047039983440.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047040139552.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047040295688.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686047040295688.pdf">Test 6, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height150">
          <h5>Vaibhav Rawat (AIR - 25, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686048985162405.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686048985318722.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686048985475104.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686048985475104.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686048985631244.pdf">Test 5, PSIR Crash Course</a></div>
            </div>


            <div className="sepdiv">&nbsp;</div>
            <div className="topperdiv2 height175">
          <h5>Sai Manasa NC  (AIR - 48, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788092360.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788248557.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788404993.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788561149.pdf">Test 4, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788717339.pdf">Test 5, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050788873651.pdf">Test 6, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050789029915.pdf">Test 7, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686050789186079.pdf">Test 8, PSIR Crash Course</a></div>
            </div>

            <div className="sepdiv">&nbsp;</div>

            <div className="topperdiv1 height125">
          <h5>Nisha (AIR - 51, 2020)</h5>
                
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686054473909213.pdf">Test 1, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686054474065440.pdf">Test 2, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686054474221756.pdf">Test 3, PSIR Crash Course</a></div>
                    <div className="innerdiv"><img className="pdftesticoncls" src={testpapericon} />&nbsp;<a target="_blank" href="https://admin.shubhraviraj.in/storedata/ToppersCopyPDF/637686054474377995.pdf">Test 4, PSIR Crash Course</a></div>
            </div>


          </div>
          </div>
        </div>
      
      
      
    </>
  );
};

export default OurToppers;
