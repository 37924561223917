import React from "react";

export const Input = ({
  value,
  onChange,
  className,
  label,
  error,
  required,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label className="label">
          {label}
          {required && <span className="required mx-1"> *</span>}
        </label>
      )}
      <input
        value={value}
        onChange={onChange}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const Select = ({
  label,
  onChange,
  value,
  error,
  className,
  children,
  required,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label className="label">
          {label}
          {required && <span className="required mx-1"> *</span>}
        </label>
      )}
      <select
        onChange={onChange}
        value={value}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const TextArea = ({
  value,
  onChange,
  label,
  className,
  error,
  ...rest
}) => {
  return (
    <>
      {label && <label className="my-1">{label}</label>}
      <textarea
        value={value}
        onChange={onChange}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};
