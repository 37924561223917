import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Slider from "react-slick";
import { Courseimg1, Forming, SignUp11, SignUp22 } from "assets/images";
import "./Form.scss";
import SuccessScreen from "./Section/Register/Success";
import OTPVerifyScreen from "./Section/Register/OTPVerify";
import RegisterScreen from "./Section/Register/Register";
import MobileOTPVerifyScreen from "./Section/Register/MobileOTPVerify";
import { getBrowserName, getOSDetail, validateEmail } from "config/utils";
import { toast } from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import { userRegister } from "services/auth";
import { userwebRegister } from "services/auth";
import useCookie from "hooks/useCookie";
import { getUserIp } from "services/third-party";
import { errorResponse, failResponse } from "config/config";
import { useCourse } from "context/context";


const SignUp = () => {
  const [createAccount, setCreateAccount] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileOtpSent, setIsMobileOtpSent] = useState(false);


  const [submitButtonRef, setSubmitLoading] = useButtonLoader(
    "Create Account",
    true
  );

  const navigate = useNavigate();
  const { cookies } = useCookie();
  // signup state
  const [signUpData, setSignUpData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    examId: "",
    signupType: "normal",
    userId: "",
    tnc: false,
  });

  const [errorField, setErrorField] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    examId: "",
    signupType: "normal",
    tnc: false,
  });


  // onchange handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value })
     
  
  };

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!signUpData.firstName) {
      errorField["firstName"] = "Please enter first name";
      formIsValid = false;
    }
    if (!signUpData.lastName) {
      errorField["lastName"] = "Please enter last name";
      formIsValid = false;
    }

    if (!signUpData.email) {
      errorField["email"] = "Please enter email";
      formIsValid = false;
    } else if (!validateEmail(signUpData.email)) {
      errorField["email"] = "Please enter valid email";
      formIsValid = false;
      setIsValidEmail(false);
    }

    if (!signUpData.mobile) {
      errorField["mobile"] = "Please enter mobile No.";
      formIsValid = false;
    }
    // if (!signUpData.password) {
    //   errorField["password"] = "Please enter password";
    //   formIsValid = false;
    // }
    if (!signUpData.examId) {
      errorField["examId"] = "Please select exam";
      formIsValid = false;
    }
    if (!signUpData.tnc) {
      errorField["tnc"] = "Please Select Terms and Conditions";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  // signup handler
  // const signUpHandler = async (e) => {
  //   e.preventDefault();
  //   if (handleValidation()) {
  //     setSubmitLoading(true);
  //     try {
  //       const { data: ip } = await getUserIp();
  //       const res = await userRegister({
  //         ...signUpData,
  //         device: "Web",
  //         platformType: getBrowserName(),
  //         os: getOSDetail(),
  //         IPaddress: ip.ip,
  //         userType: "login",
  //       });

  //       if (res.code === 200) {
  //         toast.success(res.message);
  //         if (res.message === 'Otp sent successfully') {
  //           setIsMobileOtpSent(true);
  //         }
  //         setSignUpData({ ...signUpData, userId: res.data.UserID });
  //         setSubmitLoading(false);
  //         setCreateAccount(true);
  //       } else {
  //         failResponse(res);
  //         setSubmitLoading(false);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //       setSubmitLoading(false);
  //     }
  //   } else {
  //     toast.error("Please Fill All Fields");
  //   }
  // };


  const signUpHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSubmitLoading(true);
      try {
        const { data: ip } = await getUserIp();
        const res = await userwebRegister({
          ...signUpData,
          device: "Web",
          platformType: getBrowserName(),
          os: getOSDetail(),
          IPaddress: ip.ip,
          userType: "login",
           
        });

        if (res.code === 200) {
          toast.success(res.message);
          if (res.message === 'Otp sent') {
            setIsMobileOtpSent(true);
          }
          setSignUpData({ ...signUpData, userId: res.data.UserID});
          setSubmitLoading(false);
          setCreateAccount(true);
          localStorage.setItem('userMobile', signUpData.mobile);
        } else {
          failResponse(res);
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please Fill All Fields");
    }
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: "linear",
  };

  React.useLayoutEffect(() => {
    if (cookies) navigate("/dashboard/home");
    // eslint-disable-next-line
  }, [cookies]);

  return (
    <div className="login signup spacing">
      <div className="form_container">
        <div className="content_section">
          <div className="content">
            <Slider {...settings}>
              <div className="item">
                <div className="item_content">
                  <div className="image_wrap">
                    <img src={SignUp11} style={{height:"58%"}} alt="students" loading="lazy" />
                  </div>
                  <div style={{height:"1px" }} className="text">
                  <p>
                    If you want to be an
                    IAS, IFS, IPS, IRS
                    officer like them then join us …
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item_content">
                  <div className="image_wrap">
                    <img src={SignUp22} style={{height:"58%"}} alt="students" loading="lazy" />
                  </div>
                  <div style={{height:"1px" }} className="text">
                  <p>
                    If you want to be an
                    IAS, IFS, IPS, IRS
                    officer like them then join us …
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className="form_section">
            <form className="form">
              {otpVerified === true ? (
                // Success Screen
                <SuccessScreen />
              ) : isMobileOtpSent ? (
                // Render MobileOTPVerifyScreen when isMobileOtpSent is true
                <MobileOTPVerifyScreen
                  setOtpVerified={setOtpVerified}
                  signUpData={signUpData}
                  isEmailVerified={isEmailVerified}
                />
              ) : (
                <>
                  {createAccount === true ? (
                    <OTPVerifyScreen
                      setOtpVerified={setOtpVerified}
                      signUpData={signUpData}
                      isEmailVerified={isEmailVerified}
                    />
                  ) : (
                    <RegisterScreen
                      signUpHandler={signUpHandler}
                      onChangeHandler={onChangeHandler}
                      signUpData={signUpData}
                      setSignUpData={setSignUpData}
                      errorField={errorField}
                      isValidEmail={isValidEmail}
                      isEmailVerified={isEmailVerified}
                      submitButtonRef={submitButtonRef}
                      setIsEmailVerified={setIsEmailVerified}
                    />
                  )}

                  <p className="divider">OR</p>
                  {/* <Link to="" onClick={googleSignupHandler} className="logwith">
                    <img src={GoogleIcon} alt="google plus" loading="lazy" />
                    Continue with Google
                  </Link> */}
                  <p className="signup_text">
                    <span>Already registered? </span>
                    <Link to="/login" className="mx-2">
                      {" "}
                      Sign In
                    </Link>
                  </p>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
