import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName } from "config/utils";

export const centerBasedLandingPage = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/center/based/landing/page?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
// get course data by exam id (center based landing page)
export const courseByExam = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/center/based/landing/page/course?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
