import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileCenterMenu = ({ menuClose }) => {
  const [freeresourcemenu, setFreeresourceMenu] = useState([
    { region: "About UPSC", key: "FRAboutUPSC" },
    { region: "Prelims", key: "FRPrelims" },
    { region: "Mains", key: "FRMains" },
    // { region: "Optional", key: "FROptional" },
    // { region: "Interview", key: "FRInterview" },
  ]);

  return (
    <>
      <ul onClick={menuClose}>
        {freeresourcemenu &&
          freeresourcemenu.map((data, index) => {
            return (
              <li key={index}>
                <Link to={`freeresources/${data.key}`}>
                  {data.region}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default MobileCenterMenu;
