import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { Input, Select } from "components/Form";
import { getExamTypes } from "services/master";
import { errorResponse, failResponse } from "config/config";
import { isLoggedIn } from "config/utils";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import toast from "react-hot-toast";
import { GoogleIcon } from "assets/icons";

const RegisterScreen = ({
  signUpHandler,
  onChangeHandler,
  signUpData,
  setSignUpData,
  errorField,
  isValidEmail,
  isEmailVerified,
  submitButtonRef,
  setIsEmailVerified,
}) => {
  const [allExamTypes, setAllExamTypes] = React.useState([]);
  const location = useLocation();
  useEffect(() => {
    const remcname = location.state?.remcname || localStorage.getItem('remcname');
    const remcid = location.state?.remcid || localStorage.getItem('remcid');
    
    if (location.state?.remcname) {
      if (isEmailVerified) {
        localStorage.setItem('remcname', location.state.remcname);
      } else {
        localStorage.removeItem('remcname');
      }
    }
    
    if (location.state?.remcid) {
      if (isEmailVerified) {
        localStorage.setItem('remcid', location.state.remcid);
      } else {
        localStorage.removeItem('remcid');
      }
    }
    
    console.log("remcname", remcname);
    console.log("remcid", remcid);
  }, [location.state, isEmailVerified]);
  
  



  const navigate = useNavigate();

  //this function is used for verify email by google
  const googleSignupHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );
        if (data?.email) {
          setIsEmailVerified(true);
          
          setSignUpData({
            ...signUpData,
            email: data?.email,
            signupType: "social",
            socialId: data?.id,
            
          });
        }
      } catch (err) {
        toast.error("Signup Issue");
      }
    },
  });

  // --------------- getting all home page data ----------------//
  React.useLayoutEffect(() => {
    const getExamType = async () => {
      try {
        const res = await getExamTypes();

        if (res.code === 200) {
          setAllExamTypes(res.data.examType.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    getExamType();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const storedMobile = localStorage.getItem('userMobile');
    if (storedMobile) {
      setSignUpData((prevData) => ({ ...prevData, mobile: storedMobile }));
    }
  }, []); // Run only once on component mount



  return (
    <>
      {/*Registration  Form */}
      <div className="form_title">
       
        <h3>Sign Up </h3>
        <p>To purchase courses, get content & more<br/>
        {/* <span style={{color:"#FF0000"}}>System extracts your Gmail ID after clicking on "Sign Up with Google" button</span> */}
        </p>
        <h5 style={{float:"left",color:"rgb(255, 0, 0)"}}>Step 1 :</h5> 
      </div>
      
      <Link to="" onClick={googleSignupHandler} className="logwith">
        <img src={GoogleIcon} alt="google plus" loading="lazy" />
        Click to Select Gmail ID
      </Link>
      <div style={{marginTop:"-30px",textAlign:"center", width:"100%",clear:"both", marginBottom:"10px", color:"#4971B2"}}><b>{signUpData.email}</b></div>
      <div className="form_field_wrap" style={{marginTop:"-19px"}}>
      <h5 style={{float:"left",color:"rgb(255, 0, 0)"}}>Step 2 :</h5> 
      </div>
    
      <div className="form_field_wrap ">
      <Input type="hidden" name="email" value={signUpData.email} />    
        <div className="form_field">
          <Input
            type="text"
            name="firstName"
            value={signUpData.firstName || ""}
            onChange={onChangeHandler}
            onKeyPress={(event) => {
              if (!/[a-zA-Z]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Enter first name"
            className={`input form-control `}
            maxLength={50}
            label={"First Name"}
            error={errorField.firstName}
            required
          />
        </div>
        <div className="form_field">
          <Input
            type="text"
            name="lastName"
            value={signUpData.lastName || ""}
            onChange={onChangeHandler}
            onKeyPress={(event) => {
              if (!/[a-zA-Z]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Enter last name"
            className={`input form-control `}
            maxLength={50}
            label={"Last Name"}
            error={errorField.lastName}
            required
          />
        </div>
        {/* <div className="form_field has_icon">
          <Input
            type={type}
            name="password"
            value={signUpData.password || ""}
            onChange={onChangeHandler}
            placeholder="Enter password"
            className={`input form-control d-inline `}
            maxLength={50}
            label={"Password"}
            error={errorField.password}
            required
          />
          <span className="eye_icon open">{icon}</span>
        </div> */}
      </div>
      <div className="form_field_wrap">
      
        <div className="form_field">
          <Input
            type="text"
            name="middleName"
            value={signUpData.middleName || ""}
            onChange={onChangeHandler}
            onKeyPress={(event) => {
              if (!/[a-zA-Z]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Enter middle name"
            className={`input form-control `}
            maxLength={50}
            label={"Middle Name"}
            error={errorField.middleName}
          />
        </div>

        <div className="form_field">
          <Input
            type="text"
            name="mobile"
            value={signUpData.mobile || ""}
            onChange={({ target }) =>
              setSignUpData({
                ...signUpData,
                mobile: target.value.replace(/\D/g, ""),
              })
            }
            placeholder="Enter your phone number"
            className={` input form-control `}
            maxLength={10}
            label={"Number"}
            error={errorField.mobile}
            required
          />
        </div>
        
      </div>
      <div className="form_field_wrap">

       
        
        <div className="form_field">
          <Select
            name="examId"
            placeholder="Which exam(s) would you like to enroll for?"
            onChange={onChangeHandler}
            value={signUpData.examId}
            className={`input form-control `}
            label={"Exam"}
            error={errorField.examId}
            required
          >
            <option value="" hidden>
              Which exam(s) would you like?
            </option>
            {allExamTypes?.map((data) => {
              return (
                <option value={data.id} key={data.id}>
                  {data.title}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    

      <div className="check-box">
        <Input
          type="checkbox"
          id="check"
          // className={`form-control ${
          //   !signUpData.tnc && errorField.tnc && "is-invalid"
          // }`}
          checked={signUpData.tnc}
          onChange={() =>
            setSignUpData({ ...signUpData, tnc: !signUpData.tnc })
          }
        />
        <label htmlFor="check">
          I agree to Shubhra Ranjan’s{" "}
          <Link to="/terms-and-condition"> terms of service</Link> and
          <Link to="/privacy-policy">privacy policy</Link>
        </label>
      </div>
      <div className="invalid-feedback">{errorField.tnc}</div>
      <div className="button_wrap">
        <Link
          to="#"
          onClick={signUpHandler}
          className="button"
          ref={submitButtonRef}
        >
          Create Account
          <span className="icon">
            <BsArrowRight />
          </span>
        </Link>
      </div>
    </>
  );
};

export default React.memo(RegisterScreen);
