import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Successimg } from "assets/icons";
import { BsArrowRight } from "react-icons/bs";

const Success = () => {
  const [time, setTime] = React.useState(15);
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 0) {
        clearInterval(interval);
        navigate("/dashboard/home");
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [time]);

  return (
    <>
      <div className="otp_verified">
        <img src={Successimg} alt="success" loading="lazy" />
        <h3>YOU HAVE SUCCESSFULLY SIGNED IN!</h3>
        <p>
          You are now one step closer to learning & training with the best
          faculty in the country
        </p>
        <p>
          You will redirect to dashboard after delay of{" "}
          <span className="text-danger fw-bold">{time} seconds</span>.
        </p>
      </div>
      <div className="button_wrap">
        <Link to="/dashboard/home" className="button">
          Go To Dashboard
          <span className="icon">
            <BsArrowRight />
          </span>
        </Link>
      </div>
    </>
  );
};

export default React.memo(Success);
