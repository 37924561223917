import React, { useState, useLayoutEffect, useRef } from "react";
import { NavLink } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { homeData } from "services/home";
import { errorResponse, failResponse } from "config/config";
import { Row, Col, Nav } from "react-bootstrap";
import "../../Normal/CenterMenu.css";

const CenterMenu = () => {
  const [regionalProgram, setRegionalProgram] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onHover, setOnHover] = useState(false);
  const menuRef = useRef();

  // --------------- getting all required master data with courses ----------------//
  useLayoutEffect(() => {
    const getHomeData = async () => {
      try {
        setLoading(true);
        const res = await homeData({ type: "home" });

        if (res.code === 200) {

          const sortedRegionalPrograms = res.data.ourPrograms.regional.sort((a, b) => {
            // Compare the `name` property of each object
            if (a.region < b.region) {
              return -1;
            }
            if (a.region > b.region) {
              return 1;
            }
            return 0;
          });          

          setRegionalProgram(sortedRegionalPrograms || []);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    // calls all the master functions
    getHomeData();

    // eslint-disable-next-line
  }, []);

  return (
    <li
      className={`has-menu ${onHover ? "active" : ""}`}
      ref={menuRef}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={{ position: "relative" }} // Ensure position is relative
    >
      <NavLink to="" className="nav_link">
        Our Centers{" "}
        <span className="icon">
          <MdKeyboardArrowDown />
        </span>
      </NavLink>
      <div
        className={`megamenu1 ${onHover ? "show" : ""}`}
        style={{
          position: "absolute",
          top: "100%",
          left: "0",
          zIndex: "1000",
          width: "400px",
          marginTop: "22px", // Adjust the margin top as needed
        }}
      >
        <Row className="justify-content-right">
          <Col>
            <Nav className="flex-column">
              {regionalProgram.map((program, index) => (
                <Nav.Item key={index}>
                  <Nav.Link href={`IAS_Institute_${program.region.replace("Shubhra Ranjan IAS Study, ", "")}`}>
                    {program.region}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
        </Row>
      </div>
    </li>
  );
};

export default CenterMenu;
