import React, { useMemo, useState } from "react";

const CollapseSuperTag = ({ children, limit, scroller, ...rest }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    if (!isCollapsed) {
      scroller();
      setIsCollapsed(!isCollapsed);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  const truncatedContent = useMemo(() => {
    return isCollapsed
      ? children.slice(0, limit) // description limit
      : children;
  }, [isCollapsed, children, limit]);

  // set description in to inner html
  const support = (() => {
    if (!window.DOMParser) return false;
    let parser = new DOMParser();
    try {
      parser.parseFromString("x", "text/html");
    } catch (err) {
      return false;
    }
    return true;
  })();

  const textToHTML = (str) => {
    // check for DOMParser support
    if (support) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(str, "text/html");
      return doc.body.innerHTML;
    }

    // Otherwise, create div and append HTML
    let dom = document.createElement("p");
    dom.innerHTML = str;
    return dom;
  };
  return (
    <>
      <span
        className="superTag text_editor text-dark"
        dangerouslySetInnerHTML={{ __html: textToHTML(truncatedContent) }}
        {...rest}
      />
      {(truncatedContent.length < children?.length || !isCollapsed) && (
        <span onClick={toggleCollapse} className="read_more">
          {isCollapsed ? "Read More" : "Read Less"}
        </span>
      )}
    </>
  );
};

export default CollapseSuperTag;
