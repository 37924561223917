import React from "react";
import { Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { GrMapLocation } from "react-icons/gr";
import {
  Onlineimg,
  ClassRoomImg,
  Regionalimg,
  classRoomImg,
  MobileImg,
} from "assets/images";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { countArray } from "config/utils";
import { FcPhoneAndroid, FcReading, FcTabletAndroid } from "react-icons/fc";
import { normalEncryptData } from "config/config";
import Slider from "react-slick";

const OurPrograms = ({
  tablet = {},
  mobile = {},
  classroom = {},
  regional = [],
  loading = false,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(0);

  var programSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="our_programmes spacing">
        <div className="container">
          {/* loading component */}
          {loading && (
            <>
              <div className="programmes_cardwrap">
                {countArray(4).map((data) => {
                  return (
                    <div className="programme_card" key={data}>
                      <ListCourseSkeleton height="350" />
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {/* main component */}
          {!loading && (
            <>
              <div className="page_title">
                <h3>Our Programmes</h3>
                <p>
                  Flexibility in access to courses, in the form of 3 different
                  models
                </p>
              </div>

              <div className="programmes_cardwrap">
                <Slider {...programSlider}>
                  <div className="programme_card online">
                    <div className="content">
                      <div className="image_wrap">
                        <img src={Onlineimg} alt="programme" loading="lazy" />
                      </div>
                      <div className="head">
                        <h5>Tablet : Online</h5>
                        <span className="sub_title">{tablet.description}</span>
                        <b className="course_price">
                          <BiRupee /> {tablet.price}/- <small>onwards</small>
                        </b>
                        {/* <span className="card_top_icons">
                        <FcTabletAndroid />
                      </span> */}
                      </div>
                      <ul>
                        <li>{tablet.benifitOne}</li>
                        <li>{tablet.benifitTwo}</li>
                        <li>{tablet.benifitThree}</li>
                        <li>{tablet.benifitFour}</li>
                      </ul>
                      {/* <img src={Onlineimg} alt="programme" loading="lazy"/> */}
                      <Link
                        to={`/course-listing/platform/tablet`}
                        className="linkOverlay"
                      ></Link>
                    </div>
                    <div className="learnmore">
                      <Link to={`/course-listing/platform/tablet`}>
                        Learn more
                      </Link>
                    </div>
                  </div>
                  <div className="programme_card regional">
                    <div className="content">
                      <div className="image_wrap">
                        <img src={MobileImg} alt="programme" loading="lazy" />
                      </div>
                      <div className="head">
                        <h5>Mobile : Online</h5>
                        <span className="sub_title">{mobile.description}</span>
                        <b className="course_price">
                          <BiRupee /> {mobile.price}/- <small>onwards</small>
                        </b>
                        {/* <span className="card_top_icons">
                        <FcPhoneAndroid />
                      </span> */}
                      </div>
                      <ul>
                        <li>{mobile.benifitOne}</li>
                        <li>{mobile.benifitTwo}</li>
                        <li>{mobile.benifitThree}</li>
                        <li>{mobile.benifitFour}</li>
                      </ul>
                      {/* <img src={Onlineimg} alt="programme" loading="lazy"/> */}
                      <Link
                        to={`/course-listing/platform/mobile`}
                        className="linkOverlay"
                      ></Link>
                    </div>
                    <div className="learnmore">
                      <Link to={`/course-listing/platform/mobile`}>
                        Learn more
                      </Link>
                    </div>
                  </div>
                  <div className="programme_card classroom">
                    <div className="content">
                      <div className="image_wrap">
                        <img src={ClassRoomImg} alt="" loading="lazy" />
                      </div>
                      <div className="head">
                        <h5>Classroom : Delhi</h5>
                        <span className="sub_title">
                          {classroom.description}
                        </span>
                        <b className="course_price">
                          <BiRupee /> {classroom.price}/- <small>onwards</small>
                        </b>
                        {/* <span className="card_top_icons">
                        <FcReading />
                      </span> */}
                      </div>
                      <ul>
                        <li>{classroom.benifitOne}</li>
                        <li>{classroom.benifitTwo}</li>
                        <li>{classroom.benifitThree}</li>
                        <li>{classroom.benifitFour}</li>
                      </ul>
                      {/* <img src={ClassRoomImg} alt="programme" loading="lazy"/> */}
                      <Link
                        to={`/course-listing/platform/classroom`}
                        className="linkOverlay"
                      ></Link>
                    </div>
                    <div className="learnmore">
                      {/* <Link to={`/course-listing/platform/classroom`}>
                        Learn more
                      </Link> */}
                      <Link to={`/IAS_Institute_Delhi`}>
                        Learn more
                      </Link>
                    </div>
                  </div>
                  <div className="programme_card regional">
                    <div className="content">
                      <div className="image_wrap">
                        <img src={Regionalimg} alt="programme" loading="lazy" />
                      </div>
                      <div className="head">
                        <h5>Regional Classes</h5>
                        <span className="sub_title">
                          {regional[selectedValue]?.data?.description || "N/A"}
                        </span>
                        <b className="course_price">
                          <BiRupee />{" "}
                          {regional[selectedValue]?.data?.price || "N/A"}
                          /- <small>onwards</small>
                        </b>
                        {/* <span className="card_top_icons">
                        <GrMapLocation />
                      </span> */}
                      </div>
                      <ul>
                        <li>
                          {regional[selectedValue]?.data?.benifitOne || "N/A"}
                        </li>
                        <li>
                          {regional[selectedValue]?.data?.benifitTwo || "N/A"}
                        </li>
                        <li>
                          {regional[selectedValue]?.data?.benifitThree || "N/A"}
                        </li>
                        <li>
                          {regional[selectedValue]?.data?.benifitFour || "N/A"}
                        </li>
                      </ul>
                      <Link
                        className={`reginal_center_link ${
                          !regional[selectedValue]?.centerPage && "disabled"
                        }`}
                        to={
                          regional[selectedValue]?.centerPage
                            ? `/${regional[selectedValue]?.region?.replace(
                                /\s/g,
                                "-"
                              )}/center/${normalEncryptData(
                                regional[selectedValue]?.centerPage,
                                "route"
                              )}`
                            : ""
                        }
                      >
                        {/* Go to {"Shubhra Ranjan IAS Study, Bengaluru"} Page */}
                       Go to {regional[selectedValue]?.region} Page
                      </Link>
                      {/* <img src={Regionalimg} alt="programme" loading="lazy"/> */}
                      <Link
                        // to={
                        //   regional[selectedValue]?.centerPage
                        //     ? `/${regional[selectedValue]?.region?.replace(
                        //         /\s/g,
                        //         "-"
                        //       )}/center/${normalEncryptData(
                        //         regional[selectedValue]?.region,
                        //         "route"
                        //       )}`
                        //     : ""
                        // }
                        to={
                          regional[selectedValue]?.centerPage
                            ? "IAS_Institute_"+regional[selectedValue]?.region.replace("Shubhra Ranjan IAS Study, ","")
                            : ""
                        }
                        // to={
                        //     regional[selectedValue]?.centerPage
                        //       ? "/IAS_Institute_Bengaluru"
                        //       : ""
                        //   }
                        // to={
                        //   regional[selectedValue]?.centerPage
                        //     ? "IAS_Institute_"+regional[selectedValue]?.region.replace("Shubhra Ranjan IAS Study, ","")
                        //     : ""
                        // }
                        className={`linkOverlay ${
                          !regional[selectedValue]?.centerPage && "disabled"
                        }`}
                      ></Link>
                    </div>
                    {/* <div className="learnmore">
                      <select
                        value={selectedValue}
                        onChange={({ target }) =>
                          setSelectedValue(target.value)
                        }
                      >
                       
                            
                              <option value={"Shubhra Ranjan IAS Study, Bengaluru"} key={"Shubhra Ranjan IAS Study, Bengaluru"}>
                                {"Shubhra Ranjan IAS Study, Bengaluru"}
                              </option>
                              <option value={"Shubhra Ranjan IAS Study, Pune"} key={"Shubhra Ranjan IAS Study, Pune"}>
                                {"Shubhra Ranjan IAS Study, Pune"}
                              </option>
                           
                          
                      </select>
                    </div> */}
                    <div className="learnmore">
                      <select
                        value={selectedValue}
                        onChange={({ target }) =>
                          setSelectedValue(target.value)
                        }
                      >
                        {regional &&
                          regional.map((data, i) => {
                            return (
                              <option value={i} key={i}>
                                {data.region}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </Slider>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OurPrograms;
