import React from "react";
import { Exam2 } from "assets/images";

const TestNotFound = ({ title = "" }) => {
  return (
    <>
      <div className="listing_card not_found p-0">
        <div className="not_fount_image bg-transparent">
          <img src={Exam2} alt="" loading="lazy" />
        </div>
        <h5>{title} is not available for this course</h5>
      </div>
    </>
  );
};

export default TestNotFound;
