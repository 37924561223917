import React, { useState } from "react";

import { Meetingimg, noMeeting } from "assets/images";

import { useCourse } from "context/context";
import { createGroupMeetingLog, userGroupMeetings } from "services/dashboard";
import {
  charLimit,
  countArray,
  momentDateFormat,
  timeConverter,
  timeFormatWith12Hour,
} from "config/utils";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { errorResponse, failResponse } from "config/config";
import { BiRefresh } from "react-icons/bi";
const GroupMeetings = () => {
  const { course } = useCourse();
  const [meetings, setMeetings] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const navigationHandler = async (data) => {
    try {
      const res = await createGroupMeetingLog({
        courseId: course.courseId,
        groupMettingId: data.MeetingID,
        isStart: 1,
      });
      if (res.code === 200) {
        window.open(data.GMURL);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // get users live lecture
  const getUserGroupMeetings = async () => {
    try {
      setLoading(true);
      const res = await userGroupMeetings({ courseId: course.courseId });

      if (res.code === 200) {
        setMeetings(res.data.groupMeeting || []);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // ----------------------- getting live lecture data -------------------------//
  React.useLayoutEffect(() => {
    if (course?.courseId) {
      getUserGroupMeetings();
    }
    // eslint-disable-next-line
  }, [course]);

  return (
    <>
      <div className="group_meeting">
        <div className="refresh_button_wrap">
          <h4>Group Meeting</h4>
          <button className="dashboard_button" onClick={getUserGroupMeetings}>
            <BiRefresh fontSize={"19"} className="mx-1" />
            Refresh
          </button>
        </div>
        {/* <div className="meeting_content group_meeting">
          <ul>
            {!loading &&
              meetings?.length > 0 &&
              meetings.map(
                ({
                  MeetingID,
                  MeetingTitle,
                  scheduleDate,
                  StartTime,
                  EndTime,
                  GMURL,
                  courseName,
                }) => {
                  return (
                    <li key={MeetingID} className="px-2 my-2">
                      <img src={Meetingimg} alt={MeetingTitle} loading="lazy" />
                      <h6 className="w-100">{MeetingTitle}</h6>
                      <div className="date">
                        <span>Course</span>

                        <b>{`${charLimit(courseName, 25) || "N/A"}`}</b>
                      </div>

                      <div className="date">
                        <span>Date</span>
                        <b>{momentDateFormat(scheduleDate)}</b>
                      </div>
                      <div className="date">
                        <span>Time</span>
                        <b>
                          {timeFormatWith12Hour(StartTime)} -&nbsp;
                          {timeFormatWith12Hour(EndTime)}
                        </b>
                      </div>
                      <button
                        onClick={() => window.open(GMURL)}
                        className="dashboard_button"
                        disabled={
                          new Date() < timeConverter(StartTime, scheduleDate)
                        }
                      >
                        Join
                      </button>
                    </li>
                  );
                }
              )}

            {loading &&
              countArray(4).map((data) => {
                return (
                  <li key={data} className="p-0 my-2">
                    <ListCourseSkeleton height={80} />
                  </li>
                );
              })}

            {!loading && meetings?.length <= 0 && (
              <LectureNoteFound title="Meetings" image={noMeeting} />
            )}
          </ul>
        </div> */}

        <div className="group_meeting_listing">
          <ul>
            {/* main component */}
            {!loading &&
              meetings?.length > 0 &&
              meetings.map(
                ({
                  MeetingID,
                  MeetingTitle,
                  scheduleDate,
                  StartTime,
                  EndTime,
                  GMURL,
                  courseName,
                }) => {
                  return (
                    <li key={MeetingID}>
                      <div className="top_content">
                        <img
                          src={Meetingimg}
                          alt={MeetingTitle}
                          loading="lazy"
                        />
                        <h6 className="w-100">{MeetingTitle}</h6>
                        <button
                          onClick={() =>
                            navigationHandler({ MeetingID, GMURL })
                          }
                          className="dashboard_button"
                          disabled={
                            new Date() < timeConverter(StartTime, scheduleDate)
                          }
                        >
                          Join
                        </button>
                      </div>
                      <div className="bottom_content">
                        <div className="_course">
                          <strong>Course</strong>
                          <span>{`${charLimit(courseName, 25) || "N/A"}`}</span>
                        </div>

                        <div className="date">
                          <strong>Date</strong>
                          <span>{momentDateFormat(scheduleDate)}</span>
                        </div>
                        <div className="_time">
                          <strong>Time</strong>
                          <span>
                            {timeFormatWith12Hour(StartTime)} -&nbsp;
                            {timeFormatWith12Hour(EndTime)}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}

            {/* loading component */}
            {loading &&
              countArray(4).map((data) => {
                return (
                  <li key={data} className="p-0 my-2">
                    <ListCourseSkeleton height={80} />
                  </li>
                );
              })}

            {/* Not Found Component */}
            {!loading && meetings?.length <= 0 && (
              <LectureNoteFound title="Meetings" image={noMeeting} />
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default GroupMeetings;
