import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const getCoupons = async (data) => {
  return decryptData(
    await axios.get(
      `/coupons?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};
