import React from "react";

const Review = ({
  allQuestions = [],
  questionSelection,
  selectedAnswers,
  currentPosition,
}) => {
  // generate class for questions box
  const classGenerator = (data, ind) => {
    let classData = "";
    if (ind + 1 === currentPosition) {
      classData = "current ";
    }

    if (data?.qoption === "SKIP") {
      return classData + "skip";
    }

    if (data?.qoption === "Review") {
      return classData + "review";
    }

    if (data?.qoption === "" && data?.qanswer) {
      return classData + "attempted";
    }
    return classData;
  };

  return (
    <>
      <div className="question_attempt">
        <ul className="legend">
          <li>
            <span className="Attempted"></span> Attempted
          </li>
          <li>
            <span className="Review"></span> Review
          </li>
          <li>
            <span className="Skip"></span> Skip
          </li>
        </ul>

        <div className="attempt_status">
          <b>Questions</b>
          <ul>
            {allQuestions &&
              allQuestions.map((data, i) => {
                return (
                  <li
                    key={i}
                    role="button"
                    onClick={() => questionSelection(i)}
                  >
                    <span className={classGenerator(selectedAnswers[i], i)}>
                      {++i}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Review;
