import React from "react";
import { Placeholder } from "react-bootstrap";

const LiveLectureDetailSkeleton = () => {
  return (
    <>
      <div className="course_video mw-100">
        <Placeholder as="div" animation="wave" aria-hidden="true">
          <Placeholder
            xs={12}
            style={{ height: `70vh`, borderRadius: "10px" }}
          />
        </Placeholder>
      </div>
    </>
  );
};

export default LiveLectureDetailSkeleton;
