import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// __________ get quiz dates __________
export const getQuizDates = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/dailyquiz/date?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ get question according to date __________
export const getQuizQuestions = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/dailyquiz?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};
