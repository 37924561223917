import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
// import { Clarityimg } from "assets/icons";
import "./LearnwithToppers.scss";

const LearnWithToppers = ({ props }) => {
  return (
    <div className="learnwith_toppers spacing">
      <div className="container">
        <div className="top_educators">
          <ul>
            <li>
              <img src={props.image} alt="" loading="lazy" />
            </li>
            <li>
              <h4>{props.title}</h4>
              <p>{props.description}</p>
            </li>
            <li>
              <div className="button_wrap fill padding">
                <Link to={props.buttonLink} className="button">
                  {props.buttonText}
                  <span className="icon">
                    <BsArrowRight />
                  </span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LearnWithToppers;
