import { errorResponse, failResponse } from "config/config";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getExamTypes, getMasterCourseCategories } from "services/master";

const MobileExamMenu = ({ menuClose }) => {
  const [allExamTypes, setAllExamTypes] = useState([]);

  React.useLayoutEffect(() => {
    // get course exam type
    const getCourseExamType = async () => {
      try {
        const res = await getExamTypes();
        if (res.code === 200) {
          setAllExamTypes(res.data.examType.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    // calls all the master functions
    getCourseExamType();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ul onClick={menuClose}>
        {allExamTypes &&
          allExamTypes.map((data, index) => {
            return (
              <li key={index}>
                <Link to={`/course-listing/course/${data.title}/${data.id}`}>
                  {data.title}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default MobileExamMenu;
