import React from "react";
import { Placeholder } from "react-bootstrap";

const ListCourseSkeleton = ({ height = "100%" }) => {
  return (
    <>
      <Placeholder
        as="div"
        animation="wave"
        aria-hidden="true"
        className="w-100"
      >
        <Placeholder
          xs={12}
          style={{
            height: `${height === "100%" ? "100%" : `${height}px`}`,
            borderRadius: "5px",
          }}
          className="w-100"
        />
      </Placeholder>
    </>
  );
};

export default ListCourseSkeleton;
