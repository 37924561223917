import React, { useState } from "react";
import { BsPlayCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { countArray, momentDateFormat } from "config/utils";
import { notWatchedLecture } from "services/lecture";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import { getSignedUrl } from "services/master";

const NotWatched = () => {
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);

  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const [nextId, setNextId] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  // get course lectures
  const getLecturesList = async (type = "") => {
    try {
      setLoading(true);
      const data = {};

      if (type === "next") {
        if (listLectures[0]?.customId) {
          Object.assign(data, {
            nextLecture: listLectures[listLectures.length - 1]?.customId,
          });
        } else {
          Object.assign(data, {
            nextLecture: nextId,
          });
        }
      }

      if (type === "prev") {
        if (listLectures[0]?.id) {
          Object.assign(data, {
            previousLecture: listLectures[0]?.id,
          });
        } else {
          Object.assign(data, {
            previousLecture: nextId,
            currentLectureAvailable: false,
          });
        }
      }

      const res = await notWatchedLecture({});

      if (res.code === 200) {
        setListLectures(res.data.lectures.rows || []);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // next handler
  const nextHandler = async () => {
    getLecturesList("next");
  };

  // previous handler
  const prevHandler = async () => {
    getLecturesList("prev");
  };

  const notesDownloadHandler = async (notes) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: notes }));
    setSubmitLoading(false);
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useLayoutEffect(() => {
    getLecturesList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="daily_lecture">
        <div className="meeting_content">
          <div className="d-flex py-2 ">
            <button
              className="dashboard_button has_arrow prev"
              disabled={!isPrev}
              onClick={prevHandler}
            >
              <FaChevronLeft /> Prev
            </button>

            <button
              className="dashboard_button has_arrow next"
              disabled={!isNext}
              onClick={nextHandler}
            >
              Next <FaChevronRight />
            </button>
          </div>
          <ul>
            {/* ************ Loading Component *********** */}
            {loading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <li key={data}>
                      <ListCourseSkeleton height="90" />
                    </li>
                  );
                })}
              </>
            )}
            {/* iterate lectures */}
            {!loading && listLectures.length > 0 ? (
              listLectures.map(
                ({
                  LectureID,
                  LectureTitle,
                  Duration,
                  teacherName,
                  IsYoutube,
                  vdocipherDetail,
                  ClassNotes,
                  displaydays,
                  startDate,
                  endDate,
                }) => {
                  return (
                    <li key={LectureID}>
                      <Link
                        to={`/dashboard/lecture/detail/${normalEncryptData(
                          LectureID,
                          "route"
                        )}`}
                        className="image"
                      >
                        <img
                          src={IsYoutube ? IsYoutube : vdocipherDetail?.poster}
                          alt=""
                        />
                        <BsPlayCircleFill />
                      </Link>
                      <h6>
                        <Link
                          to={`/dashboard/lecture/detail/${normalEncryptData(
                            LectureID,
                            "route"
                          )}`}
                        >
                          {LectureTitle || "N/A"}
                        </Link>
                      </h6>
                      <div className="date">
                        <span>Start Date</span>
                        <b>{momentDateFormat(startDate)}</b>
                      </div>
                      <div className="date red">
                        <span>End Date</span>

                        <b>{momentDateFormat(endDate)}</b>
                      </div>
                      <div className="date">
                        <span>Educator</span>
                        <b>{teacherName || "N/A"}</b>
                      </div>
                      <div className="date">
                        <span>Duration</span>
                        <b>{Duration} mins</b>
                      </div>
                      <div className="date">
                        <span>Class Notes</span>
                        <button
                          className="button"
                          onClick={() => notesDownloadHandler(ClassNotes)}
                          disabled={!ClassNotes || submitLoading}
                        >
                          Download
                        </button>
                      </div>
                    </li>
                  );
                }
              )
            ) : (
              <>
                <LectureNoteFound title="Lectures" />
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NotWatched;
