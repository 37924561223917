import { errorResponse, failResponse } from "config/config";
import { setLocalConfig } from "config/utils";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { FiRefreshCcw } from "react-icons/fi";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import {
  getCourseVerticals,
  getExamTypes,
  getMasterCourseCategories,
} from "services/master";

const CourseListingFilter = ({
  selectedCategory,
  setSelectedCategory,
  selectedExamType = [],
  setSelectedExamType,
  selectedVertical = [],
  setSelectedVertical,
  selectedCourseType = [],
  setSelectedCourseType,
  courseMenu,
  setCourseMenu,
  resetHandler,
}) => {
  const [courseFilter, setCourseFilter] = useState(false);
  const { type, categoryName, categoryId } = useParams();
  const navigate = useNavigate();

  const [allCategories, setAllCategories] = useState([]);
  const [allExamTypes, setAllExamTypes] = useState([]);
  const [allVerticals, setAllVerticals] = useState([]);
  const allCourseTypes = [
    { value: 1, label: "Website", slug: "website" },
    { value: 2, label: "Tablet", slug: "tablet" },
    { value: 3, label: "Mobile", slug: "mobile" },
    { value: 4, label: "Classroom", slug: "classroom" },
  ];

  var CourseCardTitle = {
    dots: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    speed: 900,
    autoplaySpeed: 8000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // --------------- getting all required master data with courses ----------------//
  React.useLayoutEffect(() => {
    // get course category
    const getCourseCategory = async () => {
      try {
        const res = await getMasterCourseCategories();

        if (res.code === 200) {
          setAllCategories(res.data.courseCategories || []);
          if (type === "course" && categoryId) {
            setSelectedCategory(
              res.data.courseCategories?.find(
                (data) => data.id === +categoryId
              ) || {}
            );
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // get course exam type
    const getCourseExamType = async () => {
      try {
        const res = await getExamTypes();
        if (res.code === 200) {
          setAllExamTypes(
            res.data.examType.rows?.map((data) => {
              return { label: data.title, value: data.id };
            }) || []
          );
          if (type === "exam" && categoryId) {
            setCourseFilter(true);
            setSelectedExamType(
              res.data.examType?.rows
                ?.filter((data) => data.id === +categoryId)
                .map((data) => {
                  return { label: data.title, value: data.id };
                }) || []
            );
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // get course verticals
    const getAllCourseVerticals = async () => {
      try {
        const res = await getCourseVerticals();
        if (res.code === 200) {
          setAllVerticals(
            res.data.courseVerticles.rows?.map((data) => {
              return { label: data.name, value: data.id };
            }) || []
          );
          if (type === "verticle") {
            setCourseFilter(true);
            setSelectedVertical(
              res.data.courseVerticles?.rows
                ?.filter((data) => data.id === 2)
                .map((data) => {
                  return { label: data.name, value: data.id };
                }) || []
            );
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (type === "platform" && categoryName) {
      setCourseFilter(true);
      const filterCourseType = allCourseTypes.filter(
        (data) => data.slug === categoryName
      );
      setSelectedCourseType(filterCourseType);
    }

    // calls all the master functions
    getCourseCategory();
    getCourseExamType();
    getAllCourseVerticals();
    // eslint-disable-next-line
  }, [type, categoryId,categoryName]);

  return (
    <>
      <div className="listing_header">
        <div className="courseTitle">
          <Nav variant="pills" className="">
            <Slider {...CourseCardTitle}>
              <Nav.Item>
                <Nav.Link
                  className={`${selectedCategory.id === "" && "active"}`}
                  onClick={() => {
                    setSelectedCategory({ id: "", name: "" });
                    setLocalConfig("category", { id: "", name: "" });
                    setLocalConfig("page", 1);
                    navigate("/course-listing");
                  }}
                >
                  All Courses
                </Nav.Link>
              </Nav.Item>
              {allCategories &&
                allCategories.map(({ name, id }) => {
                  return (
                    <Nav.Item key={id}>
                      <Nav.Link
                        className={`${id === selectedCategory.id && "active"}`}
                        onClick={() => {
                          setSelectedCategory({
                            id,
                            name,
                          });
                          setLocalConfig("category", { id, name });
                          setLocalConfig("page", 1);
                        }}
                      >
                        {name}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
            </Slider>
          </Nav>
        </div>

        <div className="filter_button_wrap">
          <button
            className="Subjects_button button"
            onClick={() => {
              setCourseMenu(!courseMenu);
            }}
          >
            COURSES BY SUB CATEGORY
          </button>
          <div className="d-flex">
            <div
              className="filter_button"
              onClick={() => setCourseFilter(!courseFilter)}
            >
              <CiFilter /> <span>Filter</span>
            </div>
            <div className="remove_filter" onClick={resetHandler} role="button">
              <FiRefreshCcw />
            </div>
          </div>
        </div>
      </div>

      {/* course filter  */}
      {courseFilter && (
        <>
          <div className="filter_section">
            <div className="multiselect_wrap">
              <label>Exam type</label>
              <MultiSelect
                options={allExamTypes}
                value={selectedExamType}
                onChange={setSelectedExamType}
                text="text"
                labelledBy={"Exam Type"}
              />
            </div>
            <div className="multiselect_wrap">
              <label>Course Verticals</label>
              <MultiSelect
                options={allVerticals}
                value={selectedVertical}
                onChange={setSelectedVertical}
                text="text"
                labelledBy={"Course Verticals"}
              />
            </div>
            <div className="multiselect_wrap">
              <label>Course Type</label>
              <MultiSelect
                options={allCourseTypes}
                value={selectedCourseType}
                onChange={setSelectedCourseType}
                text="text"
                labelledBy={"Course Type"}
              />
            </div>

            {/* <div className="multiselect_wrap">
										<MultiSelect options={options} value={selected} onChange={setSelected} labelledBy="Select" />
									</div> */}
          </div>
        </>
      )}

      <div
        className="overlay"
        onClick={() => {
          setCourseMenu(false);
        }}
      ></div>
    </>
  );
};

export default CourseListingFilter;
