import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// __________ paytm payment api  __________
export const paytmChecksum = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/icici-checksum`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

export const svICICchecksum = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/sv-icici-checksum`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};
export const svPaytmchecksum = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/sv-paytm-checksum`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};