import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import React, { useState } from "react";
import { FaMapMarked, FaMobileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getMasterCenters } from "services/master";

const OurLocation = ({ description }) => {
  const [allCenters, setAllCenters] = useState([]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  var myStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    styles: myStyles,
    zoom: 15,

  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
  });

  const [, setMap] = React.useState(null);
  const [index, setIndex] = React.useState(0);

  const onMapLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: +allCenters[index]?.latitude || 28.535517,
      lng: +allCenters[index]?.longitude || 77.391029,
    });
    map.fitBounds(bounds);

    setMap(map);
    // eslint-disable-next-line
  }, []);

  const onMarkerLoad = (marker) => {};

  // --------------- getting all required masters data ----------------//
  React.useLayoutEffect(() => {
    const getCentersData = async () => {
      try {
        const res = await getMasterCenters();
        if (res.code === 200) {
          setAllCenters(res.data.centerPage || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    getCentersData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="our_location spacing">
        <div className="container">
          <div className="page_title">
            <h3>Our Locations</h3>
            <p>{description}</p>
          </div>

          <div className="our_location_inn">
            <div className="content">
              <h4>{allCenters[index]?.center?.CenterName}</h4>
              <b>{allCenters[index]?.center?.City}</b>
              <ul>
                <li>
                  <span className="icon">
                    <FaMapMarked />
                  </span>
                  <p>{allCenters[index]?.center?.Address}</p>
                </li>
                <li>
                  <span className="icon">
                    <FaMobileAlt />
                  </span>
                  <a href={`tel:${allCenters[index]?.center?.Phone1}`}>
                    {allCenters[index]?.center?.Phone1}
                  </a>
                </li>
              </ul>
              <Link
                to={`/IAS_Institute_`+ allCenters[index]?.center?.City.replace(",","")}
                className="button my-2"
              >
                Go to {allCenters[index]?.center?.City} Page
              </Link>
            </div>
            <div className="map">
              <div className="center_selector">
                <select
                  className="input dropdown_select"
                  value={index}
                  onChange={({ target }) => setIndex(target.value)}
                >
                  {allCenters &&
                    allCenters.map(({ center, id }, i) => {
                      return (
                        <option key={id} value={i}>
                          {`${center?.CenterName}` || "N/A"}
                        </option>
                      );
                    })}
                </select>
              </div>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: +allCenters[index]?.latitude || 28.535517,
                    lng: +allCenters[index]?.longitude || 77.391029,
                  }}
                  zoom={mapOptions.zoom}
                  onLoad={(map) => {
                    map.setOptions({styles: myStyles});
                  }}
                >
                  {/* Child components, such as markers, info windows, etc. */}
                  <MarkerF
                    onLoad={onMarkerLoad}
                    position={{
                      lat: +allCenters[index]?.latitude || 28.535517,
                      lng: +allCenters[index]?.longitude || 77.391029,
                    }}
                  />

                  {/* <div className={"circle bg-danger"}>
                    <span className="circleText">SVR</span>
                  </div> */}
                </GoogleMap>
              ) : (
                <CourseSkeleton height={370} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurLocation;
