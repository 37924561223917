import { encryptData, errorResponse, failResponse } from "config/config";
import { dateFormat, getBrowserName } from "config/utils";
import { useCourse } from "context/context";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { getReUploadAnswerSheet, reuploadMainsTest } from "services/dashboard";
import "../course-report/CourseReport.scss";
import "./ReUploadAnswer.scss";
import useButtonLoader from "hooks/useButtonLoader";
import CommonModal from "components/modal-box/CommonModal";
import toast from "react-hot-toast";
import useCookie from "hooks/useCookie";
import Pagination from "react-js-pagination";
import { FaCommentAlt, FaDownload, FaUpload } from "react-icons/fa";
import FileUploader from "components/FileUploader/FileUploader";
import PercentageLoader from "Loader/PercentageLoader";
import { getSignedUrl } from "services/master";

const ReUploadAnswerSheet = () => {
  const fileTypes = ["PDF"];

  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const [answerSheet, setAnswerSheet] = useState("");

  const [selectedTestId, setSelectedTestId] = useState("");
  const [uploadPaperModal, setUploadPaperModal] = useState(false);

  const [remarkModal, setRemarkModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");

  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [fileUploadFlag, setFileUploadFlag] = useState(false);
  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Upload");

  const [fileLoading, setFileLoading] = useState(false);

  const { cookies } = useCookie();

  const getReuploadSheetList = async (courseId = course.courseId) => {
    try {
      setLoading(true);

      const finalData = {
        course_id: courseId,
        page: activePage,
      };

      const res = await getReUploadAnswerSheet(finalData);

      if (res.code === 200) {
        const { list, count } = res.data;
        setRecords(list || []);
        setTotal(count || 0);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // upload answer handler
  const uploadAnswerHandler = (id, status) => {
    if (status !== "Requested") {
      setSelectedTestId(id);
      setUploadPaperModal(true);
    } else {
      toast.error("You can't upload file again");
    }
  };

  // upload answer sheet function
  const uploadSheetHandler = async () => {
    if (!answerSheet) toast.error("Please select answer sheet");
    else {
      try {
        setSubmitLoading(true);
        setFileUploadFlag(true);
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setUploadPercentage(percent);
          },
          headers: {
            Authorization: "Bearer " + cookies,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("UploadedAnswerSheet", answerSheet);
        formData.append(
          "reqData",
          encryptData({
            subjectivetsetresultid: selectedTestId,
            platformType: getBrowserName(),
            access_token: cookies,
          })
        );

        const res = await reuploadMainsTest(formData, options);

        if (res.code === 200) {
          setSubmitLoading(false);
          setUploadPaperModal(false);
          setFileUploadFlag(false);
          getReuploadSheetList();
          toast.success(res.message);
        } else {
          failResponse(res);
          setSubmitLoading(false);
          setFileUploadFlag(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
        setFileUploadFlag(false);
      }
    }
  };

  const fileDownloadHandler = async (note) => {
    setFileLoading(true);
    window.open(await getSignedUrl({ pdfUrl: note }));
    setFileLoading(false);
  };

  React.useEffect(() => {
    if (course.courseId) {
      getReuploadSheetList();
    }
    // eslint-disable-next-line
  }, [course, activePage]);

  return (
    <>
      <section className="courser_report spacing upload_answer_sheet">
        <div className="container">
          <div className="_box">
            <h4>Re upload answer sheets</h4>
            <div className="table_wrap">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Test Title</th>
                    <th>Course Title</th>
                    <th>Date</th>
                    <th>Answer Sheet</th>
                    <th>Remark</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* loading */}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Loading ...
                      </td>
                    </tr>
                  )}

                  {/* main data row */}
                  {!loading &&
                    records?.length > 0 &&
                    records.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}.</td>
                          <td>{data.test?.TestTitle}</td>
                          <td>{data.course?.CourseTitle}</td>
                          <td>{dateFormat(data.AssignOn)}</td>
                          <td>
                            <button
                              className="dashboard_button"
                              onClick={() =>
                                fileDownloadHandler(data.UploadedAnswerSheet)
                              }
                              disabled={fileLoading}
                            >
                              <FaDownload /> Download
                            </button>
                          </td>
                          <td>
                            <button
                              className="dashboard_button"
                              onClick={() => {
                                setSelectedComment(data.Coment);
                                setRemarkModal(true);
                              }}
                            >
                              <FaCommentAlt /> Remark
                            </button>
                          </td>
                          <td>
                            <button
                              className="dashboard_button"
                              disabled={data.EvaluationStatus !== "Requested"}
                              onClick={() => {
                                uploadAnswerHandler(
                                  data.subjectivetsetresultid
                                );
                              }}
                            >
                              <FaUpload /> Upload
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {/* not found row */}
                  {!loading && records?.length <= 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No records available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* pagination */}
              {total > 10 ? (
                <div className="pagination_wrap">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    activeLinkClass={"active"}
                    onChange={(page) => setActivePage(page)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      {/* upload paper modal */}
      <CommonModal
        show={uploadPaperModal}
        className={"modal-lg reupload_loader"}
        handleClose={() => {
          if (!fileUploadFlag) {
            setAnswerSheet("");
            setUploadPaperModal(false);
          }
        }}
        title={"Upload Answer Sheet"}
      >
        <div className="testPage_upload_paper">
          <FileUploader
            onChange={(file) => setAnswerSheet(file)}
            file={answerSheet}
            types={fileTypes}
            classes={`px-3 h-50 test_upload file_uploader ${
              fileUploadFlag && "disabled"
            }`}
            message={"File selected,click on upload to save it"}
          />
          {fileUploadFlag && <PercentageLoader percentage={uploadPercentage} />}
          <div className="upload_paper__footer text-center my-3">
            <button
              className="button"
              onClick={uploadSheetHandler}
              ref={submitButtonRef}
            >
              Upload
            </button>
          </div>
        </div>
      </CommonModal>

      {/* remark modal */}
      <CommonModal
        show={remarkModal}
        handleClose={() => setRemarkModal(false)}
        size="lg"
        title={"Remark"}
      >
        <div className="terms_condition">
          <div className="terms_condition_content">{selectedComment}</div>
        </div>
      </CommonModal>
    </>
  );
};

export default ReUploadAnswerSheet;
