import React from "react";
import { Enquiry } from "assets/icons";
import { EnquiryImg } from "assets/icons";


const EnquiryForm = () => {
  const handleButtonClickold = () => {
    if (window.NpfWidgetsInit) {
      // Replace "widgetId" with the actual ID of your NoPaperForms widget
      window.NpfWidgetsInit.showPopup(
        "a4c5b0527c60b383a858b6f1c4e8cb68",
        "widgets.in8.nopaperforms.com"
      );
      // window.NpfWidgetsInit.npfElement("a4c5b0527c60b383a858b6f1c4e8cb68");
      // window.NpfWidgetsInit.crElem();
      // window.NpfWidgetsInit.crStyle();
    }

  };
  const handleButtonClick = () => {
    // Open WhatsApp link
    // window.location.href = "https://api.whatsapp.com/send/?phone=919810212719&text&app_absent=0";
    window.open("https://api.whatsapp.com/send/?phone=919810212719&text&app_absent=0", '_blank');

  };

  return (
    // <>
    //   <button
    //     type="button"
    //     className="npfWidgetButton npfWidget-a4c5b0527c60b383a858b6f1c4e8cb68 enquiry_btn"
    //     onClick={handleButtonClickold}
    //   >
    //     <img src={Enquiry} alt="enquiry" loading="lazy" />
    //   </button>
    // </>
    <>
     <button
        type="button"
        className="npfWidgetButton npfWidget-a4c5b0527c60b383a858b6f1c4e8cb68 enquiry_btn1"
        onClick={handleButtonClickold}
      >
        <img src={EnquiryImg} alt="enquiry" loading="lazy" />
      </button>


      <button
        type="button"
        className="npfWidgetButton npfWidget-a4c5b0527c60b383a858b6f1c4e8cb68 enquiry_btn"
        onClick={handleButtonClick}
        // style={{
        //   position: "fixed",
        //   bottom: "20px", // Adjust bottom position as needed
        //   right: "20px", // Adjust right position as needed
        //   zIndex: "999", // Ensure the button appears above other elements
        // }}
      >
      {/* <img width={80} className="logo" src="whatsAppImage.png" alt="logo"loading="lazy"style={{marginRight:"30px",marginBottom:"-40px"}}  /> */}

         <img src={Enquiry} alt="enquiry" loading="lazy" /> 
      </button>
    </>
  );
};

export default EnquiryForm;
