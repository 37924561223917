import {
  decryptData,
  encryptData,
  errorResponse,
  failResponse,
} from "config/config";
import { masterInstance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const getMasterSubjects = async (data) => {
  return decryptData(
    await axios.get(
      `/subject-master?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const getMasterCourseCategories = async (data) => {
  return decryptData(
    await axios.get(
      `/course-categories?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const getMasterCourseSubCategories = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/course-subcategories?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

// get exam types
export const getExamTypes = async (data) => {
  return decryptData(
    await axios.get(
      `/exam-type?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

// get course verticals
export const getCourseVerticals = async (data) => {
  return decryptData(
    await axios.get(
      `/course-verticles?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
// get test types
export const getMasterTestTypes = async (data) => {
  return decryptData(
    await axios.get(
      `/test-types?reqData=${encryptData({
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get centers listing
export const getCenters = async (data) => {
  return decryptData(
    await axios.get(
      `/centers?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get centers listing for center based landing page
export const getMasterCenters = async (data) => {
  return decryptData(
    await axios.get(
      `/centerpage?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCentersListforTokens = async (data) => {
  return decryptData(
    await axios.get(
      `/center-page-list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get signed url for 5 mint validation
export const getSignedUrl = async (data) => {
  try {
    const res = await decryptData(
      await axios.get(
        `/pdf/signed/url?reqData=${encryptData({
          ...data,
          access_token: isLoggedIn(),
        })}`
      )
    );
    if (res.code === 200) {
      return res.data.psfUrl;
    } else {
      failResponse(res);
      return "";
    }
  } catch (err) {
    errorResponse(err);
  }
};
export const getAllStates = async (data) => {
  return decryptData(
    await axios.get(
      `/get-state-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getdetailsbyPincode = async (data) => {
  return decryptData(
    await axios.get(
      `/get-details-by-pincode?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};