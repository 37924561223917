import React from "react";
import "./VideoPlayModal.scss";
import { Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

const VideoPlayModal = ({ modalShow, modalHide, video, children }) => {
  return (
    <Modal
      show={modalShow}
      onHide={modalHide}
      backdrop="static"
      keyboard={false}
      className="video_modal"
      size="lg"
      centered
    >
      <Modal.Body>
        {children && (
          <>
            <span className="close_modal" onClick={modalHide}>
              <AiFillCloseCircle color="#fff" />
            </span>
            {children}
          </>
        )}

        {!children && (
          <>
            <span className="close_modal" onClick={modalHide}>
              <AiFillCloseCircle />
            </span>
            <video width="100%" height="400" controls autoplay="true">
              <source src={video} type="video/mp4" />
              Your browser does not support the html video tag.
            </video>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default VideoPlayModal;
