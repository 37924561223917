import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import AppliedExceptional from "./exceptionalSection/AppliedExceptional";
import ExceptionalList from "./exceptionalSection/ExceptionalList";

const ExceptionalLectures = () => {
  const [exceptionalType, setExceptionalType] = useState("list");

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="list">
        <div className="dashboard_tab">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link
                eventKey="list"
                className={`${exceptionalType === "list"} && 'active`}
                onClick={() => setExceptionalType("list")}
              >
                List of lectures
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="exceptional"
                className={`${exceptionalType === "exceptional"} && 'active`}
                onClick={() => setExceptionalType("exceptional")}
              >
                Exceptional lecture
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        {/* exceptional lecture list */}
        {exceptionalType === "list" && <ExceptionalList />}

        {/* apply exceptional lecture list */}
        {exceptionalType === "exceptional" && <AppliedExceptional />}
      </Tab.Container>
    </>
  );
};

export default ExceptionalLectures;
