import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./ThankYou.scss";

const ThankYou = () => {
  return (
    <>
      <div className="thanks-container">
        <div>
          <div className="icon">
            <BsFillPatchCheckFill />
          </div>
          <div className="text-center">
            <h1>Thank You !</h1>
            {/* <p>We've send the link to your inbox. Lorem ipsum dolor sit, </p> */}
            <Link to="/dashboard/home" className="button">
              Go To Dashboard
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
