import { errorResponse, failResponse } from "config/config";
import { dateFormat } from "config/utils";
import { useCourse } from "context/context";
import React, { useCallback, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { postalFolder } from "services/postal";

const PostalFolderList = () => {
  const [folderListing, setPostalListing] = useState([]);
  const [loading, setLoading] = useState(false);

  const { course } = useCourse();
  const navigate = useNavigate();

  const postalFolderListing = useCallback(async (courseId) => {
    try {
      setLoading(true);
      const res = await postalFolder({ courseId: courseId });

      if (res.code === 200) {
        setPostalListing(res.data);
        setLoading(false);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!course.isPostal) {
      navigate("/dashboard/home");
      return;
    }
    if (course.courseId) {
      postalFolderListing(course.courseId);
    }
  }, [postalFolderListing, course, navigate]);

  return (
    <>
      <section className="courser_report spacing upload_answer_sheet">
        <div className="container">
          {/* Breadcrumb */}
          <div className="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <Link to="/dashboard/postal-folder-listing">
                    postal course
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="_box">
            <h4>Postal code documents</h4>
            <div className="table_wrap">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th style={{textAlign:"left"}}>Name</th>
                    <th>Type</th>
                    <th>Add on</th>
                    {/* <th>Size</th> */}
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {/* loading */}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Loading ...
                      </td>
                    </tr>
                  )}

                  {/* main data row */}
                  {!loading &&
                    folderListing?.length > 0 &&
                    folderListing.map(({ folderName, createdAt }, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}.</td>
                          <td style={{textAlign:"left"}}>
                            <Link
                              to={`/dashboard/postal-folder-listing/${folderName.replaceAll(
                                "/",
                                "-"
                              )}`}
                            >
                              {folderName.split("/")[1]}
                            </Link>
                          </td>
                          <td>Folder</td>
                          <td>{dateFormat(createdAt)}</td>
                          {/* <td>{size}</td> */}

                          <td>
                            <Link
                              to={`/dashboard/postal-folder-listing/${folderName.replaceAll(
                                "/",
                                "-"
                              )}`}
                              className="dashboard_button"
                            >
                              <FaEye /> View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}

                  {/* not found row */}
                  {!loading && folderListing?.length <= 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No records available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostalFolderList;
