import React, { useState, useEffect } from "react";
import { GetUPSCcontent } from "services/freeresources/freeresources";
import { errorResponse, failResponse } from "config/config";
import { FaArrowLeft, FaAngleLeft, FaCaretLeft, FaChevronLeft, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import './Syllabus.scss'; // Import your custom CSS file

const Syllabus = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
    const [content, setContent] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Function to fetch the content
        const fetchUPSCcontent = async () => {
            try {
                const res = await GetUPSCcontent();
                if (res.code === 200) {
                    const { htmlcontent } = res.data.content[2];
                    setContent(htmlcontent);
                } else {
                    failResponse(res);
                }
            } catch (err) {
                errorResponse(err);
            }
        };

        // Function to update isMobile state on window resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        fetchUPSCcontent(); // Fetch content on component mount

        window.addEventListener('resize', handleResize); // Listen for window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up listener on unmount
        };
        
    }, []); // Empty dependency array ensures this runs only once (on mount)

    // Handle back button functionality
    const handleBack = () => {
        navigate('/freeresources/FRPrelims'); // Use navigate to go back to the specified route
    };

    return (
        <div className="syllabus-container">
            {isMobile && (
                <div className="mobile-back-arrow" onClick={handleBack}>
                    <FaChevronLeft style={{ color: '#181822', fontSize: '24px' }} /> {/* Font Awesome Arrow Icon */}
                </div>
            )}
            <div className="subsection-prelims-syllabus-container">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

export default Syllabus;
