import React from "react";
import "./OtherPages.scss";
function TermsCondition() {
	return (
		<div className="terms_condition spacing">
			<div className="container">
				<h1>Terms & Condition</h1>

				<div className="terms_condition_content spacing">
					<p>
						Please be informed that as a user you are using our services and hence, trust us. Therefore, it becomes pertinent that we are able to provide you with the best quality services with minimal effort. We endeavour to ensure that every user experience is smooth and hassle-free. These Terms
						and Condition of Use, guide user behaviour and in the process lay down certain parameters and guidelines, which would be beneficial to both, you and us, the Company.
					</p>
					<p>
						Shubhra Ranjan IAS Study Pvt. Ltd., operating under the name of <b> SHUBHRA RANJAN </b>, is a popular brand that provides guidance to prepare for civil services examinations conducted by UPSC (Union Public Services Commission).
					</p>
					<p>
						These Terms and Conditions of Use (hereinafter <b>Terms of Use </b> ) pertains to the services made available at https://www.Shubhra Ranjan.com (hereinafter "Shubhra Ranjan Website") and its equivalent Shubhra Ranjan Mobile Application (hereinafter "Shubhra Ranjan App") available on
						Google Play, Windows Store or Apple Store. The Shubhra Ranjan App and Shubhra Ranjan Website, on which the services are availed, will be hereinafter collectively referred to as <b> Platform </b>. We request you to read the Terms of Use thoroughly, before using the Platform as it governs
						your use of the Platform and any other applications, products, websites and services owned and managed by the Company.
					</p>
					<p>
						For the purpose of this Terms of Use, wherever the context so requires <b> Your, You, Yourself </b> or <b>User</b> shall mean any natural or legal person who browses the Platform or has agreed to become a subscriber to the services on the Platform, by providing login credentials while
						registering on our website as a Subscribed User (defined in Clause 2.1) using the computer systems.
					</p>
					<p>
						The term <b> We, Us, Our </b> shall mean Shubhra Ranjan IAS Study Pvt. Ltd. the Company.
					</p>
					<p>
						We reserve the right to amend the Terms of Use at any time, which shall be updated on this page, regularly, so that you are always aware of the latest developments in the Platform. in case of any changes or updates, we shall endeavour to notify You of such changes at the earliest.
						Nevertheless, please ensure that You read our Terms of Use and any updated version thereof before proceeding to use the Platform or the Services, as the case maybe. The Terms of Use is subject to be amended without prior notice and it is your duty to be apprised of the same.
					</p>
					<p>
						This Terms of Use applies solely to Our Platform. We state that this Terms of Use is not applicable to other such similar or related Platform and the Terms of Use of other Platforms and websites may vary, accordingly we are not responsible for the Terms of Use of other such Platforms.
					</p>

					<h5>1. Acceptance of Terms</h5>
					<ol>
						<li>
							These Terms of Use set forth a legally binding contract between Us, and You and by using the Platform, You agree to the Terms of Use stated herein. The Terms of Use equally apply, even if You only visit the Website or review any of the service offerings made available by Us and are
							bound by these Terms of Use. If you do not agree to this Terms of Use, please do not continue to access and use our Platform, henceforth.
						</li>
						<li>
							In consideration of your use of the Shubhra Ranjan Website and Shubhra Ranjan App, You agree:
							<ol className="alphaBate">
								<li>To provide true, accurate, current and complete information about yourself complying with Clause 2 of this Terms of Use and as prompted in order to generate the login credentials.</li>
								<li>To the responsibility of ensuring that You meet the required qualiﬁcation while opting for the courses subscribed.</li>
								<li>To maintain and promptly update your login credentials to keep it true, accurate, current and complete.</li>
								<li>And acknowledge that Shubhra Ranjan is not responsible to ensure that You conform to the eligibility criteria.</li>
								<li>To seek the consent of Your parents/legal guardians, in case if you are a minor, before providing any information about Yourself or Your parents and other family members, on the Shubhra Ranjan App</li>
								<li>That We are not responsible or liable for any consequences, that arise as a result of misuse of any kind, by You, in the Shubhra Ranjan App, Shubhra Ranjan Website or any of Our services, such that it causes any harm or injury to a third-party.</li>
								<li>
									That We reserve the right to change and update the content in the Platform to represent the latest evidence and practices, with the changing times, needs and requirements. We reserve the right to constantly review, update and replace the content and features of all the Study
									Materials, including but not limited to Test Series, Videos and Notes, which would be carried out on a yearly basis in the form of ‘Editions’. We do not guarantee that any content or features, of the previous Edition, would be made available on the Platform in the new Edition.
								</li>
								<li>That We reserve all right to engage a different faculty or tutor or change the current faculty or tutor at any given point of time without any prior notification to the User.</li>
							</ol>
						</li>
						<li>
							If You provide any information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, We reserve the right to suspend or terminate your account and refuse any and all
							current or future use of the Platform.
						</li>
						<li>
							This document is an electronic record in terms of the Information Technology Act, 2000 (IT Act) and the Rules as applicable, thereunder, and the provisions pertaining to electronic records in various statutes as amended by the IT Act. This electronic record is generated by a computer
							system and does not require any physical or digital signatures.
						</li>
						<li>
							You may access the Platform and subscribe to the services only for Your personal use. The Platform as a whole, is owned exclusively by Shubhra Ranjan IAS Study Pvt. Ltd. You acknowledge that We are providing You with a revocable, limited, non-exclusive, and non-transferable license
							to use the features of the Platform. You will upon logging into the Shubhra Ranjan Website/mobile application as a regular user be entitled to view Our limited free content as a demo of the offerings. You will get access once You enrol as a Subscriber. You are advised to review this
							free content before taking the decision to proceed to subscribe to the services as a ‘Subscribed User’ (as deﬁned in Clause 2.2).
						</li>
						<li>By registering Your e-mail address and/or phone number with Us, You consent to be contacted by Us via phone calls, SMS notiﬁcations, e-mails and/or any other means that help us reach You, in case of any subscription or service updates.</li>
						<li>You are entirely responsible for maintaining the security and conﬁdentiality of Your account as well as the login credentials to the account once you become a ‘Subscribed User’.</li>
						<li>We will not be liable for any loss that You may incur as a result of a third party using Your account, either with or without Your knowledge. You may be held liable for any losses incurred by Us as a consequence of third party usage of Your account.</li>
						<li>
							Due to the global nature of the Internet, You agree to comply with all applicable local laws, state speciﬁc laws, and regulation rules regarding use of the Platform. You also agree to comply with all applicable laws regarding the transmission of technical data and information, exported
							from India or the country in which You reside.
						</li>
					</ol>

					<h5>2. Eligibility</h5>
					<ol>
						<li>
							Persons who are "competent/capable" of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for Shubhra Ranjan App and be a Registered User (defined hereunder). As a minor if You wish to use the Shubhra Ranjan App or Our Services, such use shall
							be made available to You by Your legal guardian or parents, who has agreed to these Terms. In the event a minor utilizes the Shubhra Ranjan App/Website/Services, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the
							legal guardian or parents.
						</li>
						<li>You are mandatorily required to either be a person of competence, complying with Clause 2.1, as mentioned above.</li>
						<li>
							To register on the Platform for availing the Services (as deﬁned in Clause 3.1) a login account, which is based on a paid subscription, is required. You become a <b> Subscriber/Subscribed User </b> (hereinafter ‘Subscriber’) once the account is created and an appropriate plan is chosen
							with the correct payment being done for the plan.
						</li>
						<li>
							To determine Your eligibility as a Subscriber, You will be required to give details to validate Your eligibility by ﬁlling in all mandatory ﬁelds in the online service subscription enrolment form. Upon completing all ﬁelds in the service subscription form, You will have access to the
							‘Free Content’ or the ‘Basic Plan’ in the capacity of a ‘Registered User’ (hereinafter Registered User) and have the option to purchase a paid subscription of your choice.
						</li>
						<li>
							If You choose to purchase a paid subscription, You will be directed to the purchase page and have the option to select the services and duration for which You wish to become a Subscribed User. Upon receipt of the payment in full the user would become a “Pro User”. After the payment is
							complete, You will receive a conﬁrmation email to the registered email id conﬁrming your plan details and credentials for accessing the Platform.
						</li>
						<li>
							Post receipt of the payment in full, every user shall have to mandatorily submit their one valid identity proof issued by the Government of India i.e., Aadhar Card, Passport, Voter’s ID, MCI card etc, as acceptable by the Us. Upon submission of the valid identity proof, the user shall
							mandatorily conduct a verification process of each Pro user. Only on success completion of the verification process by Us will You attain the status of a Subscribed User.
						</li>
					</ol>

					<h5>3. Services of Shubhra Ranjan</h5>

					<ol>
						<li> To avail the full range of course content offered as part of the Services, You will have to mandatorily be a Subscribed User meeting all the requirements set out in Clauses 2.2 and 2.3.</li>
						<li>
							The Services and amounts payable to access the Services may differ for different Subscribers and will be based on the course combination chosen by You either at the time of enrolling as a Subscribed User or thereafter, based on the courses a Subscribed User adds through his/ her
							account.
						</li>
						<li>
							In these Terms of Use, the term Services (hereinafter referred to as ‘services’) shall mean and include without limitation, the online tutorial videos and content as well as study materials that will be accessible to You as a Subscribed User and will exclude the free demo content that
							is accessible to all Registered Users visiting the Platform. The foregoing meaning and scope of Services may be subject to change and the deﬁnition shall not in any way limit or restrict Our right to remove or add features to the Platform.
						</li>
						<li> As a “Registered User”, You are enrolled to the free plan by default and have the option to purchase a course and become a Subscribed User.</li>
						<li>You agree that any Services provided by Us may be subject to change at Our discretion and We may add, remove or modify offerings to its existing scope of Services at such fees as will be solely determined by Us.</li>
						<li>We may, at Our discretion, revise the amount payable in respect of existing offerings forming part of the Services that may be made available to You.</li>
						<li>You agree that We may at any time and for any reason, terminate Your access to whole or part of the Platform, or restrict or suspend Your access to your Subscribed User account, for any or no reason, with or without prior notice, and without any liability to You.</li>
					</ol>

					<h5>4. Subscriber Account, Password and Security</h5>
					<ol>
						<li>
							If You use the Platform and wish to avail Services by creating a User account, You may be required to submit your phone number and/or email ID and choose a password which will be the only set of credentials basis which You will be granted access to the Platform and its Services. You
							are responsible for maintaining the confidentiality of Your password and other credentials to access the Services. If there is any compromise to Your account, You can change your password using the ‘forgot password’ functionality available on the login page used to access the Platform.
						</li>
					</ol>

					<h5>5. Third Parties </h5>
					<ol>
						<li>
							We may use/integrate another third party’s platform on Our Website or the Shubhra Ranjan App. We may also use third party platforms to provide services to ourselves. In such event, You will be bound by such third party’s terms and conditions, privacy policy, and any other applicable
							policies. You also agree and hereby authorize Shubhra Ranjan to share Your details and personal information with such third parties to the extent necessary for Shubhra Ranjan to deliver the Services to You.
						</li>
					</ol>

					<h5>6. Payment Terms</h5>
					<ol>
						<li>
							Any payments made to Shubhra Ranjan in respect of Services are subject to the payment terms of Shubhra Ranjan as will be notified to You once You initiate the process to become a Subscribed User. The current existing payment schedule available to Subscribed Users are mentioned on the
							following webpage: https://www.Shubhra Ranjan.com/home/pro
						</li>
						<li>The Fee including GST and any delivery charges payable in relation to delivery of electronic versions of document based Study materials, if applicable, will be shown prior to completion of the online payment transaction.</li>
						<li>
							In the unlikely event that, due to a technical error, the amount of the Fee displayed on the Platform is incorrect, Shubhra Ranjan will notify You as soon as it reasonably can. If the correct amount of the Fee is higher than the amount displayed on the Platform, Shubhra Ranjan will
							contact You through your registered e-mail address and/or phone number to notify You of the correct Fee. To avail all Services for the duration of Subscription, You may be required to remit any additional fees such that the correct Fee is paid by you.
						</li>
						<li>
							Shubhra Ranjan hereby notifies You that Shubhra Ranjan uses third party payment gateway service providers to process payment made by You towards the Service subscription. It is hereby clarified that Shubhra Ranjan will not be responsible for any payment failures or errors occurring due
							to technical issues at the bank’s end or issues arising from the third payment gateway and all such issues should be resolved directly between You and the banking or payment gateway partner concerned.
						</li>
						<li>
							The provision of the online tutorials forming part of the Services are contingent upon Shubhra Ranjan having received cleared funds from You in respect of the Fee for the relevant Service subscription. Without prejudice to Shubhra Ranjan’s rights and remedies under these Terms of Use,
							if any sum payable is not paid on or before the due date, Shubhra Ranjan reserves the right, forthwith and at Shubhra Ranjan’s sole discretion, to suspend Your access to the Services and refuse You entry to the course You intended to avail as part of the subscription to the Platform.
						</li>
					</ol>

					<h5>7. Disclaimer as regards Study materials</h5>
					<ol>
						<li>
							The term ‘Study Materials’ (hereinafter <b>Study Materials</b>) as used in these Terms of Use include the videos, test series and any other learning material posted for the speciﬁc topics as well as other electronic materials that may be made available from time to time. At times, soft
							copy document versions of the video lectures may be provided to a Subscribed User and is based on Our sole assessment as regards the need for such material. Where soft copy Study Materials accompany the services, these Study Materials will be made available to You, upon meeting all the
							conditions stipulated in these Terms of Use and becoming a Subscribed User.
						</li>
						<li>We do not make any representation, guarantee or commitment to You that the Study Materials offered either in the demo versions or as part of subscribed services will be error free.</li>
						<li>
							The MCQs provided in the Study Materials is inclusive of all kind of MCQs provided in the Study Material i.e., via test series, notes etc. The content will be constantly updated (addition or removal) in terms of its numbers and the material without any prior notice to You and at a sole
							discretion of Shubhra Ranjan.
						</li>
						<li>We do not claim any guaranteed rank, mark, success/any kind of professional advantage with the use of the Platform.</li>
						<li>
							We reserve the right to change and update the content in the Platform to represent the latest evidence and practices, with the changing times, needs and requirements. We reserve the right to constantly review, update and replace the content and features of all the Study Materials,
							including but not limited to Test Series, Videos and Notes, which would be carried out on a yearly basis in the form of ‘Editions’. We do not guarantee that any content or features, of the previous Edition, would be made available on the Platform in the latest Edition.
						</li>
						<li>We reserve all right to engage a different faculty or tutor or change the current faculty or tutor at any given point of time without any prior notification to the User.</li>
					</ol>

					<h5>8. Online Subscription and Access Terms</h5>
					<ol>
						<li> Except as set out in the description of the subscription model available on the Platform, no additional study materials and/or video tutorials will be provided by Us.</li>
						<li>
							Upon receipt of a conﬁrmation e-mail from Us you will be notiﬁed when You (only if you are a Subscribed User) have access to purchased services and for the length of time such access will be made available to You, subject however to Our absolute right to suspend or terminate access in
							accordance with these Terms of Use.
						</li>
						<li>
							A subscription received is personal to You and You shall not transfer or share your right to access the Study Material or further sell the subscription or allow access to the subscription to any other person for consideration or otherwise. Such acts will be construed as contrary to
							this Terms of Use and in such a situation we reserve the right to suspend or terminate access to Your account, with or without prior notice.
						</li>
					</ol>

					<h5>9. System Requirements</h5>
					<ol>
						<li>
							Please note that it is Your responsibility to check that the computer or device You plan to use to access the subscription is compatible with the minimum speciﬁcation requirement that relates to the subscription You are opting for. You acknowledge and accept that We cannot be held
							responsible for any technical problems (including but not limited to playback of video content) with any systems, computers or devices You encounter following the commencement of the subscription
						</li>
						<li>
							2. Please find below the Minimum System Requirements for using our Platform:
							<ol className="alphaBate">
								<li>Android 5 - Non-rooted - DRM supported devices</li>
								<li> iOS 10 - Non-jailbroken</li>
							</ol>
						</li>
						<li>We recommend the Latest version of Chrome. Other browsers may or may not be supported.</li>
						<li>
							Please find below the Minimum Internet Speed requirement for using our Platform:
							<ol className="alphaBate">
								<li>For Videos = 0.8Mbps</li>
								<li>For other Services = 0.2Mbps</li>
							</ol>
						</li>
						<li>
							Please find below the Recommended Internet Speed for using our Platform
							<ol className="alphaBate">
								<li>For Videos = 0.8Mbps</li>
								<li>For other Services = 2Mbps</li>
							</ol>
						</li>

						<li>
							Please note that the above mentioned has been derived based on User experience and is not conclusive or a guarantee that if the above-mentioned criteria is fulfilled, the Platform would work in the like manner. Other unforeseeable circumstances too must be considered while using the
							services on the Platform.
						</li>
					</ol>

					<h5>10. Modifications to Subscription</h5>
					<ol>
						<li>
							We reserve the right to make modiﬁcations, enhancements or issue clariﬁcations (for example, clariﬁcation of doubt, interactive sessions) to the subscription, including but not limited to changes in Services, number of Services available, pricing and validity of Subscription duration.
						</li>
						<li>When We make such changes, We may or may not notify the User of the same and the User will be bound by such modifications, enhancement, changes and amendments, as the case may be.</li>
						<li>These changes would be called as ‘Editions’ and we have complete discretion to update the same with time, as We deem fit and cannot be challenged by You at any time.</li>
						<li>We do not guarantee that the contents or features of the previous versions/Editions would be made available post the launch of the new edition.</li>
					</ol>

					<h5>11. Technical Support and Access</h5>
					<ol>
						<li>We do not warrant uninterrupted or error-free operation of the subscription.</li>
						<li>We are not obliged to offer You any technical support in relation to your subscription other than the speciﬁc support that has been included as part of the subscription plan You have opted for</li>
						<li>
							You also accept and acknowledge that We cannot be held responsible for any delay or disruptions to your subscription as a result of such suspension or any of the following but not limited to:
							<ol className="alphaBate">
								<li>the operation of the internet and the World Wide Web, including but not limited to viruses</li>
								<li>any firewall restrictions that have been placed on your network or the computer You are using to access the Services</li>
								<li>failures of telecommunications links and equipment; or</li>
								<li>updated browser issues</li>
								<li>DRM protocols preventing playback of media</li>
								<li>Not meeting standards any of the minimum system requirements as defined in clause 9.</li>
							</ol>
						</li>
					</ol>

					<h5>12. Disclaimer of Warranties and Liabilities</h5>
					<ol>
						<li>We shall not be liable for any interference, virus, hacking, or any such consequence, caused by any network or internet service providers and failure of device manufacturers to support DRM protocols.</li>
						<li>Any content posted on a third-party platform, by Us or any authorised third party, shall be subject to the terms and conditions, and policies, of that third-party platform in addition to the terms and conditions set forth herein.</li>
						<li>
							SUBJECT TO APPLICABLE LAWS, IN NO EVENT WILL SHUBHRA RANJAN OR ITS EMPLOYEES’, OR ITS AGENTS’, PARTNERS, AND CONTRACTORS’, AGGREGATE LIABILITY ARISING FROM OR RELATED TO THE AFORESAID SERVICES TO YOU, EXCEED THE PAYMENTS ACTUALLY RECEIVED AND RETAINED BY SHUBHRA RANJAN FROM YOU, FOR
							ANY AND ALL CAUSES OF ACTION BROUGHT BY YOU OR YOUR AGENTS. IN THE EVENT THAT YOU ARE NOT A SUBSCRIBED USER, SHUBHRA RANJAN WILL NOT BE LIABLE TO YOU UNDER ANY CIRCUMSTANCES.
						</li>
					</ol>

					<h5>13. Disclaimer of Services</h5>
					<ol>
						<li>
							You acknowledge and agree that the suggestions and recommendation provided by Us on the Platform, are only suggestions, and You may comply with it at Your choice and option. We offer You various services via the Platform, to help You prepare for civil services examinations conducted by
							UPSC with customised support and assistance.
						</li>
					</ol>

					<h5>14. Data protection</h5>
					<ol>
						<li>We will process the information that We receive from You or otherwise hold about You in accordance with these Terms of Use and the Privacy Policy. You consent to the use by Us of such information in accordance with these Terms of Use and Shubhra Ranjan’s Privacy Policy.</li>
						<li>
							You acknowledge that We may conduct online surveys from time to time. The data collected through these surveys are used to gauge Our services, collect demographic information and other information that We may ﬁnd useful. We may share non-personal, aggregated information with third
							parties. By agreeing to these Terms of Use, You agree to Us using Your information in this manner and in the manner mentioned in our Privacy Policy.
						</li>
					</ol>

					<h5>15. Shubhra Ranjan No Sharing Fair Usage Policy</h5>
					<ol>
						<li> A Shubhra Ranjan account is meant to be used by only one User.</li>
						<li>Sharing the Shubhra Ranjan account credentials for both personal use or commercial purposes will result in account blockage without prior notice or warning.</li>
						<li>As per this Fair Usage policy, each user will have a ﬁxed number of Fair Usage hours which is defined course wise. In case the limit is exceeded, the account will fall under scrutiny and we will immediately investigate the matter further, without Your consent.</li>
						<li>Apart from the Fair Usage Hours, we also track a number of other parameters to identify any form of sharing.</li>
						<li>
							If a violation of this policy is identiﬁed the same would amount to the substantial breach of the said terms of use and the Company would have all the rights to take any legal action as provided under the law of the land ,and also including but not limited to termination/blocking your
							account permanently without any prior notice to you.
						</li>
					</ol>

					<h5>16. Prohibited Conduct</h5>
					<ol>
						<li>
							You agree that You shall not use the services or the Platform in order to host, display, upload, modify, publish, transmit, update, distribute, share, store material. You are bound not to cut, copy, decompile, distribute, modify, recreate, reverse engineer, distribute, disseminate,
							post, publish or create derivative works from, transfer, or sell any information or software obtained from the website. With Our prior permission, limited use may be allowed and this is solely based on Our discretion. For the removal of doubt, it is clariﬁed that unlimited or wholesale
							reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modiﬁcation of data and information within the content of the Platform is not permitted and is considered:
							<ol className="alphaBate">
								<li> in violation of any applicable law or regulation;</li>
								<li>in a manner that will infringe the copyright, trademark, trade secret or other intellectual property or proprietary rights of others or violate the privacy, publicity or other personal rights of others;</li>
								<li>that belongs to another person and to which the user does not have any right to;</li>
								<li>
									that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, threatening, abusive or hateful or racially, ethnically objectionable, disparaging, relating encouraging money laundering or gambling or
									otherwise unlawful in any manner whatsoever;
								</li>
								<li>that harms minors in any way;</li>
								<li>that impersonate another person or entity;</li>
								<li> that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of Shubhra Ranjan’s computer systems or site or Shubhra Ranjan’s Users, customer’s computer systems or site;</li>
								<li>
									threatens the unity, integrity, defence, security or sovereignty of India and any other countries, friendly relations with foreign states or of public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or insulting any
									other nation.
								</li>
							</ol>
						</li>
						<li>If You become aware of misuse of the Service by any person, please contact 9810212719.</li>
						<li>
							You shall not either as a regular browsing user, Registered User, Basic Plan user or as a Subscribed User, use the Platform to either directly or indirectly, either alone or with another third party disassemble, reverse engineer or decompile any part or whole of the Platform in order
							to get access or attempt to get access to the underlying software, source or proprietary technology which enables the Services or other functionalities of the Platform or do anything with an intent to create derivative works or competing platforms that provide same or similar services.
						</li>
						<li>
							You shall not either directly or through other third parties take, post, publish, transmit or otherwise make available any of the Study Material or video tutorials on any other medium. Furthermore, You undertake not to use, display, mirror or frame the Platform or any individual
							element within the Platform, unless this is expressly approved by Us in writing.
						</li>
						<li> You shall not copy or record or make any attempt to copy or record part or whole of the Services or use the Services for further distribution any mode so as to commercially beneﬁt from the Study Material or engage in any form of piracy.</li>
						<li> Any violation of the said clause would amount to substantial breach of the said Terms of Use and the Company shall have all the rights to take any legal action as provided under the law of the land.</li>
					</ol>

					<h5>17. Termination or Suspension of Account for Illegalities or suspected Illegalities</h5>
					<ol>
						<li> If there is a suspicion of untoward or illegal activity, We may suspend your account immediately and if required, debar all future access by You to the Platform.</li>
						<li>We reserve the right to cancel any subscription as We believe has been compromised, or is being used fraudulently, at its own discretion without any ﬁnancial liability to You.</li>
						<li>
							User’s account will be Blocked for the following reasons (including but not limited to):
							<ol className="alphaBate">
								<li>If found suspicious (High/ Suspicious Activity)</li>
								<li>Usage of Rooted/ Jailbroken Devices, Emulators, ChromeCast, Android TV, Amazon Fire, Samsung Dex, Cloned APK's 3)TV/ Smart TV/ Apple TV or any other virtual machines.</li>
								<li>Screen Casting, Screen Recording, Screen Mirroring, Downloading Videos out of Shubhra Ranjan Platform,</li>
								<li>Using a camera, phone or any other device to record any Platform content</li>
								<li>Sharing Shubhra Ranjan Screen Shots or PDF's in Social Media or any other Network</li>
								<li>Sharing Shubhra Ranjan Accounts Credentials with anyone.</li>
								<li>Usage of Multiple Devices (Other than upto to Two Devices for Personal Use)</li>
								<li>Abusing Shubhra Ranjan and Shubhra Ranjan Faculties.</li>
								<li>Try to sell Shubhra Ranjan Accounts and Contents on Shubhra Ranjan.</li>
							</ol>
						</li>
					</ol>

					<h5>18. Preservation/Disclosure</h5>
					<ol>
						<li>
							You acknowledge, consent and agree that Shubhra Ranjan may access, preserve and disclose Your account information if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to:
							<ol className="alphaBate">
								<li>comply with legal process nationally or internationally;</li>
								<li>enforce this Agreement;</li>
								<li>respond to claims that any content violates the rights of third parties;</li>
								<li>protect the rights, property or personal safety of Shubhra Ranjan, its subscribers and the public; or</li>
								<li>pursuant to the terms of the Privacy Policy, reach You for marketing or promotional purposes through any channel.</li>
							</ol>
						</li>
					</ol>

					<h5>19. Security Components</h5>
					<ol>
						<li>
							You understand that Shubhra Ranjan and software embodied within Shubhra Ranjan and the Services you access may include security components that permit digital materials including the Study Materials to be protected, and that use of these Study Materials is subject to usage rules set by
							Shubhra Ranjan. You shall not attempt to override, disable, circumvent or otherwise interfere with any such security components, encryptions and usage rules embedded into Website or the specific Services accessible through Your Service account.
						</li>
					</ol>

					<h5>20. Proprietary Rights</h5>
					<ol>
						<li>
							All materials on Website, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, artwork, software, notes (soft copies) and any and all other elements (collectively referred to as Material) are protected by
							copyrights, trademarks and/or other intellectual property rights owned and controlled by Us.
						</li>
						<li>
							You acknowledge and agree that all Material on the Platform is made available for limited, non-commercial, personal use only. Except as speciﬁcally provided herein or elsewhere on the Platform, no Material may be copied, reproduced, republished, sold, downloaded, posted, transmitted,
							or distributed in any way, or otherwise used for any purpose, by any person or entity, without Our prior express written permission, which is at Our sole discretion.
						</li>
						<li>
							You may not add, delete, distort, or otherwise modify the Material. Any unauthorized attempt to modify any Material, to defeat or circumvent any security features, or to utilize the Platform or any part of the Material for any purpose other than its intended purposes is strictly
							prohibited and such actions will be considered as violation of the Terms of Use and will result in immediate account blockage or any such action the We deem fit.
						</li>
					</ol>

					<h5>21. Shubhra Ranjan and Third Parties</h5>
					<ol>
						<li>
							All the tutors in the tutorial videos You access as part of the Services You use or subscribe for are independent third parties and not employees of the Company. The professional and technical information contained in the video tutorial are the sole responsibility of the Tutor. Even
							though We review the content hosted as part of the Services on a best effort basis, We disclaim all warranties as regards the authenticity or correctness of information communicated to You by the tutors and We do not guarantee that the tutor videos contain updated information on the
							subject matter.
						</li>
						<li>
							The Platform may contain links to other apps or web pages owned by third parties (i.e. advertisers, afﬁliate partners, strategic partners, or others). We are not responsible for examining or evaluating, and we do not warrant the products or offerings of, any of these businesses or
							individuals, or the accuracy of the content of their website. We do not assume any responsibility or liability for the actions, product and content of any such websites. Before You access or visit any third-party app or website, You should review website terms of use and policies for
							such app or web pages. If You decide to access any such third-party platform, You do so at your own risk. The hosting of such links to third party content cannot under any circumstances be construed as Our endorsement of such third parties and You shall not implead Us in any suit or
							claim involving You and such third parties.
						</li>
					</ol>

					<h5>22. Intellectual Property Rights</h5>
					<ol>
						<li>
							We provide You with a single limited license to use and access the Platform and Materials hosted as part of the subscribed Services on devices which We have permitted access at the time of Your subscription to the Services for the limited purpose of accessing the tutorial or associated
							Study Materials online. The license is speciﬁcally personal, non-transferable, and non-exclusive. All content on the Platform, which is including, but not limited to, designs, text, graphics, images, information, logos, button icons, software, audio ﬁles and any other similar content
							are the exclusive and sole property of Us, the Company.
						</li>
						<li>All icons and logos are trademarks of and proprietary to Us. The unauthorized copying, modiﬁcation, use or publication of these marks is strictly prohibited.</li>
						<li>
							All material on the Platform, including images, illustrations, audio clips, video clips and third-party licensed images are protected by copyrights, trademarks and other intellectual property rights. Material on the Platform is solely for your personal, non-commercial use. You must not
							copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by e-mail or other electronic means, whether directly or indirectly and You must not assist any other person to do so.
						</li>
						<li>
							Without the prior written consent of Us, which is at Our sole discretion, modiﬁcation of the Content, use of the Content on any other website, app or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the
							copyrights, trademarks and other proprietary rights, and is prohibited.
						</li>
						<li>
							At all times, We and/or Our licensors, remain the owner of the intellectual property in the tutorial, and the study materials. No Course and/or study materials nor any part thereof may be reproduced, stored in a retrieval system or transmitted any form or by any means without the prior
							written permission of Us.
						</li>
					</ol>

					<h5>23. Terms and Termination</h5>
					<ol>
						<li>
							This Agreement shall remain in full force and effect for so long as You use the Platform. You may terminate your subscription at any time, for any reason, by contacting the Support channels. We, however, do not offer any refunds because of Your termination or discontinuation of the
							Services. Any termination or Subscription modiﬁcations initiated by You are subject to the Policy which is available at https://www.ShubhraRanjan.com/home/cancellation-policy.
						</li>
						<li>
							We reserve the right to terminate Your services without prior notice. Your account or Your access to the Platform maybe terminated immediately, with or without notice to You, and without liability to You, if We believe that You have breached any of covenants, conditions, restrictions
							or limitations contained in these Terms of Use or the Privacy Policy, or any false or misleading information, or interfered with use of the Platform by others.
						</li>
						<li>
							All Content on the Platform, (including but not limited to software) and Services, included on or otherwise made available to You across all mediums are provided on permitted number of views basis on a limited number of whitelisted devices without any representation or warranties,
							express or implied except otherwise speciﬁed in writing. Without prejudice to the forgoing paragraph, Shubhra Ranjan does not warrant that:
							<ol className="alphaBate">
								<li>Platform will be constantly available or;)</li>
								<li>The information made available through the Services on the Platform is complete, true or accurate.</li>
							</ol>
						</li>
						<li>
							We are not responsible to You for any data that You lose either (a) as a result of accessing the tutorial, or (b) as a result of accessing study materials, or (c) otherwise during the course of subscription. It is your responsibility to ensure that You regularly save and back up (i)
							all data which You hold on the computer from which You are accessing the Services and (ii) all data that You are inputting when completing a study module being offered.
						</li>
						<li>We will not be held responsible for any delay or failure to comply with its obligations under these Terms if the delay or failure arises from any causes which is beyond Our reasonable control.</li>
						<li>
							Each provision in these Terms of Use shall be construed separately as between You and Us. If any part of these Terms of Use is held to be unreasonable, inapplicable, or unenforceable, but would be valid if some part thereof was deleted, such provision shall apply but with such
							modiﬁcation as may be necessary to make it valid and effective.
						</li>
						<li>
							The tutorials are for personal training and for knowledge enhancement purposes only. We do not accept any responsibility to any party for the use of the Services provided and/or the contents of the Study materials for any purpose other than training for educational purposes, including
							but not limited to the giving of advice by You to any third party.
						</li>
						<li>
							We shall not be responsible for any claims that may arise as a direct or indirect consequence of any third-party placing reliance and acting upon the information obtained as part of the Services or Your advice to a third party basis the Study Material or tutorial videos or any other
							Services.
						</li>
					</ol>

					<h5>24. Exclusions and Limitations</h5>
					<p>
						The Platform and Services are intended to be used within the jurisdiction of India only. Those who access or use the Platform from other jurisdictions do so at their own volition and are responsible for compliance with the local law. Some jurisdictions do not allow the exclusion of
						certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, in certain jurisdictions, some of the above limitations of liability may not apply to You, nevertheless, all other provisions of these Terms remain in full force and
						effect.
					</p>

					<h5>25. Indemnity</h5>

					<ol>
						<li>
							You agree to defend, indemnify and hold Us harmless, Our subsidiaries, afﬁliates, subcontractors, ofﬁcers, directors, employees, consultants, representatives and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or expenses (including but not
							limited to attorneys’ fees and costs) arising from:
							<ol className="alphaBate">
								<li>Your use of and access to the Platform;</li>
								<li>Third-party claims who rely on Your representations to them based on the information made available through Our services;</li>
								<li>Your violation of any conditions in the Terms of Use and the Privacy Policy; or</li>
								<li>Your violation of any third party right, including without limitation any copyright, property, or privacy right.</li>
							</ol>
						</li>
					</ol>

					<h5>26. Additional Terms</h5>

					<ol>
						<li>
							We reserve the right at any time to modify, edit, delete, suspend or discontinue, temporarily or permanently the Service or Platform (or any portion thereof) with or without notice. You agree that we will not be liable to You or to any third party for any such modiﬁcation, editing,
							deletion, suspension or discontinuance of website.
						</li>
						<li>This Agreement and any rights and licenses granted here under, may not be transferred or assigned by You, but may be assigned by Shubhra Ranjan without restriction.</li>
						<li>
							These Terms of Use together with the Privacy Policy as well as subscription details such as pricing tables and any other legal notices published by Shubhra Ranjan on the Platform, shall constitute the entire agreement between You and Shubhra Ranjan concerning the Platform and governs
							your use of the Service and use of the Platform, superseding any prior agreements between You and Shubhra Ranjan with respect to the subject matter covered in these Terms of Use.
						</li>
						<li>
							Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour
							to give effect to the parties’ intentions as reﬂected in the provision, and the other provisions of this Agreement remain in full force and effect.
						</li>
						<li>These Terms are governed by the laws of India. Any matters arising under these terms shall be subject to the exclusive jurisdiction of courts located in Delhi.</li>
					</ol>

					<h5>27. Grievances</h5>

					<ol>
						<li>In case of any grievance arising from the use of Platform, please contact us at 9810212719.</li>
					</ol>
				</div>
			</div>
		</div>
	);
}

export default TermsCondition;
