import React from "react";
import Slider from "react-slick";
import { charLimit } from "config/utils";


import "./instituteToppers.scss";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { countArray, sliderItem } from "config/utils";

const InstituteToppers = ({
  toppersArray = [],
  title,
  subTitle,
  bottomText,
  loading,
}) => {
  var toppers = {
    dots: false,
    arrows: true,
    slidesToShow: sliderItem(toppersArray, 4),
    slidesToScroll: 1,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="institute_toppers spacing">
      <div className="container">
        <div className={`page_title ${subTitle ? "" : "padding"}`}>
          <h3>{title}</h3>

          {subTitle && <p>{subTitle}</p>}
        </div>

        <div className="toppers_card_wrap">
          {/* loading card */}
          {loading && (
            <div className="d-flex">
              {countArray(3).map((data) => {
                return (
                  <div className="w-50 mx-1" key={data}>
                    <CourseSkeleton height={150} />
                  </div>
                );
              })}
            </div>
          )}

          {/************** not found component **************/}
          {!loading && toppersArray.length <= 0 && (
            <h5 className="text-center">No Toppers Added </h5>
          )}

          {/* toppers slider */}
          {!loading && toppersArray.length > 0 && (
            <>
              <Slider {...toppers}>
                {toppersArray &&
                  toppersArray.map((item, index) => {
                    return (
                      <div className="toppers_card " key={index}>
                        <div className="content">
                          <h4>AIR {item.rank}</h4>
                          <b className="name">{charLimit(item.TopperName,22)}</b>
                          <span className="exam">{`${item.course || "N/A"} ${
                            item.Year
                          } ${item.StreamData?.StreamName}`}</span>
                        </div>
                        <div className="image">
                          <img
                            src={item.ProfilePic}
                            alt="topper-pic"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              {bottomText && <p className="bottom_detail">{bottomText}</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstituteToppers;
