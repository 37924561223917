import React, { useState } from "react";
import { BiRupee } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Calendarimg, Teacherimg, UserGroupimg } from "assets/icons";
import { Courseimg1 } from "assets/images";
import DemoLacture from "components/demo-lacture/DemoLacture";

import "./TeacherProfile.scss";
import { teacherDetailById } from "services/home";
import { charLimit, momentDateFormat, sliderItem } from "config/utils";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import SuperTag from "components/SuperTag/SuperTag";
import { errorResponse, failResponse } from "config/config";
import TeacherDetailSkeleton from "components/skeleton/TeacherDetailSkeleton";

const TeacherProfile = () => {
  const { id } = useParams();
  const [faculty, setFaculty] = useState({});
  const [allLectures, setAllLectures] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [intro, setIntro] = useState("");
  const [loading, setLoading] = useState(false);

  var Workshop = {
    dots: false,
    arrows: true,
    slidesToShow: sliderItem(allCourses, 5),
    // slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    centerPadding: "30px",

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  // --------------- getting staff detail by id ----------------//
  React.useLayoutEffect(() => {
    const getTeacherDetail = async (id) => {
      try {
        setLoading(true);
        const res = await teacherDetailById({ teacher_id: id });

        if (res.code === 200) {
          const { teacherDetails } = res.data;
          setFaculty(teacherDetails || {});
          setAllLectures(teacherDetails.teacherLectures.rows || []);
          setAllCourses(teacherDetails.teacherCourses.rows || []);
          setIntro(teacherDetails.video);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    getTeacherDetail(id);
  }, [id]);

  return (
    <section className="teacher_profile">
      {loading && <TeacherDetailSkeleton />}
      {!loading && (
        <>
          <div className="profile_banner spacing">
            <div className="container">
              <h3>Teacher profile</h3>
              <div className="teacher_card">
                <div className="teacher_image">
                  <img src={faculty.ProfilePic} alt="" loading="lazy" />
                </div>
                <div className="content">
                  <h4>{faculty.fullName}</h4>
                  <p>
                    <SuperTag>{charLimit(faculty.Description, 600)}</SuperTag>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {intro && (
            <div className="teacher_introduction spacing">
              <div className="container">
                <div className="page_title">
                  <h3>Teacher’s Introduction</h3>
                </div>

                <div className="video_modal">
                  <YoutubeVideo src={intro} width="100%" />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* Demo Lecture */}
      <DemoLacture
        lectures={allLectures}
        facultyName={faculty.fullName}
        loading={loading}
      />

      {/* Courses Conducted by */}
      <div className="upcomming_classrooms spacing">
        <div className="container">
          <div className="page_title padding">
            <h3>Top courses conducted by {faculty.fullName}</h3>
          </div>

          <div className="coursecard_wrap">
            {/* courses card */}
            {allCourses && allCourses.length > 0 ? (
              <>
                <Slider {...Workshop}>
                  {allCourses.map(
                    ({
                      id,
                      CourseTitle,
                      CourseType,
                      CourseFee,
                      AdmissionStartDate,
                      image,
                      NumberOfSeat,
                    }) => {
                      return (
                        <div className="course_card" key={id}>
                          <div className="image_wrap">
                            <img
                              src={image || Courseimg1}
                              alt={CourseTitle}
                              loading="lazy"
                            />
                          </div>
                          <div className="content">
                            <div className="course_detail">
                              <h5>{CourseTitle}</h5>
                              <ul>
                                <li>
                                  <img
                                    src={Calendarimg}
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                  Starting from
                                  <b> {momentDateFormat(AdmissionStartDate)}</b>
                                </li>
                                <li>
                                  <img src={Teacherimg} alt="" loading="lazy" />
                                  {CourseType === "Both"
                                    ? "Hybrid"
                                    : CourseType}
                                  Course
                                </li>
                                <li>
                                  <img
                                    src={UserGroupimg}
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                  {NumberOfSeat} Seats Available
                                </li>

                                <li>
                                  <h6 className="price">
                                    <BiRupee />
                                    {CourseFee} /-
                                  </h6>
                                  <span>Inclusive of taxes</span>
                                </li>
                              </ul>
                            </div>
                            <div className="button_wrap">
                              <Link
                                to={`/course-detail/${CourseTitle}/${id}`}
                                className="button"
                              >
                                View Details
                                <span className="icon">
                                  <BsArrowRight />
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Slider>
              </>
            ) : (
              <>
                <div className="listing_card text-center">
                  <h5>Courses Not Available</h5>
                </div>
              </>
            )}

            <div className="button_wrap spacing padding_bottom center">
              <Link to="/course-listing" className="button">
                View All
                <span className="icon">
                  <BsArrowRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TeacherProfile;
