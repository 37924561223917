import React, { useState } from "react";
import Drawer from "dashboard/components/drawer/Drawer";
import { FaBell } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";
import { deleteNotifications, notificationList } from "services/dashboard";
import { countArray, groupBy, todaysDate } from "config/utils";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCourse } from "context/context";
import Notification from "./Notification";
import { noNotificationImg } from "assets/images";

const NotificationDrawer = () => {
  // const [loading, setLoading] = useState(false);
  const { allNotifications, setAllNotifications, notificationCheck } =
    useCourse();

  const [notificationShow, setNotificationShow] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [total, setTotal] = useState(0);
  // const [delNotification, setDeleteNotification] = useState();
  // get all notification
  const getAllNotifications = async (page) => {
    try {
      const res = await notificationList({ page: page });
      if (res.code === 200) {
        setAllNotifications(
          [...allNotifications, ...res.data.notification] || []
        );
        setTotal(res.data?.count);
        setActivePage(page + 1);
        if (
          allNotifications.length + res.data.notification?.length >=
          res.data.count
        ) {
          setHasMore(false); // No more data to load
        }
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  const deleteNotification = async (id) => {
    try {
      const res = await deleteNotifications({ notificationId: id });
      if (res.code === 200) {
        const res = await notificationList({ page: activePage });
        if (res.code === 200) {
          setAllNotifications(res.data.notification || []);
          setTotal(res.data?.count);
        }
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // ----------------------- getting all notifications -------------------------//
  React.useLayoutEffect(() => {
    if (notificationShow) {
      getAllNotifications(activePage);
    }
    // eslint-disable-next-line
  }, [notificationShow]);

  return (
    <>
      <li>
        <div
          className="position"
          onClick={() => {
            setNotificationShow(true);
          }}
        >
          {notificationCheck === 1 ? <div className="badge green"></div> : null}
          <FaBell />
        </div>
        <Drawer
          show={notificationShow}
          handleClose={() => setNotificationShow(false)}
          title={"Notifications"}
          className={"help_offcanvas"}
        >
          <div className="notification_wrapper">
            <ul
              className="p-0"
              id="scrollableDiv"
              style={{ height: 559, overflow: "auto" }}
            >
              {/* main component */}
              {allNotifications?.length > 0 && (
                <InfiniteScroll
                  loader={
                    <>
                      {countArray(5).map((data) => {
                        return (
                          <li className="my-3 loading" key={data}>
                            <CourseSkeleton height={100} />
                          </li>
                        );
                      })}
                    </>
                  }
                  endMessage={<p style={{ textAlign: "center" }}></p>}
                  dataLength={allNotifications?.length}
                  next={() => getAllNotifications(activePage + 1)}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                >
                  {groupBy(allNotifications, "addedon").map((data) => {
                    return (
                      <div className="notification_inner" key={data.date}>
                        <h6>{todaysDate(data.date)}</h6>

                        {data.data?.map((data, i) => {
                          return (
                            <Notification
                              key={data.id}
                              notificationData={data}
                              handleClose={() => setNotificationShow()}
                              deleteNotification={() =>
                                deleteNotification(data.id)
                              }
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </InfiniteScroll>
              )}

              {/* not found */}
              {allNotifications?.length <= 0 && (
                <li className="not_found no_notification">
                  <img src={noNotificationImg} alt="" />
                  <p>Whoops..... Notification is not available</p>
                </li>
              )}
            </ul>
          </div>
        </Drawer>
      </li>
    </>
  );
};

export default NotificationDrawer;
