import React, { useEffect, useState } from 'react';
import './PYQPrelims.scss';
import { Select } from 'components/Form';
import { GetPrelimsPYQ } from 'services/freeresources/freeresources';
import { FaChevronLeft, FaPlus, FaMinus } from "react-icons/fa"; // Added FaPlus and FaMinus icons
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

const PYQPrelims = () => {
  const [year, setYear] = useState("");
  const [records, setRecords] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [limit] = useState(10);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const [showAnswerIndex, setShowAnswerIndex] = useState(null); // State to toggle answer visibility

  const getQuestions = async () => {
    try {
      const res = await GetPrelimsPYQ({ PYQYear: year });
      if (res.code === 200) {
        setRecords(res.data.prelimspyq || []);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    getQuestions();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [year, activePage]);

  const handleBack = () => {
    navigate('/freeresources/FRPrelims');
  };

  return (
    <>
      <section className="courser_report spacing">
        <div className="pyq-container">
          {isMobile && (
            <div className="mobile-back-arrow" onClick={handleBack}>
              <FaChevronLeft style={{ color: '#181822', fontSize: '24px' }} />
            </div>
          )}
          <div className="subsection-prelims-pyq-container">
            <h3 className="pagetitle">Previous Year Questions (PYQs)</h3>
            <div className="search_bar_wrap mb-3 d-flex">
              <Select
                name="year"
                placeholder="Select Year"
                onChange={({ target }) => setYear(target.value)}
                value={year}
                className="input form-control w-500 custom-select"
              >
                <option value="">Select Year</option>
                {["2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"].map(year => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </Select>
            </div>

            <div className="questions_wrap_prelims-pyq">
              {records?.length > 0 ? (
                records.map((record, i) => (
                  <div key={i} className="question_block_prelims-pyq">
                    <div className="question_header_prelims-pyq" style={{display:"flex"}}>
                      <span className='pquestion'>Q{i + 1 + (activePage - 1) * limit}: </span>
                      <span>&nbsp;{typeof record.Question === 'string' ? parse(record.Question) : record.Question}</span>
                    </div>

                    <div className="options_container_prelims-pyq">
                      {[record.Option1, record.Option2, record.Option3, record.Option4].map((option, idx) => (
                        <div key={idx} className="option_box">
                          {/* <strong>Option {idx + 1}:&nbsp;</strong>  */}
                          {option}
                        </div>
                      ))}
                    </div>

                    <div className="toggle_answer" onClick={() => setShowAnswerIndex(showAnswerIndex === i ? null : i)}>
                      <span style={{ color: "#00239c" }}>{showAnswerIndex === i ? 'Hide Answer' : 'View Answer'}</span>
                      {showAnswerIndex === i ? <FaMinus /> : <FaPlus />} {/* Plus/Minus Icon */}
                    </div>

                    {showAnswerIndex === i && (
                      <div className="answer_section_prelims-pyq">
                        <div className="correct_option">
                          <strong style={{ color: "#28a745" }}>Correct Option: </strong>
                          <span>
                            {record.CorrectOption === record.Option1 && "Option 1"}
                            {record.CorrectOption === record.Option2 && "Option 2"}
                            {record.CorrectOption === record.Option3 && "Option 3"}
                            {record.CorrectOption === record.Option4 && "Option 4"}
                          </span>
                          = {record.CorrectOption}
                        </div>
                        <div className="explanation">
                          <strong>Explanation: </strong>
                          {typeof record.Explaination === 'string' ? parse(record.Explaination) : record.Explaination}
                        </div>
                      </div>
                    )}

                  </div>
                ))
              ) : (
                <div className="text-center" style={{ padding: '20px', fontStyle: 'italic' }}>No records available</div>
              )}
            </div>
          </div>
        </div> 
      </section> 
    </> 
  );
};

export default PYQPrelims;
