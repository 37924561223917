import CommonModal from "components/modal-box/CommonModal";
import React, { useState } from "react";
import "./WelcomePopup.scss";
import { errorResponse, failResponse } from "config/config";
import { getAdvertisementData } from "services/home";

const WelcomePopup = () => {
  const [popup, setPopup] = useState(false);
  // const [value, setValue] = useCookies(["registered"]);
  const [advertisementData, setAdvertisementData] = useState({
    image: "",
  });

  
  // let nextDay = new Date();
  // let today = new Date();

  // nextDay.setDate(today.getDate() + 1);

  const getAdvertisement = async () => {
    try {      
      const res = await getAdvertisementData();

      if (res.code === 200) {
        // setValue("registered", true, {
        //   path: "/",
        //   expires: nextDay,
        // });
        setAdvertisementData({ image: res.data.image });
        setPopup(true);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  React.useEffect(() => {
    // if (value.registered) {
    // setPopup(false); //Modal does not open if cookie exists
    // } else if (!value.registered) {
    getAdvertisement(); //Creates a cookie and shows modal.
    // }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <CommonModal
        className="welcome_wrapper"
        show={popup}
        handleClose={() => setPopup(false)}
        size="lg"
      >
        <div className="welcome">
          <div className="image_wrap" style={{height:'100%'}}>
            <img src={advertisementData.image} alt="Welcome" className="img" />
          </div>
          {/* <div className="detail_wrap">
              <h3>Welcome to Shubra Ranjan</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
            </div> */}
        </div>
      </CommonModal>
    </>
  );
};

export default WelcomePopup;
