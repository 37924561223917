import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./FacultiesList.scss";
// import { Placeholder } from "react-bootstrap";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { charLimit, countArray } from "config/utils";
import SuperTag from "components/SuperTag/SuperTag";

const FacultiesList = ({ faculties = [], loading }) => {
  var sliderFaculties = {
    dots: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 900,
    autoplay: false,
    autoplaySpeed: 8000,
    centerMode: false,
    infinite: true,
    focusOnSelect: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          centerPadding: "80px",
        },
      },
    ],
  };
  return (
    <div className="skilled_faculties spacing">
      <div className="ourSkilledFaculties">
        <div className="container">
          <div className="page_title">
            <h3>Our Faculty</h3>
            <p>
              Dedicated and experienced pillars at SRIAS, responsible for
              nurturing numerous toppers.
            </p>
          </div>

          <div>
            <div>
              {/************* loading component *************/}
              {loading && (
                <div className="d-flex">
                  {countArray(5).map((data) => {
                    return (
                      <div className="w-25 mx-1" key={data}>
                        <CourseSkeleton height={320} />
                      </div>
                    );
                  })}
                </div>
              )}

              {/************* not found component *************/}
              {!loading && faculties.length <= 0 && (
                <h5 className="text-center">No Faculty Added</h5>
              )}

              {/************* faculty component *************/}
              {!loading && faculties.length > 0 && (
                <div className="faculties_slider">
                  <Slider {...sliderFaculties}>
                    {/* {/ iterate all faculties /} */}
                    {faculties.map(
                      ({ id, name, image, shortDescription }, i) => {
                        return (
                          <div className="faculties_card_wrap" key={i}>
                            <div className="faculties_card">
                              <div className="image_wrap">
                                {/* <Link to={`/teacher/${name}/${id}`}>
                                
                                </Link> */}
                                <img src={image} alt="" loading="lazy" />
                              </div>
                              <div className="content">
                                <h6>{name}</h6>
                                {/* <p>
                                SOCIOLOGY | MA History & ma sociology from d.u
                              </p> */}
                              </div>
                            </div>
                            <div className="faculties_card_active">
                              <div className="contact_wrap">
                                <div className="faculty_detail">
                                  <h4>{name}</h4>
                                  {/* <p className="designation">
                                    Director of Shubhra Ranjan IAS study
                                    pvt.ltd.
                                  </p> */}
                                  <p className="description">
                                    <SuperTag>
                                      {charLimit(shortDescription, 600)}
                                    </SuperTag>
                                  </p>
                                </div>
                                <div className="faculty_image">
                                  <img src={image} alt="" loading="lazy" />
                                </div>
                              </div>
                              <ul className="links">
                                <li>
                                  <Link to={`/teacher/${name}/${id}`}>
                                    View Teacher Profile
                                  </Link>
                                </li>
                                {/* <li>
                                <Link to="">View Teacher - Led Exam</Link>
                              </li> */}
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
                // <Slider {...sliderFaculties}>
                //   {/ iterate all faculties /}
                //   {faculties.map(({ id, name, image }) => {
                //     return (
                //       <div className="facultiescard" key={id}>
                //         <Link to={`/teacher/${name}/${id}`}></Link>
                //         <div className="item">
                //           <div className="image_wrap">
                //             <img src={image} alt={name} loading="lazy"/>
                //           </div>
                //           <h6>{name}</h6>
                //         </div>
                //       </div>
                //     );
                //   })}
                // </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacultiesList;
