import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Bannerbgimg } from "assets/images";
import "./CenterLandingpage.scss";
import InstituteToppers from "components/institute-toppers/InstituteToppers";
import FacultiesList from "components/faculties-list/FacultiesList";
import CampusGallery from "components/campus-photos/CampusGallery";
import Testimonials from "components/testimonial/Testimonial";
import ContactusForm from "components/contactus-form/Contactus-form";
import FAQ from "components/FAQs/FAQs";
import { centerBasedLandingPage } from "services/center";
import CourseDetailSkeleton from "components/skeleton/CourseDetailSkeleton";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import CenterMap from "./section/CenterMap";
import UpcomingCourses from "./section/UpcomingCourses";
import {
  errorResponse,
  failResponse,
  normalDecryptData,
  normalEncryptData,
} from "config/config";

const CenterLanding = () => {
  const { centerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [centerData, setCenterData] = useState({});
  const [examTypes, setExamTypes] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [toppers, setToppers] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [allCenters, setAllCenters] = useState([]);
  const [type, settype] = useState("center");
  const navigate = useNavigate();

  // --------------- getting all required masters data ----------------//
  React.useLayoutEffect(() => {
    const getCentersData = async () => {
      try {
        setLoading(true);

        // decrypt the center id and navigate ot home if is invalid
        const rcname = centerId;
        const rcarray = rcname.split("_");

        let centername = rcarray[2];
        console.log(centername);
        let mainId = 0;
        if (centername == "Pune") {
          mainId = 5;
        }
        else if (centername == "Lucknow") {
          mainId = 1;
        }
        else if (centername == "Bengaluru") {
          mainId = 4;
        }
        else if (centername == "Hyderabad") {
          mainId = 3;
        }
        else if (centername == "NewMumbai") {
          mainId = 2;
        }
        else if (centername == "Mumbai") {
          mainId = 13;
        }
        else if (centername == "Jammu") {
          mainId = 6;
        }
        else if (centername == "Nagpur") {
          mainId = 7;
        }
        else if (centername == "Bhubaneswar") {
          mainId = 8;
        }
        else if (centername == "Bhopal") {
          mainId = 9;
        }
        else if (centername == "Chandigarh") {
          mainId = 10;
        }
        else if (centername == "Varanasi") {
          mainId = 11;
        }
        else if (centername == "Delhi") {
          mainId = 12;
        }
        else if (centername == "Jaipur") {
          mainId = 142;
        }
        //normalDecryptData(centerId, "route");




        if (!mainId) navigate("/");
        if (mainId === 0) {
          navigate(`*`);
        } else {
          // Proceed with fetching data based on the determined mainId
          const res = await centerBasedLandingPage(
            type === "center" ? { centerpageId: mainId } : { verticleId: mainId }
          );

          if (res.code === 200) {
            const {
              centerData,
              centerFaq,
              examType,
              center,
              testimonials,
              toppers,
              teachers,
            } = res.data;

            setCenterData(
              {
                ...centerData.center,
                latitude: centerData.latitude,
                longitude: centerData.longitude,
                introductionVideoURL: centerData.introductionVideoURL,
                id: centerData?.id,
              } || {}
            );
            setGalleryImages(
              centerData?.centerImage.map((image) => {
                return { id: image.id, src: image.imageUrl, width: 6, height: 4 };
              }) || []
            );
            setAllCenters(center || []);
            setFaqs(centerFaq || []);
            setExamTypes(examType || []);
            setTestimonials(testimonials || []);
            setToppers(toppers || []);
            setFaculties(teachers || []);
            setLoading(false);
          } else {
            failResponse(res);
            setLoading(false);
          }
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };


    // calls all the master functions
    getCentersData();
    // eslint-disable-next-line
  }, [centerId]);



  return (
    <>
      {/* main component */}
      {!loading && (
        <section className="landingpage">
          <div
            className="landingpage_banner spacing"
            style={{ backgroundImage: `url("${Bannerbgimg}")` }}
          >
            <div className="container">
              <div className="content">
              {centerData.CenterName === "Shubhra Ranjan IAS Study, Delhi 25" ? (
                  <h1>Welcome to Shubhra Ranjan IAS Study, Delhi</h1>
                ) : (
                  <h1>Welcome to {centerData.CenterName}</h1>
                )}
                
                {centerData.CenterName === "Shubhra Ranjan IAS Study, Mumbai" ? (
                  <p>
                    Shubhra Ranjan IAS Study, Kandivali at Mumbai is centrally located place for UPSC Aspirants
                    in Mumbai. It is located at the prestigious <b>Thakur College of Science and Commerce (TCSC)</b> in
                    Kandivali. The center offers modern infrastructure with various facilities that allows students to
                    prepare for All India Competitive Exams in Mumbai. Our new center is dedicated to providing
                    exceptional coaching and resources to aspiring civil servants, ensuring they receive the highest
                    quality education and guidance.
                  </p>
                ) : (
                  <p>
                    Join India’s leading institute for Civil services preparation.
                  </p>
                )}

                {/* <div className="banner_button">
                  <div className="button_wrap fill ">
                    <Link to="" className="button">
                      Upcoming Courses
                      <span className="icon">
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="button">
                      Upcoming Courses
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-2">
                        <label form="Bengaluru">
                          <input type="radio" id="Bengaluru" name="center" />{" "}
                          <span>Bengaluru</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <label form="bhopal">
                          <input type="radio" id="bhopal" name="center" />{" "}
                          <span>Bhopal</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">
                        <label form="Bhubaneswar">
                          <input type="radio" id="Bhubaneswar" name="center" />{" "}
                          <span>Bhubaneswar</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-5">
                        <label form="Chandigargh">
                          <input type="radio" id="Chandigargh" name="center" />{" "}
                          <span>Chandigargh</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6">
                        <label form="Chennai">
                          <input type="radio" id="Chennai" name="center" />{" "}
                          <span>Chennai</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-7">
                        <label form="Dehradun">
                          <input type="radio" id="Dehradun" name="center" />{" "}
                          <span>Dehradun</span>
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-9">
                        <label form="Hyderabad">
                          <input type="radio" id="bhopal" name="center" />{" "}
                          <span>Hyderabad</span>
                        </label>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </div>
              <div className="slider_section">
                <YoutubeVideo src={centerData.introductionVideoURL} />
              </div>
            </div>
          </div>

          {/* upcoming Class room course */}
          <UpcomingCourses
            exam={examTypes}
            affiliatecenter={centerData.CenterID}
            centerId={centerData?.id}
            phoneNumber={centerData?.Phone1}
            city={centerData.City}
          />

          {/* Institute Toppers */}
          <InstituteToppers
            title={`Our Toppers from ${centerData.State}`}
            toppersArray={toppers}
            loading={loading}
          />

          {/* Campus photo gallery */}
          <CampusGallery galleryImages={galleryImages} city={centerData.City} />

          {/* Faculties List */}
          <FacultiesList faculties={faculties} loading={loading} />

          {/* Testimonials */}
          <Testimonials testimonials={testimonials} loading={loading} />





          {/* Contact us */}
          <ContactusForm
            center={centerData.City}
            address={centerData.Address}
            centerId={centerData.CenterID}
          />

          {/* FAQ's */}
          <FAQ loading={loading} faqs={faqs} />

          {/* center map  */}
          <CenterMap centerData={centerData} />

          {/* Regional Centres */}
          <div className="regional_cetners spacing">
            <div className="container">
              <div className="page_title padding">
                <h3>Regional Centres</h3>
              </div>

              <ul>
                {allCenters &&
                  allCenters.map((center) => {
                    return (
                      <li key={center.id}>
                        <Link
                          to={
                            center.center?.CenterName === "Shubhra Ranjan IAS Study, Delhi 25"
                              ? `/IAS_Institute_Delhi`
                              : `/IAS_Institute_${center.center?.CenterName.replace(
                                "Shubhra Ranjan IAS Study, ",
                                ""
                              )}`
                          }
                          className="button"
                        >
                          {center.center?.CenterName}
                        </Link>

                      </li>
                    );
                  })}
              </ul>

              {/* <h3 className="other_mode">Other Modes</h3>
              <ul>
                <li>
                  <Link to="" className="button">
                    Tablet Mode
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>

          {/* ////////////////////// Number Data /////////////////// */}

          <div className="number_data spacing">
            <div className="container">
              <div className="number_data_content">
                <ul>
                  <li>
                    <h3>62,000 + </h3> <span>Students Tutored</span>
                  </li>
                  <li>
                    <h3>50 + </h3> <span>Courses Offered</span>
                  </li>
                  <li>
                    <h3>20 + </h3> <span>Experienced faculties</span>
                  </li>
                  <li>
                    <h3>8 </h3> <span>Regional Centers</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* loading component */}
      {loading && (
        <div className="course_detail_section">
          <CourseDetailSkeleton />
        </div>
      )}
    </>
  );
};

export default CenterLanding;
