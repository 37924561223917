// import React from "react";
import React, { useState, useCallback } from "react";

import Gallery from "react-photo-gallery";

import "./CampusGallery.scss";

const CampusGallery = ({ galleryImages = [], city }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="campus_gallery">
      <div className="container">
        <div className="page_title padding">
          <h3>{city} Campus</h3>
        </div>

        <div className="image_section">
          <Gallery photos={galleryImages} onClick={openLightbox} />
        </div>
        {/* <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={galleryImages.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway> */}
      </div>
    </div>
  );
};

export default CampusGallery;
