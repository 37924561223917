import React from "react";
import { BsCalendar4Week, BsClock } from "react-icons/bs";
import { LiveLectureImg2 } from "assets/images";

import Slider from "react-slick";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import {
  charLimit,
  countArray,
  momentDateFormat,
  sliderItem,
  timeConverter,
  timeFormatWith12Hour,
} from "config/utils";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import { normalEncryptData } from "config/config";
import { AiOutlineCreditCard } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";

const LiveLectures = ({ lectures = [], loading, getLiveLectures }) => {
  const navigate = useNavigate();

  //  Slider //
  var Coursecard = {
    dots: false,
    arrows: true,
    slidesToShow: sliderItem(lectures, 3),
    slidesToScroll: 1,
    infinite: false,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  // ----------------------- video play handler -------------------------//
  const videoPlayHandler = (videoId, time, date, isGps, isPlatformEnabled) => {
    if (new Date() < timeConverter(time, date)) {
      toast.error("Live video is not started");
    } else if (!isPlatformEnabled) {
      toast.error(
        "Live Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
    } else {
      navigate(
        `/dashboard/live-lecture/detail/${normalEncryptData(
          `${videoId},${isGps}`,
          "route"
        )}`,
        {
          state: { auth: true },
        }
      );
    }
  };
  

  return (
    <>
    
      <div className="daily_lecture spacing">
        <div className="refresh_button_wrap">
          <h4> Live Lectures</h4>
          <button className="dashboard_button" onClick={getLiveLectures}>
            <BiRefresh fontSize={"19"} className="mx-1" />
            Refresh
          </button>
        </div>
        <div className="daily_lecture_slider">
          {/* ***************Loading Component*************** */}
          {loading && (
            <div className="d-flex">
              {countArray(2).map((data) => {
                return (
                  <div className="lecture_card p-0" key={data}>
                    <CourseSkeleton height={290} />
                  </div>
                );
              })}
            </div>
          )}

          {/* ***************not found Component*************** */}
          {!loading && lectures?.length <= 0 && (
            <>
            <div style={{marginTop:"130px"}}>
               <LectureNoteFound note="Live lectures are not available"/>
               </div>
            </>
          )}

          {/* ***************main Component*************** */}
          {!loading && lectures?.length > 0 && (
            <Slider {...Coursecard}>
              {lectures.map(
                ({
                  LectureTitle,
                  isLive,
                  liveLectureID,
                  teacher,
                  StartTime,
                  EndTime,
                  ClassRoom,
                  ScheduleDate,
                  channel,
                  courseName,
                  isGps,
                  coursePlatformEnabled,
                }) => {
                  return (
                    <div
                      className="lecture_card"
                      role="button"
                      key={liveLectureID}
                      onClick={() =>
                        videoPlayHandler(
                          liveLectureID,
                          StartTime,
                          ScheduleDate,
                          isGps,
                          coursePlatformEnabled
                        )
                      }
                    >
                      <div className="image_Wrap">
                        <img src={LiveLectureImg2} alt="" loading="lazy" />
                        {isLive && <span className="live_tag">Live</span>}
                      </div>

                      <div className="content">
                        <h5> {charLimit(LectureTitle || "N/A", 40)}</h5>
                        <p>
                          <AiOutlineCreditCard />
                          {`${courseName || "N/A"}`}
                        </p>
                        <p>
                          <BsCalendar4Week /> {momentDateFormat(ScheduleDate)}
                        </p>
                        <p>
                          <BsClock />
                          {timeFormatWith12Hour(StartTime)} -
                          {timeFormatWith12Hour(EndTime)}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};

export default LiveLectures;
