import React, { memo } from "react";
import SuperTag from "components/SuperTag/SuperTag";
import { countArray } from "config/utils";

const ALPHA_ROMAN_COUNTS = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
};

const ROMAN_COUNTS = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const Questions = ({
  question = {},
  disabled,
  index = 1,
  optionSelectHandler,
  totalOptions = 0,
  checked,
}) => {
  return (
    disabled && (
      <>
        <p className="question d-flex">
          <b>Q&nbsp;{index}.</b>
          <SuperTag>{question.Question}</SuperTag>
        </p>

        <ul className="ansOption">
          {countArray(totalOptions).map((num) => {
            return (
              <li key={num}>
                <input
                  type="radio"
                  name={`question`}
                  id={`question${num}`}
                  defaultChecked={checked(`Option ${ROMAN_COUNTS[num]}`)}
                />
                <label
                  htmlFor={`question${num}`}
                  className={`${
                    checked(`Option ${ROMAN_COUNTS[num]}`) ? "checked" : ""
                  }`}
                  onClick={() =>
                    optionSelectHandler({
                      questionId: question.QuestionID,
                      qanswer: `Option ${ROMAN_COUNTS[num]}`,
                    })
                  }
                >
                  <span>{ALPHA_ROMAN_COUNTS[num]}.</span>
                  {question[`Option${num}`]}
                </label>
              </li>
            );
          })}
        </ul>
      </>
    )
  );
};

export default memo(Questions);
