import React from 'react';

const HotTopicsforPrelims = () => {
  return (
    <div className="subsection-container">
      <h3>Hot Topics for Prelims</h3>
      <p>Stay updated with the latest topics that are trending for prelims.</p>
      
      
      
    </div>
  );
};

export default HotTopicsforPrelims;
