import { errorResponse, failResponse } from "config/config";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getMasterCourseCategories } from "services/master";

const MobileCourseMenu = ({ menuClose }) => {
  const [allCategories, setAllCategories] = useState([]);
  React.useEffect(() => {
    // get course category
    const getCourseCategory = async () => {
      try {
        const res = await getMasterCourseCategories();
        if (res.code === 200) {
          setAllCategories(res.data.courseCategories || []);
          const { id, name } = res.data?.courseCategories?.[0];
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    getCourseCategory();
  }, []);
  return (
    <>
      <ul onClick={menuClose}>
        {allCategories &&
          allCategories.map((data, index) => {
            return (
              <li key={index}>
                <Link to={`/course-listing/course/${data.name}/${data.id}`}>
                  {data.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default MobileCourseMenu;
