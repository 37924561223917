import React from "react";
import { Placeholder } from "react-bootstrap";

const CourseSkeleton = ({ height = "100%", width = "100%" }) => {
  return (
    <>
      <Placeholder
        as="div"
        animation="wave"
        aria-hidden="true"
        className="rounded"
        style={{ width: `${width}` }}
      >
        <Placeholder
          xs={12}
          style={{
            height: `${height === "100%" ? "100%" : `${height}px`}`,
            width: `${width}`,
          }}
        />
      </Placeholder>
    </>
  );
};

export default CourseSkeleton;
