import React from "react";
import { FileUploader as FileDND } from "react-drag-drop-files";

const FileUploader = ({
  types = [],
  onChange,
  classes,
  file,
  disabled = false,
  message = "File selected successfully, Kindly click on upload now",
}) => {
  React.useEffect(() => {
    if (file) {
      const element = document.querySelector(".test_upload span");

      if (element) {
        const successSpan = element.querySelector("span");

        if (successSpan && successSpan.textContent.includes("Successfully")) {
          // Update the text content of the found span
          successSpan.textContent = message; // Replace with your desired text
        }
        if (element && element.textContent.includes("another")) {
          element.textContent = message; // Replace with your desired text
        }
      }
    }
  }, [file, message]);
  return (
    <>
      <FileDND
        multiple={false}
        handleChange={onChange}
        name="file"
        label="Drag & drop your file "
        types={types}
        classes={classes}
        disabled={disabled}
      />
    </>
  );
};

export default FileUploader;
