import React from "react";
import { Codesend } from "assets/icons";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { charLimit, countArray } from "config/utils";

const CoreTakeAway = ({ title, description, coreTakeAway = [], loading }) => {
  return (
    <>
      <div className="core_takeaways spacing">
        <div className="container">
          <div className="page_title">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </div>
          <div className="takeaways">
            <ul>
              {/************** loading component **************/}
              {loading &&
                countArray(4).map((data) => {
                  return (
                    <li key={data}>
                      <ListCourseSkeleton height="100" />
                    </li>
                  );
                })}

              {/************** not found component **************/}
              {!loading && coreTakeAway.length <= 0 && (
                <li className="mw-100 justify-content-center">
                  <h5>No Core Takeaway Available</h5>
                </li>
              )}

              {/************** main component **************/}
              {!loading &&
                coreTakeAway.length > 0 &&
                coreTakeAway.map(({ question, answer, image }, i) => {
                  return (
                    <li key={i}>
                      <img src={image || Codesend} alt="" loading="lazy"
                      />
                      <p>
                        <b>{question}</b>
                        {/* {answer.slice(0, 150)}... */}
                        {charLimit(answer, 150)}
                      </p>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoreTakeAway;
