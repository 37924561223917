import { errorResponse, failResponse } from "config/config";
import { dateFormat, reverseString } from "config/utils";
import { useCourse } from "context/context";
import React, { useCallback, useEffect, useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getSignedUrl } from "services/master";
import { postalContent } from "services/postal";

const PostalFileListing = () => {
  const { id } = useParams();
  const [fileListing, setFileListing] = useState([]);
  const [mainUrl, setMainUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const { course } = useCourse();

  const navigate = useNavigate();

  const getPostalFiles = useCallback(async (courseId, folderId) => {
    try {
      setLoading(true);
      const res = await postalContent({
        courseId,
        folderName: folderId.replaceAll("-", "/"),
      });
      if (res.code === 200) {
        const files = res.data.files.map((data) => {
          return { ...data, type: "File" };
        });
        const folders = res.data.subfolders.map((data) => {
          return { ...data, type: "Folder" };
        });
        setFileListing([...folders, ...files]);
        setMainUrl(res.data.url);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  }, []);

  const breadcrumbNavigator = (ind) => {
    let mainUrl = "/dashboard/postal-folder-listing/";
    let foldersUrl = "";
    if (ind > 0) {
      id.replaceAll("-", "/")
        .split("/")
        .forEach((data, i) => {
          if (ind >= i) {
            foldersUrl += `${data}/`;
          }
        });
    }
    navigate(`${mainUrl}${foldersUrl.replaceAll("/", "-")}`);
  };

  const notesDownloadHandler = async (note) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: note }));
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (!course.isPostal) {
      navigate("/dashboard/home");
      return;
    }
    if (course.courseId) {
      getPostalFiles(course.courseId, id);
    }
  }, [getPostalFiles, course, id, navigate]);

  return (
    <>
      <section className="courser_report spacing upload_answer_sheet">
        <div className="container">
          {/* Breadcrumb */}
          <div className="breadcrumb">
            <div className="container">
              <ul>
                {id
                  .replaceAll("-", "/")
                  .split("/")
                  .map((data, i) => {
                    return (
                      <li key={data}>
                        <span
                          className="link"
                          onClick={() => breadcrumbNavigator(i)}
                        >
                          {i === 0 ? "Postal Course" : data}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="_box">
            <h4>Postal file</h4>
            <div className="table_wrap">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Add on</th>
                    <th>Size</th>
                    <th>Download/View</th>
                  </tr>
                </thead>
                <tbody>
                  {/* loading */}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Loading ...
                      </td>
                    </tr>
                  )}

                  {/* main data row */}
                  {!loading &&
                    fileListing?.length > 0 &&
                    fileListing.map(
                      ({ fileName, createdAt, size, type,fileURL }, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}.</td>
                            <td className="text-start">
                              {type === "Folder" && (
                                <Link
                                  to={`/dashboard/postal-folder-listing/${fileName.replaceAll(
                                    "/",
                                    "-"
                                  )}`}
                                >
                                  {fileName.split("/")[2]}
                                </Link>
                              )}
                              {type === "File" && (
                                <>
                                  {reverseString(
                                    reverseString(fileName).split("/")[0]
                                  )}
                                </>
                              )}
                            </td>
                            <td>{type}</td>
                            <td>{dateFormat(createdAt)}</td>
                            <td>{size}</td>

                            <td>
                              {type === "File" && (
                                <button
                                  className="dashboard_button"
                                  onClick={() =>
                                    notesDownloadHandler(mainUrl + fileName)
                                  }
                                  disabled={submitLoading}
                                >
                                  <FaDownload /> Download
                                </button>
                              )}

                              {type === "Folder" && (
                                <Link
                                  to={`/dashboard/postal-folder-listing/${fileName.replaceAll(
                                    "/",
                                    "-"
                                  )}`}
                                  className="dashboard_button"
                                >
                                  <FaEye /> View
                                </Link>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}

                  {/* not found row */}
                  {!loading && fileListing?.length <= 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No records available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostalFileListing;
