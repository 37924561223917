import React from "react";
import { Placeholder } from "react-bootstrap";

const LectureDetailSkeleton = () => {
  return (
    <>
      <div className="courseList p-0">
        <Placeholder as="div" animation="wave" aria-hidden="true">
          <Placeholder
            xs={12}
            style={{ height: `70vh`, borderRadius: "10px" }}
          />
        </Placeholder>
      </div>
      <div className="course_video p-0">
        <Placeholder as="div" animation="wave" aria-hidden="true">
          <Placeholder
            xs={12}
            style={{ height: `70vh`, borderRadius: "10px" }}
          />
        </Placeholder>
      </div>
    </>
  );
};

export default LectureDetailSkeleton;
