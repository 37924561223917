import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import { FaBook, FaFileAlt, FaLightbulb, FaScroll } from "react-icons/fa"; // Import icons from react-icons
import "./Prelims.scss"; // Import the CSS file for styling

const Prelims = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // Set grid columns based on screen size
  const [gridTemplateColumns, setGridTemplateColumns] = useState('1fr 1fr');

  const updateGridColumns = () => {
    const mobile = window.innerWidth <= 768;
    setIsMobile(mobile);
    setGridTemplateColumns(mobile ? '1fr' : '1fr 1fr'); // 1 column for mobile, 2 for desktop
  };

  useEffect(() => {
    updateGridColumns(); // Set initial grid columns

    // Add event listener to handle screen resize
    window.addEventListener('resize', updateGridColumns);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateGridColumns);
    };
  }, []);

  // Define the styles for the grid
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    gap: '20px', // Add some spacing between the cards
  };

  // Define the titles, paths, icons, and corresponding descriptions for the cards
  const cardData = [
    {
      title: "Syllabus",
      path: "/freeresources/FRPrelimsSyllabus",
      icon: FaBook,
      description: "Find the complete syllabus for the upcoming exams."
    },
    {
      title: "PYQ Prelims",
      path: "/freeresources/FRPrelimsPYQPrelims",
      icon: FaFileAlt,
      description: "Access previous year questions to enhance your preparation."
    },
    {
      title: "Prelims Strategy",
      path: "/freeresources/FRPrelimsPrelimsStrategy",
      icon: FaLightbulb,
      description: "Learn effective strategies to tackle prelims effectively."
    },
    // {
    //   title: "Hot Topics for Prelims",
    //   path: "/freeresources/FRPrelimsHotTopicsforPrelims",
    //   icon: FaScroll,
    //   description: "Stay updated with the latest topics that are trending for prelims."
    // },
  ];

  return (
    <div className="section1-container">
      <div className="card-container" style={gridStyle}>
        {cardData.map((card, index) => (
          <Link to={card.path} key={index} className="card">
            <h4 style={{ fontSize: "17px" }}>
              <card.icon style={{ marginRight: '10px', color: "#DD5D59" }} /> {card.title} {/* Use the icon component */}
            </h4>
            <h6 className="cardh6" style={{ fontWeight: "600", fontSize: "15px" }}>{card.description}</h6> {/* Display the description */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Prelims;
