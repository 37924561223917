import React from "react";
import { Placeholder } from "react-bootstrap";

const CourseSubscriptionSkeleton = () => {
  return (
    <>
      <div className="subscription_content">
        <div className="personal_details">
          <h6>
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder xs={2} className="rounded" />
            </Placeholder>
          </h6>
          <div className="">
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder
                xs={12}
                className="rounded"
                style={{ height: `40vh` }}
              />
            </Placeholder>
          </div>
          <div className="my-3">
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder
                xs={12}
                className="rounded"
                style={{ height: `40vh` }}
              />
            </Placeholder>
          </div>
        </div>
        <div className="course_detail">
          <h6>
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder xs={2} className="rounded" />
            </Placeholder>
          </h6>
          <div className="">
            <Placeholder as="div" animation="wave" aria-hidden="true">
              <Placeholder
                xs={12}
                className="rounded"
                style={{ height: `82vh` }}
              />
            </Placeholder>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSubscriptionSkeleton;
