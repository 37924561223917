import { errorResponse, failResponse } from "config/config";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { homeData } from "services/home";
import { getExamTypes, getMasterCourseCategories } from "services/master";

const MobileCenterMenu = ({ menuClose }) => {
    const [regionalProgram, setRegionalProgram] = useState([]);

  React.useLayoutEffect(() => {
    // get course exam type
    const getHomeData = async () => {
        try {
          const res = await homeData({ type: "home" });
  
          if (res.code === 200) {
            setRegionalProgram(res.data.ourPrograms.regional || []);
          } else {
            failResponse(res);
          }
        } catch (err) {
          errorResponse(err);
        }
      };
  
      // calls all the master functions
      getHomeData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ul onClick={menuClose}>
        {regionalProgram &&
          regionalProgram.map((data, index) => {
            return (
              <li key={index}>
                <Link to={`IAS_Institute_${data.region.replace("Shubhra Ranjan IAS Study, ", "")}`}>
                  {data.region}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default MobileCenterMenu;
