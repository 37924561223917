import React from "react";

const GS3 = () => {
  return (
    <div>
      <h2>Section 2</h2>
      <p>This is Section 2. You can modify this area with whatever content you want to display.</p>
    </div>
  );
};

export default GS3;
