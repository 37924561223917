import React, { useState } from "react";
import "./CourseReport.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { Table } from "react-bootstrap";
import { errorResponse, failResponse } from "config/config";
import { useCourse } from "context/context";
import useDebounce from "hooks/useDebounce";
import { getOnDemandProgress } from "services/dashboard";
import {
  convertMinutesToHoursMinutesSeconds,
  countArray,
  dateFormat,
  simpleDateFormat,
  timeFormatWith12Hour2,
} from "config/utils";
import ReactDatePicker from "react-datepicker";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import Pagination from "react-js-pagination";
import { getCourseSectionList } from "services/lecture";

const CourseReport = () => {
  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [records, setRecords] = useState([]);
  const [cardData, setCardData] = useState({
    totalLecture: 0,
    totalAllocateTime: 0,
    totalConsumeTime: 0,
    totalLecturePublished: 0,
  });

  const [date, setDate] = useState("");
  const [platform, setPlatform] = useState("");
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const debounceValue = useDebounce(search, 300);
  const [allSections, setAllSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  // get course lectures
  const getSectionsList = async () => {
    try {
      setLoading(true);
      const res = await getCourseSectionList({ course_id: course.courseId });

      if (res.code === 200) {
        setAllSections(res.data.sections || []);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // get course lectures
  // const getReportsData = async (
  //   courseId = course.courseId,
  //   search = debounceValue,
  //   reportDate = date,
  //   reportPlatform = platform
  // ) => {
  //   try {
  //     setCardLoader(true);
  //     const finalData = {
  //       course_id: courseId,
  //       search,
  //       platform: reportPlatform,
  //       date: reportDate,
  //     };

  //     if (date) {
  //       Object.assign(finalData, {
  //         date: simpleDateFormat(date),
  //       });
  //     }

  //     const res = await getOnDemandProgress(finalData);

  //     if (res.code === 200) {
  //       const {
  //         lectureViews,
  //         TotalLecture,
  //         TotalTimeAllocated,
  //         totalConsumeTime,
  //         totalLecturePublished,
  //       } = res.data;
  //       setRecords(lectureViews || []);
  //       setCardData({
  //         ...cardData,
  //         totalConsumeTime,
  //         totalLecturePublished,
  //         totalLecture: TotalLecture,
  //         totalAllocateTime: TotalTimeAllocated,
  //       });
  //       setCardLoader(false);
  //     } else {
  //       failResponse(res);
  //       setCardLoader(false);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //     setCardLoader(false);
  //   }
  // };
  const getReportListData = async () => {
    try {
      setLoading(true);
      const finalData = {
        course_id: course.courseId,
        search: debounceValue,
        platform,
        date: date ? simpleDateFormat(date) : "",
        sectionId: selectedSection,
      };

      const res = await getOnDemandProgress(finalData);

      if (res.code === 200) {
        const {
          lectureViews,
          TotalLecture,
          TotalTimeAllocated,
          totalConsumeTime,
          totalLecturePublished,
          count,
        } = res.data;
        setRecords(lectureViews || []);
        setCardData({
          totalConsumeTime,
          totalLecturePublished,
          totalLecture: TotalLecture,
          totalAllocateTime: TotalTimeAllocated,
        });
        setTotal(count || 0);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    } finally {
      setLoading(false);
    }
  };

  const searchHandler = () => {
    getReportListData();
  };

  // ----------------------- getting lecture's section list -------------------------//
  React.useEffect(() => {
    if (course.courseId && course.isOnDemand) {
      getSectionsList();
    }
    // eslint-disable-next-line
  }, [course]);

  React.useEffect(() => {
    if (course.courseId) {
      getReportListData();
    }
    // eslint-disable-next-line
  }, [course, debounceValue, platform, date, selectedSection]);
  // }, [course, debounceValue, activePage]);

  return (
    <>
      <section className="courser_report spacing">
        <div className="container">
          <div className="report_card_wrap">
            {/* loading component */}
            {cardLoader &&
              countArray(4).map((data) => {
                return (
                  <div className="report_cards" key={data}>
                    <ListCourseSkeleton height={137} />
                  </div>
                );
              })}
            {/* main component */}
            {!cardLoader && (
              <>
                <div className="report_cards">
                  <div className="item">
                    <h5>Time Allocated</h5>
                    <span>
                      {convertMinutesToHoursMinutesSeconds(
                        cardData.totalAllocateTime || 0
                      )}
                    </span>
                  </div>
                </div>
                <div className="report_cards">
                  <div className="item">
                    <h5>Time Consumed</h5>
                    <span>
                      {convertMinutesToHoursMinutesSeconds(
                        cardData.totalConsumeTime || 0
                      )}
                    </span>
                  </div>
                </div>
                <div className="report_cards">
                  <div className="item">
                    <h5>Total Lectures</h5>
                    <span>{cardData.totalLecture}</span>
                  </div>
                </div>
                <div className="report_cards">
                  <div className="item">
                    <h5>Lecture Published</h5>
                    <span>{cardData.totalLecturePublished}</span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="_box">
            <div className="filter_wrap">
              <div className="form_field">
                <label>Date</label>
                <div>
                  <ReactDatePicker
                    selected={date}
                    dateFormat="dd MMM yyyy"
                    placeholderText="Select Date"
                    className="input"
                    onChange={(date) => setDate(date)}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="form_field">
                <label>Platform</label>
                <select
                  className="input text-capitalize"
                  value={platform}
                  onChange={({ target }) => setPlatform(target.value)}
                >
                  <option value="">All</option>
                  {["web", "mobile", "tablet"].map((data) => {
                    return (
                      <option
                        className="text-capitalize"
                        key={data}
                        value={data}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* display section selection if course is on demand */}
              {course.isOnDemand && (
                <div className="form_field">
                  <label>Section</label>
                  <select
                    className="input text-capitalize"
                    value={selectedSection}
                    onChange={({ target }) => setSelectedSection(target.value)}
                  >
                    <option value="">Select</option>
                    {allSections.map(({ SectionID, SectionName }) => {
                      return (
                        <option
                          className="text-capitalize"
                          key={SectionID}
                          value={SectionID}
                        >
                          {SectionName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <button className="dashboard_button" onClick={searchHandler}>
                Search
              </button>
            </div>
            <div className="search_bar_wrap mb-3 d-flex justify-content-end">
              <div className="search_bar">
                <input
                  type="search"
                  placeholder="Search"
                  className="input"
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                />
                <AiOutlineSearch onClick={searchHandler} />
              </div>
            </div>
            <div className="table_wrap">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Lecture Title</th>
                    <th>From Time</th>
                    <th>To Time</th>
                    <th>Total Time</th>
                    <th>Platform</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {/* loading */}
                  {loading && (
                    <tr>
                      <td className="text-center">
                        Loading ...
                      </td>
                       {/* <td colSpan={7} className="text-center">
                        Loading ...
                      </td> */}
                    </tr>
                  )}

                  {/* main data row */}
                  {!loading &&
                    records?.length > 0 &&
                    records.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}.</td>
                          <td>{data.lectureTitle}</td>
                          {/* <td>{timeFormatWith12Hour2(data.lectureStartAt)}</td> */}
                          <td>{timeFormatWith12Hour2(data.updatedAt)}</td>
                          <td>{timeFormatWith12Hour2(data.lectureEndAt)}</td>
                          <td>
                            {convertMinutesToHoursMinutesSeconds(
                              data.TotalPlayedTime
                            )}
                          </td>
                          <td>{data.Platform}</td>
                          <td>{dateFormat(data.createdAt)}</td>
                        </tr>
                      );
                    })}

                  {/* not found row */}
                  {!loading && records?.length <= 0 && (
                    <tr>
                      {/* <td colSpan={7} className="text-center">
                        No records available
                      </td> */}
                      <td className="text-center">
                        No records available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* pagination */}
              {/* {total > 10 ? (
                <div className="pagination_wrap">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    activeLinkClass={"active"}
                    onChange={(page) => setActivePage(page)}
                  />
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CourseReport;
