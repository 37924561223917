import React, { useState } from "react";
import { Nav, Row, Tab } from "react-bootstrap";
import "./CourseListing.scss";
import { Pageimg } from "assets/icons";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";
import { getMasterCourseSubCategories } from "services/master";
import { getAllCourses } from "services/courses/courses";
import { useLanguage } from "context/context";
//import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import CourseCard from "./Section/CourseCard";
import Pagination from "react-js-pagination";
import useDebounce from "hooks/useDebounce";
import CourseNotFound from "components/Not-Found/CourseNotFound";
import { countArray, getLocalConfig, setLocalConfig } from "config/utils";
import CourseListingFilter from "./Section/CourseListingFilter";
import { useNavigate, useParams } from "react-router-dom";
import { errorResponse, failResponse } from "config/config";
import CourseSkeleton from "./Section/CourseSkeleton";

const CourseListing = () => {
  // all type
  const { type, subCategoryId } = useParams();
  const navigate = useNavigate();

  const [showAllSubCategory, setShowAllSubCategory] = useState(true);


  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    id: "",
    name: "",
  });

  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });

  const [selectedExamType, setSelectedExamType] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState([]);

  const [allCourses, setAllCourses] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [courseMenu, setCourseMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  //*********Handel Search************** */
  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search, 300);

  // getting language
  const { language } = useLanguage();

  // --------------- getting all courses ----------------//
  // const getCourses = async () => {
  //   try {
      
  //     setLoading(true);
  //     const courseTypeValues = selectedCourseType.map((data) => data.value);
  //     const res = await getAllCourses({
  //       language,
  //       page: activePage,
  //       examType: selectedExamType.map((data) => data.value),
  //       //courseType: selectedCourseType.map((data) => data.value),
  //       courseType: courseTypeValues,
  //       stateId: selectedVertical.map((data) => data.value),
  //       courseCategory: selectedCategory.id,
  //       CourseSubCategory: selectedSubCategory.id,
  //       search: debounceValue,
  //     });

  //     if (res.code === 200) {
  //       setAllCourses(res.data.courses.rows || []);
  //       setCourseCount(res.data.courses.count || 0);
  //       setLoading(false);
  //     } else {
  //       failResponse(res);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //     setLoading(false);
  //   }
  // };

  React.useEffect(() => {
    const fetchCoursesAndSubCategories = async () => {
      try {
        setLoading(true);
        const courseTypeValues = selectedCourseType.map((data) => data.value);
        const examTypeValues = selectedExamType.map((data) => data.value);
        const courseverticalValues = selectedVertical.map((data) => data.value);
        // Use getAllCourses API to get both courses and subcategories
        const res = await getAllCourses({
          language,
          page: activePage,
          examType: examTypeValues,
          //courseType: selectedCourseType.map((data) => data.value),
          courseType: courseTypeValues,
          stateId: courseverticalValues,
          courseCategory: selectedCategory.id,
          CourseSubCategory: selectedSubCategory.id,
          search: debounceValue,
          courseTypeId: selectedCategory?.id,
          subCategoryId: selectedSubCategory?.id,
        });
  
        if (res.code === 200) {
          const { courses, courseSubCategories } = res.data;
  
          // Update course listing
          setAllCourses(courses.rows || []);
          setCourseCount(courses.count || 0);
  
          // Update subcategories
          const filteredSubCategories = courseSubCategories.rows || [];
          //setAllSubCategory(filteredSubCategories);
          if (Array.isArray(filteredSubCategories)) {
            setAllSubCategory(filteredSubCategories);
          } else {
            console.error("filteredSubCategories is not an array:", filteredSubCategories);
          }


  
          if (type === "course" && subCategoryId) {
            const data = filteredSubCategories.find(
              (data) => data.id === +subCategoryId
            );
  
            let selectedData = {
              id: data?.id,
              name: data?.name,
            };
  
            setSelectedSubCategory(selectedData || {});
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      } finally {
        setLoading(false);
      }
    };
  
    // Call fetchCoursesAndSubCategories whenever selectedCategory or any other relevant dependencies change
    fetchCoursesAndSubCategories();
  }, [selectedCategory, selectedCourseType,selectedExamType,selectedVertical, type,activePage, debounceValue,selectedSubCategory.id]);
  
  

  // reset filter function
  const resetHandler = () => {
    if (type) {
      setSearch("");
      setSelectedCourseType([]);
      setSelectedExamType([]);
      setSelectedVertical([]);
      setSelectedSubCategory({
        id: "",
        name: "",
      });

      setSelectedCategory({
        id: "",
        name: "",
      });
      navigate("/course-listing");
    } else {
      setSearch("");
      setSelectedCourseType([]);
      setSelectedExamType([]);
      setSelectedVertical([]);
      setSelectedSubCategory({
        id: "",
        name: "",
      });

      setSelectedCategory({
        id: "",
        name: "",
      });
    }
  };

  // page on change handler
  const pageOnChangeHandler = (page) => {
    setActivePage(page);
    setLocalConfig("page", page);
  };
  console.log("allSubCategory:", allSubCategory);

  // sub category selection handler
  // const subCategorySelectionHandler = (id, name, courseCategory) => {
  //   console.log("Selected Subcategory:", id, name, courseCategory);
  //   setActivePage(1);
  //   setSelectedSubCategory({
  //     id,
  //     name,
  //   });
  //   setSelectedCategory({
  //     id: courseCategory?.CourseTypeID,
  //     name: courseCategory?.CourseTypeName,
  //   });
  //   setLocalConfig("category", {
  //     id: courseCategory?.CourseTypeID,
  //     name: courseCategory?.CourseTypeName,
  //   });
  //   setLocalConfig("subCategory", {
  //     id,
  //     name,
  //   });
  //   setLocalConfig("page", 1);
  // };


/////////////current//////////////////////////////////////////////////////////////////////
  // Inside the subCategorySelectionHandler function
// const subCategorySelectionHandler = async (id, name, courseCategory) => {
//   setActivePage(1);
//   const selectedData = {
//     id,
//     name,
//   };

//   setSelectedSubCategory(selectedData);
//   setSelectedCategory({
//     id: courseCategory?.CourseTypeID,
//     name: courseCategory?.CourseTypeName,
//   });

//   if (id === "") {
//     // If "All Sub Category" is selected, fetch subcategories only for the selected category
//     try {
//       const res = await getMasterCourseSubCategories({
//         courseTypeId: courseCategory?.CourseTypeID,
//       });

//       if (res.code === 200) {
//         setAllSubCategory(res.data.courseSubCategories || []);
//       } else {
//         failResponse(res);
//       }
//     } catch (err) {
//       errorResponse(err);
//     }
//   }

//   // Update the URL here
//   navigate(`/course-listing/course/${selectedCategory?.name}/${selectedCategory?.id}/${name}/${id}`);
//   setLocalConfig("category", {
//     id: courseCategory?.CourseTypeID,
//     name: courseCategory?.CourseTypeName,
//   });
//   setLocalConfig("subCategory", selectedData);
//   setLocalConfig("page", 1);
//   setShowAllSubCategory(id === "");
// };





const subCategorySelectionHandler = async (id, name, courseCategory) => {
  setActivePage(1);

  const selectedData = {
    id,
    name,
  };

  setSelectedSubCategory(selectedData);
  setSelectedCategory({
    id: courseCategory?.CourseTypeID,
    name: courseCategory?.CourseTypeName,
  });

  if (id === "") {
    // If "All Sub Category" is selected, fetch subcategories only for the selected category
    try {
      const res = await getAllCourses({

        language,
        page: activePage,
        examType: selectedExamType.map((data) => data.value),
        courseType: selectedCourseType.map((data) => data.value),
        stateId: selectedVertical.map((data) => data.value),
        courseCategory: selectedCategory.id,
        CourseSubCategory: selectedSubCategory.id,
        search: debounceValue,


        courseTypeId: courseCategory?.CourseTypeID,
      });

      if (Array.isArray(res.data.courseSubCategories)) {
        setAllSubCategory(res.data.courseSubCategories || []);
      } else {
        console.error("courseSubCategories is not an array:", res.data.courseSubCategories);
      }
    } catch (err) {
      errorResponse(err);
    }
  }

   
  console.log("Debugging - courseCategory:", courseCategory);

  if (courseCategory && courseCategory.CourseTypeID !== 1 && courseCategory.CourseTypeID !== 2 && courseCategory.CourseTypeID !== 3 && courseCategory.CourseTypeID !== 4 && courseCategory.CourseTypeID !== 5 && courseCategory.CourseTypeID !== 6 && courseCategory.CourseTypeID !== 7 && courseCategory.CourseTypeID !== 8 && courseCategory.CourseTypeID !== 9) {
    console.log("Navigate to specific URL");
    //navigate(`/course-listing/course`);
    navigate(`/course-listing/course/${selectedCategory?.name}/${selectedCategory?.id}/${name}/${id}`);
    
    //navigate(`/course-listing/course`);
  } else {
    console.log("Navigate to default URL");
    navigate(`/course-listing/course`);
  }
  




  setLocalConfig("category", {
    id: courseCategory?.CourseTypeID,
    name: courseCategory?.CourseTypeName,
  });
  setLocalConfig("subCategory", selectedData);
  setLocalConfig("page", 1);
  setShowAllSubCategory(id === "");
};
















/////////////////////////////////////////////////////////////////////////////


   
  
  useEffect(() => {
    document.body.classList.toggle("subjectMenu", courseMenu);
  }, [courseMenu]);



  //   subcategory dependent filter
  // get sub categories
    // const getSubCategory = async () => {
    //   try {
    //     const res = await getMasterCourseSubCategories({
    //       courseTypeId: selectedCategory?.id,
    //     });

    //     if (res.code === 200) {
    //       setAllSubCategory(res.data.courseSubCategories || []);
    //       if (type === "course" && subCategoryId) {
    //         const data = res.data.courseSubCategories?.find(
    //           (data) => data.id === +subCategoryId
    //         );

    //         let selectedData = {
    //           id: data?.id,
    //           name: data?.name,
    //         };

    //         setSelectedSubCategory(selectedData || {});
    //       }
    //     } else {
    //       failResponse(res);
    //     }
    //   } catch (err) {
    //     errorResponse(err);
    //   }
    // };




  // React.useEffect(() => {
  //   setActivePage(1);
    

  // //   ///////////////////////////////////////////////////////////
  //   const getSubCategory = async () => {
  //     try {
  //       let res;
  //       if (showAllSubCategory) {
  //         // If "All Sub Category" is selected, fetch subcategories for the selected category
  //         res = await getMasterCourseSubCategories({
  //           courseTypeId: selectedCategory?.id,
  //         });
  //       } else {
  //         // If a specific subcategory is selected, fetch only that subcategory
  //         res = await getMasterCourseSubCategories({
  //           courseTypeId: selectedCategory?.id,
  //           subCategoryId: selectedSubCategory?.id,
  //         });
  //       }
    
  //       if (res.code === 200) {
  //         setAllSubCategory(res.data.courseSubCategories || []);
  //         if (type === "course" && subCategoryId) {
  //           const data = res.data.courseSubCategories?.find(
  //             (data) => data.id === +subCategoryId
  //           );
    
  //           let selectedData = {
  //             id: data?.id,
  //             name: data?.name,
  //           };
    
  //           setSelectedSubCategory(selectedData || {});
  //         }
  //       } else {
  //         failResponse(res);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //     }
  //   };
    
  //   getSubCategory();
  //   // eslint-disable-next-line
  // }, [selectedCategory]);



  ///////////////////////////////////////////////////////////////////////
  // React.useEffect(() => {
  //   setActivePage(1);
  // const getSubCategory = async () => {
  //   try {
  //     let res;
  
  //     // Use getAllCourses API to get subcategories
  //     if (showAllSubCategory) {
  //       res = await getAllCourses({
  //         language,
  //         page: activePage,
  //         examType: selectedExamType.map((data) => data.value),
  //         courseType: selectedCourseType.map((data) => data.value),
  //         stateId: selectedVertical.map((data) => data.value),
  //         courseCategory: selectedCategory.id,
  //         CourseSubCategory: selectedSubCategory.id,
  //         search: debounceValue,
          
  //         courseTypeId: selectedCategory?.id,
  //       });
  //     } else {
  //       res = await getAllCourses({

  //         language,
  //         page: activePage,
  //         examType: selectedExamType.map((data) => data.value),
  //         courseType: selectedCourseType.map((data) => data.value),
  //         stateId: selectedVertical.map((data) => data.value),
  //         courseCategory: selectedCategory.id,
  //         CourseSubCategory: selectedSubCategory.id,
  //         search: debounceValue,

  //         courseTypeId: selectedCategory?.id,
  //         subCategoryId: selectedSubCategory?.id,
  //       });
  //     }
     
  //     if (res.code === 200) {
  //       const courseSubCategories = res.data.courseSubCategories.rows || [];
  //       setAllSubCategory(courseSubCategories);
  //       console.log("allsubcategoriesss",courseSubCategories);
  
  //       if (type === "course" && subCategoryId) {
  //         const data = courseSubCategories.find(
  //           (data) => data.id === +subCategoryId
  //         );
  
  //         let selectedData = {
  //           id: data?.id,
  //           name: data?.name,
  //         };
  
  //         setSelectedSubCategory(selectedData || {});
  //       }
  //     } else {
  //       failResponse(res);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //   }
  // };
  
 
    
  //   getSubCategory();
  //   // eslint-disable-next-line
  // }, [selectedCategory,selectedCourseType]);
  
  



  // get course according to filter
  React.useEffect(() => {
    //getCourses();
    //fetchCoursesAndSubCategories();
    // eslint-disable-next-line
  }, [
    language,
    activePage,
    selectedSubCategory,
    selectedCategory,
    selectedExamType,
    selectedVertical,
    selectedCourseType,
    debounceValue,
    type,
    subCategoryId,
  ]);

  // set active page by use param page
  React.useEffect(() => {
    const page = getLocalConfig("page");
    const category = getLocalConfig("category");

    const subCategory = getLocalConfig("subCategory");

    // set local config data t state
    page && setActivePage(page);
    category?.id &&
      setSelectedCategory({ id: category?.id, name: category?.name });
    subCategory?.id &&
      setSelectedSubCategory({ id: subCategory?.id, name: subCategory?.name });
  }, []);
  

  return (
    <section className="course_listing_section">
      <div className="container">
        <div className="title spacing">
          <h3>View Our Courses</h3>
        </div>

        <div className="course_listing">
          <Tab.Container id="left-tabs-example" defaultActiveKey="all-courses">
            {/* Course Listing Filter */}
            <CourseListingFilter
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedExamType={selectedExamType}
              setSelectedExamType={setSelectedExamType}
              selectedVertical={selectedVertical}
              setSelectedVertical={setSelectedVertical}
              selectedCourseType={selectedCourseType}
              setSelectedCourseType={setSelectedCourseType}
              courseMenu={courseMenu}
              setCourseMenu={setCourseMenu}
              resetHandler={resetHandler}
            />

            {/* Course listing */}
            <div className="currrent_affairs_card">
              <Tab.Content>
                <Tab.Pane eventKey="all-courses">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Sociology"
                  >
                    <Row>
                      <div className="courseside_nav">
                        <h5>COURSES BY SUB CATEGORY</h5>
                        <Nav
                          variant="pills"
                          className="flex-column"
                          onClick={() => {
                            setCourseMenu(false);
                          }}
                        >
                          <Nav.Item>
                            <Nav.Link
                              className={`${
                                selectedSubCategory.id === "" && "active"
                              }`}
                              onClick={() => {
                                subCategorySelectionHandler("", "", {
                                  CourseTypeID: "",
                                  CourseTypeName: "",
                                });
                                navigate("/course-listing");
                              }}
                            >
                              <img src={Pageimg} alt="" loading="lazy" />
                              All Sub Category
                            </Nav.Link>
                          </Nav.Item>
                          {allSubCategory &&
                            allSubCategory.map(
                              ({ id, name, courseCategory }) => {
                                return (
                                  <Nav.Item key={id}>
                                    <Nav.Link
                                      className={`${
                                        selectedSubCategory.id === id &&
                                        "active"
                                      }`}
                                      onClick={() =>
                                        subCategorySelectionHandler(
                                          id,
                                          name,
                                          courseCategory
                                        )
                                      }
                                      
                                    >
                                      <img
                                        src={Pageimg}
                                        alt=""
                                        loading="lazy"
                                      />
                                      <span>{name}</span>
                                    </Nav.Link>
                                  </Nav.Item>
                                );
                              }
                            )}
                        </Nav>
                      </div>

                      <div className="course_section">
                        <Tab.Content>
                          <Tab.Pane eventKey="Sociology">
                            <div className="title_bar">
                              <div className="title">
                                <h5>
                                  {selectedCategory.id
                                    ? selectedCategory.name
                                    : "All Courses"}
                                </h5>
                                <span>{courseCount || 0} Courses</span>
                              </div>
                              <div className="search_bar">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="input"
                                  value={search}
                                  onChange={({ target }) =>
                                    setSearch(target.value)
                                  }
                                />
                                <AiOutlineSearch />
                              </div>
                            </div>
                            <div className="course_card_section">
                              <div className="card_wrapper">
                                {/************** loading components **************/}
                                {loading && (
                                  <>
                                    {countArray(6).map((data) => {
                                      return <CourseSkeleton key={data} />;
                                    })}
                                  </>
                                )}

                                {/************** not found components **************/}
                                {!loading && allCourses.length <= 0 && (
                                  <CourseNotFound title="Course" />
                                )}

                                {/************** main components **************/}
                                {!loading && allCourses.length > 0 && (
                                  <>
                                    {allCourses.map((course) => {
                                      return (
                                        <CourseCard
                                          key={course.CourseID}
                                          course={course}
                                          isOnDemand={course.isOnDemand}
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                              {/* pagination */}
                              {courseCount > 10 ? (
                                <div className="pagination_wrap">
                                  <Pagination
                                    activePage={activePage}
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    itemsCountPerPage={10}
                                    totalItemsCount={courseCount}
                                    pageRangeDisplayed={5}
                                    activeLinkClass={"active"}
                                    onChange={(page) =>
                                      pageOnChangeHandler(page)
                                    }
                                  />
                                </div>
                              ) : null}
                              
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
};
export default CourseListing;
