import React from "react";
import { Placeholder } from "react-bootstrap";

const CourseSkeleton = () => {
  return (
    <div className="listing_card">
      <div className="image_wrap bg-transparent">
        <Placeholder
          as="div"
          animation="wave"
          aria-hidden="true"
          className="h-100"
        >
          <Placeholder xs={12} className="rounded h-100" />
        </Placeholder>
      </div>
      <div className="card_content">
        <h5>
          <Placeholder as="div" animation="wave" aria-hidden="true">
            <Placeholder xs={4} className="w-100" style={{ height: `20px` }} />
          </Placeholder>
        </h5>
        <ul>
          <li className="w-50 mx-2">
            <Placeholder
              as="div"
              animation="wave"
              aria-hidden="true"
              className="w-100"
            >
              <Placeholder
                xs={4}
                className="w-100"
                style={{ height: `10px` }}
              />
            </Placeholder>
          </li>
          <li className="w-50 mx-2">
            <Placeholder
              as="div"
              animation="wave"
              aria-hidden="true"
              className="w-100"
            >
              <Placeholder
                xs={4}
                className="w-100"
                style={{ height: `10px` }}
              />
            </Placeholder>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseSkeleton;
