import React from "react";

const Tooltip = ({ children, message = "", disabled = false }) => {
  return !disabled ? (
    <span class="tooltip-toggle" aria-label={message} tabindex="0">
      {children}
    </span>
  ) : (
    children
  );
};

export default Tooltip;
