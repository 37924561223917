import KYCModal from "components/modal-box/KYCModal"
import React, { useState } from "react";
import "./KYCPopup.scss";
import { errorResponse, failResponse } from "config/config";
import { getAdvertisementData } from "services/home";
import {kycpopup} from "../../assets/images"
import { Link } from "react-router-dom";


const KYCPopup = () => {
  const [popup, setPopup] = useState(true);
  // const [value, setValue] = useCookies(["registered"]);
  const [advertisementData, setAdvertisementData] = useState({
    image: "",
  });

  
  // let nextDay = new Date();
  // let today = new Date();

  // nextDay.setDate(today.getDate() + 1);

 

  const handleLinkClick = () => {
    setPopup(false);
  };


  return (
    <>
      <KYCModal
        className="kyc_wrapper"
        show={popup}
        handleClose={() => setPopup(false)}
        size="lg"
      >
        <div className="kyc">
          <div className="image_wrap" style={{height:'100%'}}>
            <img src={kycpopup} alt="kyc" className="img" style={{width:"100%"}} />
            <center><div className="button_overlay">
            <Link to={`/dashboard/update-kyc-details`} className="m-0">
            <button style={{ backgroundColor: '#dd5d59', color: '#fff', width:"96px", height:"32px", borderRadius:"4px", border:"4px solid #dd5d59", fontFamily:"inherit", fontSize:"15px" }} onClick={handleLinkClick}>Update</button>
            </Link>
                </div>
                </center>
          </div>
          {/* <div className="detail_wrap">
              <h3>kyc to Shubra Ranjan</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate ipsam error iure esse, ea impedit laboriosam modi
                necessitatibus maxime ipsa possimus libero! Quod facilis amet
                quibusdam nisi commodi! Dolores, quam!
              </p>
            </div> */}
        </div>
      </KYCModal>
    </>
  );
};

export default KYCPopup;
