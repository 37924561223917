import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Calendarimg, Teacherimg } from "assets/icons";
import "./DemoLacture.scss";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { countArray } from "config/utils";
import VideoPlayModal from "components/video-play-modal/VideoPlayModal";
import { vdoCipherGenerateOtpWOAuth } from "services/lecture";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import VideoCipherPlayer from "components/video-player/VdoCipherPlayer";
import { errorResponse, failResponse } from "config/config";

const DemoLacture = ({ lectures = [], facultyName, loading }) => {
  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoType, setVideoType] = useState("");
  const [cipherLoading, setCipherLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");

  // generate otp of vdocipher
  const videoPlayHandler = async (file, videoTypeParam) => {
    setSelectedVideo(file);
    setVideoType(videoTypeParam);
    if (!videoTypeParam) {
      try {
        setCipherLoading(true);
        const res = await vdoCipherGenerateOtpWOAuth({
          video_id: file,
        });

        if (res.code === 200) {
          setOtp(res.data.otp || "");
          setPlaybackInfo(res.data.playbackInfo || "");
          setPlayVideoModal(true);
          setCipherLoading(false);
        } else {
          failResponse(res);
          setCipherLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setCipherLoading(false);
      }
    }

    if (videoTypeParam) {
      setPlayVideoModal(true);
    }
  };

  return (
    <div className="demo_lacture spacing">
      <div className="container">
        <div className="page_title">
          <h3>Demo Lectures</h3>
          <p>
            Get a clear understanding on our unique teaching methods and watch
            demos of experienced faculty conducting classes in the different
            modes offered by us
          </p>
        </div>

        <div className="lacture_card_wrap">
          {/************** loading component **************/}
          {loading &&
            countArray(3).map((data) => {
              return (
                <div className="lacture_card tablet p-0" key={data}>
                  <ListCourseSkeleton height="120" />
                </div>
              );
            })}

          {/************** not found component **************/}
          {!loading && lectures.length <= 0 && (
            <h5 className="text-center w-100">No Lectures Available</h5>
          )}

          {/************** main component **************/}
          {!loading &&
            lectures.length > 0 &&
            lectures.map(
              ({ LectureID, LectureTitle, Duration, IsYoutube, VideoFile }) => {
                return (
                  <div className="lacture_card tablet" key={LectureID}>
                    <div className="content">
                      <h5>{LectureTitle}</h5>
                      {/* <span className="tag_name">Static</span> */}
                      <ul>
                        <li>
                          <img src={Calendarimg} alt="" loading="lazy" />
                          {`${Duration} min demo`}
                        </li>
                        <li>
                          <img src={Teacherimg} alt="" loading="lazy" />{" "}
                          {facultyName}
                        </li>
                      </ul>
                    </div>
                    <div className="button_wrap">
                      {/* video play button */}
                      {VideoFile === selectedVideo ? (
                        cipherLoading ? (
                          <button type="button" className="button">
                            <div
                              class="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span class="sr-only"></span>
                            </div>
                          </button>
                        ) : (
                          <button
                            className="button"
                            onClick={() => {
                              videoPlayHandler(VideoFile, IsYoutube);
                            }}
                            disabled={!VideoFile}
                          >
                            View
                            <span className="icon">
                              <BsArrowRight />
                            </span>
                          </button>
                        )
                      ) : (
                        <button
                          className="button"
                          onClick={() => {
                            videoPlayHandler(VideoFile, IsYoutube);
                          }}
                          disabled={!VideoFile}
                        >
                          View
                          <span className="icon">
                            <BsArrowRight />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>

      {/* Youtube Video Player Modal */}
      <VideoPlayModal
        modalShow={playVideoModal}
        modalHide={() => setPlayVideoModal(false)}
      >
        {/* Youtube Player */}
        {videoType && selectedVideo && (
          <>
            <YoutubeVideo src={selectedVideo} width="100%" height="400" />
          </>
        )}

        {/* Vdocipher Player */}
        {!videoType && selectedVideo && (
          <>
            {playbackInfo && otp && (
              <VideoCipherPlayer otp={otp} playbackInfo={playbackInfo} />
            )}
          </>
        )}
      </VideoPlayModal>
    </div>
  );
};

export default DemoLacture;
