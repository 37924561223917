import React from "react";
// import "./DailyQuiz.scss";

const QuizResult = ({ showResult, attemptedAnswers = [] }) => {
  const [rightAnswers, setRightAnswers] = React.useState([]);
  const [wrongAnswers, setWrongAnswers] = React.useState([]);

  //   find right and wrong answer
  React.useEffect(() => {
    const rightAnswersArr = [];
    const wrongAnswersArr = [];
    attemptedAnswers.forEach((question) => {
      if (question.correctOption === question.attemptAnswer) {
        rightAnswersArr.push(question);
      } else {
        wrongAnswersArr.push(question);
      }
    });
    setRightAnswers(rightAnswersArr);
    setWrongAnswers(wrongAnswersArr);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="quiz_result questions">
      <h5>Quiz Result</h5>
      <h4>Thank you for participating in the Quiz</h4>

      <ul className="summery_result">
        <li>Your result is a below:</li>
        <li>
          <span className="box_green"></span>
          {rightAnswers?.length} out of {attemptedAnswers?.length} is correct
        </li>
        <li>
          <span className="box_red"></span>
          {wrongAnswers?.length} out of {attemptedAnswers?.length} are wrong
        </li>
      </ul>
      <div className="button_wrap padding">
        <button type="button" className="button" onClick={showResult}>
          View results
        </button>
      </div>
    </div>
  );
};
export default QuizResult;
