import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./payment-msg.scss";
import { FailedImg } from "assets/images";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useLayoutEffect(() => {
    if (!location?.state?.paymentStatus) {
      navigate("/dashboard/home");
    }
  }, [navigate, location]);

  return (
    <>
      <div className="payment_status spacing failed">
        <div className="content">
          <div>
            {/* <div className="icon">
            <BsFillPatchCheckFill />
          </div> */}
            <div className="image_wrap">
              <img src={FailedImg} alt="payment failed" loading="lazy" />
            </div>
            <div className="text-center">
              <h3>Payment failed !</h3>
              <p>We are not able to process your payment. please try again. </p>
              <Link to="/dashboard/home" className="button">
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
