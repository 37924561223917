import React from "react";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { Successimg } from "assets/icons";
import "./Form.scss";
import usePasswordToggle from "hooks/usePasswordToggle";
import { Input } from "components/Form";
import { toast } from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import { resetPassword } from "services/auth";
import { errorResponse, failResponse } from "config/config";

const ResetPassword = () => {
  const { token } = useParams();
  const [type, icon] = usePasswordToggle();
  const [type2, icon2] = usePasswordToggle();
  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Continue", true);

  const [cred, setCred] = useState({ password: "", confirmPassword: "" });
  const [restPassword, setResetPassword] = useState(false);

  const [errorField, setErrorField] = useState({
    password: "",
    confirmPassword: "",
  });

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!cred.password) {
      errorField["password"] = "Please Enter Password";
      formIsValid = false;
    }

    if (!cred.confirmPassword) {
      errorField["confirmPassword"] = "Please Enter Confirm Password";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  // onchange handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setCred({ ...cred, [name]: value });
  };

  // reset password handler
  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (cred.password !== cred.confirmPassword) {
        toast.error("password and confirm password must be the same");
        return;
      }
      setSubmitLoading(true);
      try {
        const res = await resetPassword({ ...cred, key: token });

        if (res.code === 200) {
          toast.success(res.message);
          setSubmitLoading(false);
          setResetPassword(true);
        } else {
          failResponse(res);
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    }
  };

  return (
    <div className="login forget_password spacing">
      <div className="form_container">
        <div className="content_section">
          <div className="form_section">
            {restPassword === true ? (
              <>
                <div className="otp_verified">
                  <img src={Successimg} alt="success" loading="lazy" />
                  <h3>Password reset !</h3>
                  <p className="subtitle">
                    Your password has been reset. Click below to log in to your
                    account.
                  </p>
                </div>
                <div className="button_wrap">
                  <Link
                    to="/login"
                    onClick={() => setResetPassword(true)}
                    className="button"
                  >
                    Sign In
                    <span className="icon">
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="form_title">
                  <h3>Reset Your Password</h3>
                  <p>Create a new password</p>
                </div>
                <form className="form" onSubmit={resetPasswordHandler}>
                  <div className="form_field has_icon">
                    <Input
                      type={type}
                      name="password"
                      value={cred.password || ""}
                      onChange={onChangeHandler}
                      placeholder="Enter password"
                      className={`input form-control d-inline `}
                      maxLength={50}
                      label={"Password"}
                      error={errorField.password}
                      required
                    />
                    <span className="eye_icon open">{icon}</span>
                  </div>

                  <div className="form_field has_icon">
                    <Input
                      type={type2}
                      name="confirmPassword"
                      value={cred.confirmPassword || ""}
                      onChange={onChangeHandler}
                      placeholder="Enter confirm password"
                      className={`input form-control d-inline `}
                      maxLength={50}
                      label={"Confirm Password"}
                      error={errorField.confirmPassword}
                      required
                    />
                    <span className="eye_icon open">{icon2}</span>
                  </div>

                  <div className="button_wrap">
                    <button
                      type="submit"
                      className="button"
                      ref={submitButtonRef}
                    >
                      Continue
                      <span className="icon">
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
