import BannerSkeleton from "components/skeleton/BannerSkelaton";
import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

import Slider from "react-slick";

const HomeSlider = ({ banners }) => {
  ////////Slider//////
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: "linear",
    lazyLoad: true,
  };

  return (
    <>
      <div className="home_banner">
        <Slider {...settings}>
          {banners && banners.length > 0 ? (
            banners.map(
              ({ banner, descriptionOne, descriptionTwo, bannertitle, bannerurl, bannerid }, i) => {
                const url = bannerurl ? bannerurl : "/course-listing";
                return (
                  <div className="animated_banner" key={i}>
                    <div className="container">
                      <div className="image_section">
                        <div className="image_wrap" key={bannerid}>
                          <Link to={url} className="d-block">
                            <img src={banner} alt="Slider" />
                          </Link>
                        </div>
                      </div>
                      <div className="content_section">
                        <span className="text">{descriptionOne || "N/A"}</span>
                        <h1>
                          <Link style={{ color: "#181822" }} to={url}>
                            {bannertitle || "N/A"}
                          </Link>
                        </h1>
                        <p>{descriptionTwo || "N/A"}</p>
                        <div className="button_wrap">
                          <Link to={url} className="button">
                            View course(s)
                            <span className="icon">
                              <BsArrowRight />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <BannerSkeleton />
          )}
        </Slider>
      </div>
    </>
  );
};

export default HomeSlider;
