import VideoPlayModal from "components/video-play-modal/VideoPlayModal";
import VideoCipherPlayer from "components/video-player/VdoCipherPlayer";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import {
  lectureAccess,
  lectureIsExpired,
  lectureIsStarted,
} from "config/utils";
import { useCourse } from "context/context";
import useButtonLoader from "hooks/useButtonLoader";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { vdoCipherGenerateOtp } from "services/lecture";
import { getSignedUrl } from "services/master";

const Notification = ({
  notificationData,
  handleClose,
  deleteNotification,
}) => {
  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoType, setVideoType] = useState("");
  const [cipherLoading, setCipherLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");

  const { setCourse } = useCourse();
  const navigate = useNavigate();

  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Download");

  // generate otp of vdocipher
  const videoPlayHandler = async (file, videoTypeParam) => {
    setSelectedVideo(file);
    setVideoType(videoTypeParam);
    if (!videoTypeParam) {
      try {
        setCipherLoading(true);
        const res = await vdoCipherGenerateOtp({
          video_id: file,
        });

        if (res.code === 200) {
          setOtp(res.data.otp || "");
          setPlaybackInfo(res.data.playbackInfo || "");
          setPlayVideoModal(true);
          setCipherLoading(false);
        } else {
          failResponse(res);
          setCipherLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setCipherLoading(false);
      }
    }

    if (videoTypeParam) {
      setPlayVideoModal(true);
    }
  };

  // lecture video selection
  const videoSelectionHandler = (notificationData) => {
    const {
      courseId,
      courseName,
      coursePlatformEnabled,
      isOnDemand,
      isGps,
      isLectureAvailable,
      isTestAvailable,
      lecture,
    } = notificationData;
    setCourse({
      courseId,
      courseName,
      isOnDemand: isOnDemand === "Y" ? true : false,
      isGps,
      coursePlatformEnabled,
      isLectureAvailable,
      isTestAvailable,
    });
    if (!coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (lectureIsStarted(lecture.dateScheduled, lecture.lecturetime)) {
      toast.error("Lecture is not started");
      return;
    }
    if (lectureIsExpired(lecture.lectureenddate, lecture.lecturetime)) {
      toast.error("Lecture is expired");
      return;
    }
    if (
      lectureAccess(
        lecture.dateScheduled,
        lecture.lectureenddate,
        lecture.lecturetime
      )
    ) {
      navigate(
        `/dashboard/lecture/detail/${normalEncryptData(
          lecture?.lectureid,
          "route"
        )}`,
        {
          state: { auth: true },
        }
      );
      handleClose();
    }
  };

  const pdfDownloader = async (url) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: url }));
    setSubmitLoading(false);
  };

  return (
    <li className="my-3">
      <div className="notification">
        {/* {!notificationData.isseen && (
          <div className="ping">
            <span className="heartbeat"></span>
            <span className="dot"></span>
          </div>
        )} */}
        <span className="del_notification" onClick={deleteNotification}>
          <FaTimes />
        </span>
        <span>
          <h6>{notificationData.title}</h6>
          <p>{notificationData.description}</p>
        </span>

        {/* if type is lecture video & for all */}
        {+notificationData.type === 2 && +notificationData.sendTo === 1 && (
          <div className="text-center">
            {notificationData.lecture?.VideoFile === selectedVideo ? (
              cipherLoading ? (
                <button className="dashboard_button">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                </button>
              ) : (
                <button
                  className="dashboard_button"
                  onClick={() => {
                    videoPlayHandler(
                      notificationData.lecture?.VideoFile,
                      notificationData.lecture?.IsYoutube
                    );
                  }}
                  disabled={!notificationData.lecture?.VideoFile}
                >
                  View Video
                </button>
              )
            ) : (
              <button
                className="dashboard_button"
                onClick={() => {
                  videoPlayHandler(
                    notificationData.lecture?.VideoFile,
                    notificationData.lecture?.IsYoutube
                  );
                }}
                disabled={!notificationData.lecture?.VideoFile}
              >
                View Video
              </button>
            )}
          </div>
        )}

        {/* if type is lecture video & for course wise */}
        {+notificationData.type === 2 && +notificationData.sendTo === 2 && (
          <div className="text-center">
            <button
              className="dashboard_button"
              onClick={() => {
                videoSelectionHandler(notificationData);
              }}
            >
              View Lecture
            </button>
          </div>
        )}

        {/* if type is pdf */}
        {+notificationData.type === 3 && (
          <div className="text-center">
            <button
              className="dashboard_button"
              ref={submitButtonRef}
              onClick={() => pdfDownloader(notificationData.pdfUrl)}
            >
              Download PDF
            </button>
          </div>
        )}

        {/* if type is youtube video */}
        {+notificationData.type === 4 && (
          <div className="text-center">
            <button
              className="dashboard_button"
              onClick={() => window.open(notificationData.videoid)}
            >
              View Video
            </button>
          </div>
        )}
      </div>
      {/* Youtube Video Player Modal */}
      <VideoPlayModal
        modalShow={playVideoModal}
        modalHide={() => setPlayVideoModal(false)}
      >
        {/* Youtube Player */}
        {videoType && selectedVideo && (
          <>
            <YoutubeVideo src={selectedVideo} width="100%" height="400" />
          </>
        )}

        {/* Vdocipher Player */}
        {!videoType && selectedVideo && (
          <>
            {playbackInfo && otp && (
              <VideoCipherPlayer otp={otp} playbackInfo={playbackInfo} />
            )}
          </>
        )}
      </VideoPlayModal>
    </li>
  );
};

export default Notification;
