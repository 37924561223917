import React from "react";
import { Link } from "react-router-dom";
import { Successimg } from "assets/icons";
import { BsArrowRight } from "react-icons/bs";

const Success = () => {
  return (
    <>
      <>
        <div className="otp_verified">
          <img src={Successimg} alt="success" loading="lazy" />
          <h3>YOU HAVE SUCCESSFULLY SIGNED IN!</h3>
          <p>
            You are now one step closer to learning & training with the best
            faculty in the country
          </p>
        </div>
        <div className="button_wrap">
          <Link to="" className="button">
            Enter Website
            <span className="icon">
              <BsArrowRight />
            </span>
          </Link>
        </div>
      </>
    </>
  );
};

export default React.memo(Success);
