import React, { useState } from "react";
import { Navigate, useNavigate, Outlet, Route, Routes } from "react-router-dom";
import { isLoggedIn } from "config/utils";
import Chart from "react-google-charts";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  Aboutimg1,
  Aboutimg2,
  Aboutimg3,
  Virajimg,
  Shubhraimg,
  Onlineimg,
  ClassRoomImg,
  Regionalimg, EvolvingwithTime, StudentCentricApproach, IntegratedLearning,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
} from "assets/images";
import { FaPlay } from "react-icons/fa";
import OurLocation from "components/our-location/OurLocation";
import { getMasterCenters } from "services/master";
import { errorResponse, failResponse, normalEncryptData } from "config/config";

const Offline = () => {
  const navigate = useNavigate();

  if (!isLoggedIn()) {
    localStorage.setItem("utoken","utoken");
    return <Navigate to="/login" replace />;
  } else {
    return <Navigate to="/my-token" replace />;;
  }

  return (
    <div className="ourStory">
     
    </div>
  );
};

export default Offline;
