import { useCourse } from "context/context";
import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import OnDemandSectionA from "./section/OnDemandSectionA";
import { getCourseSectionList } from "services/lecture";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { charLimit, countArray } from "config/utils";
import { useParams } from "react-router-dom";
import { errorResponse, failResponse } from "config/config";
import OnDemandUpcomingList from "./section/OnDemandUpcomingList";
import OnDemandViewedList from "./section/OnDemandViewedList";
import { Link, useNavigate } from "react-router-dom";
import { BiBorderRadius } from "react-icons/bi";

const styles = {
  navLink: {
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '8px',
    height: '40px',
  },
  active: {
    backgroundColor:'#DD5D59',
    color: '#fff',
    borderradius:"8px",
  },
  inactive: {
    backgroundColor: '#fff',
    color: '#000',
    border:"1px solid",
    borderColor:"#b6c6e0",

  },
};
// const styles = {
//   navLink: {
//     padding: '10px 20px',
//     cursor: 'pointer',
//     borderRadius: '8px',
//     height: '40px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   active: {
//     backgroundColor: '#DD5D59',
//     color: '#fff',
//   },
//   inactive: {
//     backgroundColor: '#fff',
//     color: '#000',
//   },
// };

const OnDemandSection = () => {
  const navigate = useNavigate();

  const { course } = useCourse();
  const { sectionId } = useParams();

  const [allSections, setAllSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState({
    SectionID: "",
    SectionName: "",
    SectionDescription: "",
  });
  const [loading, setLoading] = useState(false);

  // get course lectures
  const getSectionsList = async () => {
    try {
      setLoading(true);

      const res = await getCourseSectionList({ course_id: course.courseId });

      if (res.code === 200) {
        setAllSections(res.data.sections || []);
        if (sectionId) {
          const findData = res.data.sections.find(
            (data) => data.SectionID === sectionId
          );
          setSelectedSection(findData || res.data.sections[0]);
        } else {
          setSelectedSection(
            res.data.sections[0] || {
              SectionID: "",
              SectionName: "",
              SectionDescription: "",
            }
          );
        }

        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useLayoutEffect(() => {
    if (course.courseId) {
      getSectionsList();
    }
    // eslint-disable-next-line
  }, [course]);

  const [selectedView, setSelectedView] = useState('normalList');


  return (
    <>
      <div className="lecture_content spacing">
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="List_view">
         
            <div className="dashboard_tab">
              {/* all sections listing */}
              <Nav variant="pills">
                {/* loading component */}
                {loading && (
                  <>
                    {countArray(5).map((num) => {
                      return (
                        <Nav.Item key={num}>
                          <Nav.Link className="p-0">
                            <CourseSkeleton height={45} width={"22vh"} />
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </>
                )}

                {/* main component */}
                {!loading &&
                  allSections?.length > 0 &&
                  allSections.map(
                    ({ SectionID, SectionName, SectionDescription }) => {
                      return (
                        <Nav.Item key={SectionID}>
                          <Nav.Link
                            className={
                              selectedSection?.SectionID === SectionID &&
                              "active"
                            }
                            eventKey={SectionID}
                            onClick={() =>
                              setSelectedSection({
                                SectionID,
                                SectionName,
                                SectionDescription,
                              })
                            }
                          >
                            {charLimit(SectionName, 15)}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    }
                  )}
              </Nav>

               &nbsp;&nbsp;
            {/* <Nav variant="pills">
            <ul style={{marginLeft:"52px"}}>
           
           <li></li>
              <li>
              <Link 
               onClick={() => setSelectedView('normalList')}><h6>Ongoing</h6>
 
              </Link>
              &nbsp;&nbsp; &nbsp;&nbsp;

              <Link 
               onClick={() => setSelectedView('upcomingList')}><h6>Upcoming</h6>
 
              </Link>
              &nbsp;&nbsp; &nbsp;&nbsp;
              <Link 
                 onClick={() => setSelectedView('viewedList')}><h6>Viewed</h6>
 
               </Link>
              </li>
            </ul>
            </Nav> */}
             <Nav variant="pills">
        <ul style={{ marginLeft: '52px' }}>
          <li>
            <Link
              to="#"
              onClick={() => setSelectedView('normalList')}
              style={{
                ...styles.navLink,
                ...(selectedView === 'normalList' ? styles.active : styles.inactive),
              }}
            >
              <h8>Ongoing</h8>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              to="#"
              onClick={() => setSelectedView('upcomingList')}
              style={{
                ...styles.navLink,
                ...(selectedView === 'upcomingList' ? styles.active : styles.inactive),
              }}
            >
              <h8>Upcoming</h8>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              to="#"
              onClick={() => setSelectedView('viewedList')}
              style={{
                ...styles.navLink,
                ...(selectedView === 'viewedList' ? styles.active : styles.inactive),
              }}
            >
              <h8>Viewed</h8>
            </Link>
          </li>
        </ul>
      </Nav>


              {/* overview screen */}
              {/* {selectedSection.SectionID && selectedView !== 'viewedList' && selectedView !== 'upcomingList' && ( */}
              {selectedView === 'normalList' && (

              <OnDemandSectionA
                courseId={course?.courseId}
                sectionId={selectedSection.SectionID}
                sectionName={selectedSection.SectionName}
                sectionDescription={selectedSection.SectionDescription}
              />
            )}

            {selectedView === 'upcomingList' && (
              <OnDemandUpcomingList
                sectionId={selectedSection.SectionID}
                courseId={course?.courseId}
                sectionName={selectedSection.SectionName}
                sectionDescription={selectedSection.SectionDescription}
              />
            )}

            {selectedView === 'viewedList' && (
              <OnDemandViewedList
                sectionId={selectedSection.SectionID}
                courseId={course?.courseId}
                sectionName={selectedSection.SectionName}
                sectionDescription={selectedSection.SectionDescription}
              />
            )}

            </div>
            
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default OnDemandSection;
