import React, { useState } from "react";
import "./CalendarEvents.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";

import { useCourse } from "context/context";
import { userTestSeries } from "services/test";
import {
  countArray,
  getNextDates,
  getPreviousDays,
  groupBy,
  simpleDateFormat,
} from "config/utils";
import TestNotFound from "components/Not-Found/TestNotFound";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { errorResponse, failResponse } from "config/config";
const CalendarEvents = () => {
  const { course } = useCourse();

  const [allTests, setAllTests] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);

  // get test series
  const getTestSeries = async (
    startDate = days[0],
    endDate = days[days.length - 1]
  ) => {
    try {
      setLoading(true);
      const data = {
        testTabType: "calenderview",
        courseIds: [course.courseId],
        currentDate: startDate,
        endDate,
      };

      const res = await userTestSeries(data);
      if (res.code === 200) {
        setAllTests(res.data.testList || []);
        setIsNext(res.data.next);
        setIsPrev(res.data.previous);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  const dataFilterHandler = (date) => {
    const groupedData = groupBy(allTests, "scheduleDate");
    let finalArr = groupedData.find((data) => date === data.date);
    return finalArr?.data || [];
  };

  //   next button handler
  const nextButtonHandler = () => {
    setDays(getNextDates(days[days.length - 1]) || []);
    getTestSeries(
      getNextDates(days[days.length - 1])[0],
      getNextDates(days[days.length - 1])[7]
    );
  };

  //   previous button handler
  const previousButtonHandler = () => {
    setDays(getPreviousDays(days[0]) || []);
    getTestSeries(getPreviousDays(days[0])[0], getPreviousDays(days[0])[7]);
  };

  // --------------- getting all test series of list view data ----------------//
  React.useLayoutEffect(() => {
    // calls all the master functions
    if (course.courseId && days.length > 0) {
      getTestSeries();
    }

    // eslint-disable-next-line
  }, [course, days]);

  // --------------- getting 7 days ----------------//
  React.useLayoutEffect(() => {
    setDays(getNextDates() || []);
  }, []);

  return (
    <>
      <div className="event_calendar">
        <div className="calendar_title">
          <div className="control_buttons">
            <button
              className="dashboard_button has_arrow prev"
              onClick={previousButtonHandler}
              disabled={!isPrev}
            >
              <FaChevronLeft />
              Prev
            </button>
            <button
              className="dashboard_button has_arrow next"
              onClick={nextButtonHandler}
              disabled={moment(days[days.length - 1]) >= moment() && !isNext}
            >
              Next <FaChevronRight />
            </button>
          </div>
          {/* <button type="button" className="dashboard_button">
            <FaCalendarAlt /> Calendar
          </button> */}
        </div>
      </div>

      <div className="calender_wrapper">
        <ul className="week_days">
          {days &&
            days.map((date, i) => {
              return (
                <li key={i}>
                  <div
                    className={`calendar_head ${
                      date === simpleDateFormat() && "active"
                    }`}
                  >
                    <b> {moment(date).format("DD")}</b>
                    <span className="dayName">
                      {moment(date).format("MMM")}
                    </span>
                    <span className="dayName">
                      {moment(date).format("ddd")}
                    </span>
                  </div>

                  {/* loading comp */}
                  {loading && (
                    <>
                      <ul className="days_events">
                        {countArray(1).map((num) => {
                          return (
                            <li key={num}>
                              <CourseSkeleton width="100%" height={100} />
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}

                  {/* main component */}
                  {!loading && allTests?.length > 0 && (
                    <ul className="days_events">
                      {dataFilterHandler(date).map((data, j) => {
                        return (
                          <li key={j}>
                            <p>{data.testDetails?.TestTitle}</p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
        {/* not found comp */}
        {!loading && allTests?.length <= 0 && <TestNotFound title="Tests" />}
      </div>
    </>
  );
};

export default CalendarEvents;
