import React from 'react'
import "./OtherPages.scss";
import { Accordion } from "react-bootstrap";
import { AiOutlineSearch } from 'react-icons/ai';
import FAQ from 'components/FAQs/FAQs';

// function FAQPage() {
//   return (
//     <div className="terms_condition faqPage spacing">
//        <div className='container'>
//             <div className='page_search'>
//                 <div className='search_box'>
//                     <input type='search' placeholder='Search' />
//                     <AiOutlineSearch />
//                 </div>
//             </div>
//             <FAQ />
//        </div>
//     </div>
//   )
// }
function FAQPage() {
  return (
    // <div className="terms_condition faqPage spacing">
    <div className="faqs_section spacing">
      <div className="container">
        <div className="faqs">
          <div className="page_title padding">
            <h3>FAQ’S</h3>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="24">
              <Accordion.Header>{"What is the foundation course offered at the Delhi Centre? "}</Accordion.Header>
              <Accordion.Body>{"The foundation course at our main Delhi Centre is designed to provide comprehensive coverage of the entire syllabus with 600+ hours of lectures, focusing on conceptual clarity and immersive classroom experiences."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="25">
              <Accordion.Header>{"How long are the daily classes?"}</Accordion.Header>
              <Accordion.Body>{"Daily classes are 2.5 hours long."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{"How does the course ensure conceptual clarity?"}</Accordion.Header>
              <Accordion.Body>{"The course focuses on conceptual clarity through detailed lectures, face-to-face interactive doubt sessions with teachers, and focused answer writing sessions for General Studies."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{"What support materials are provided?"}</Accordion.Header>
              <Accordion.Body>{"Quality class notes are provided by the faculty, along with personal mentorship for effective strategy development."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{"Is there any special program for answer writing?"}</Accordion.Header>
              <Accordion.Body>{"Yes, there are focused answer writing sessions for General Studies through a weekly class test program."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>{"How does the online foundation course work?"}</Accordion.Header>
              <Accordion.Body>{"The online foundation course offers daily 2.5-hour classes with live and recorded lectures, providing comprehensive coverage of the syllabus in 600+ hours. It includes 3x views for each lecture, allowing you to watch them at your convenience."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>{"How are doubts cleared in the online program?"}</Accordion.Header>
              <Accordion.Body>{"Doubt clearing sessions are held via Zoom after each class."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>{"Are there any additional benefits to the online program?"}</Accordion.Header>
              <Accordion.Body>{"Along with the comprehensive syllabus coverage and focus on conceptual clarity, the online program offers personal mentorship and focused answer writing sessions similar to the classroom program."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>{"What does the regional foundation course entail?"}</Accordion.Header>
              <Accordion.Body>{"Similar to the other programs, the regional foundation course includes 600+ hours of lectures for comprehensive syllabus coverage, focused on conceptual clarity and critical thinking, in a student-friendly environment at our High Tech Centre."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>{"Are the classes online or offline?"}</Accordion.Header>
              <Accordion.Body>{"The classes are conducted offline, immersing students in the sights and sounds of a classroom environment."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>{"What additional support is provided in the regional program?"}</Accordion.Header>
              <Accordion.Body>{"The program includes quality class notes, focused answer writing sessions, and a student-friendly environment that facilitates critical thinking."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>{"How does the regional program differ from the Delhi Centre classroom program?"}</Accordion.Header>
              <Accordion.Body>{"While both programs offer immersive classroom experiences and comprehensive syllabus coverage, the regional program emphasizes a high-tech classroom environment and is specifically designed to cater to students in that region, possibly with more localized support and resources."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>{"Can I prepare for General Studies, Optional Subject, and CSAT simultaneously for the CSE-2025 exam?"}</Accordion.Header>
              <Accordion.Body>{"Yes, you can prepare for all these components simultaneously. We offer a range of courses tailored to each segment of the Civil Services Examination, including General Studies, Optional Subject, Essay, and CSAT. While these courses are separate, they are designed to comprehensively cover all aspects of the exam."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>{"What types of programs do you offer for CSE preparation?"}</Accordion.Header>
              <Accordion.Body>{"We prepare students for success across all three levels of the Civil Services Examination: the UPSC Preliminary Test, UPSC Main Examination, and Personality Test. Our programs include classroom and LIVE-Online courses for General Studies, Optional Subjects, Essay writing, and CSAT."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>{"When does the General Studies (PCM) comprehensive program start?"}</Accordion.Header>
              <Accordion.Body>{"The first batch for our General Studies (PCM) comprehensive program is scheduled to start in February 2024. This program is specially designed to give students a thorough understanding of the General Studies required for the CSE."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>{"How can I prepare for my Optional Subject for the CSE-2025?"}</Accordion.Header>
              <Accordion.Body>{"Our Optional Subjects Program is set to begin in June 2024 and October 2024. You can choose a batch that suits your preparation schedule. These programs are detailed and cater to a wide range of popular optional subjects."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>{"What about the preparation for CSAT?"}</Accordion.Header>
              <Accordion.Body>{"We have planned CSAT batches to start every two months, beginning from June 2024 until March 2025. This scheduling provides flexibility for students to join at a time that best fits their preparation timeline."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>{"Can I choose to enroll in individual courses based on my needs?"}</Accordion.Header>
              <Accordion.Body>{"Absolutely. Students have the flexibility to enroll in individual courses based on their convenience and specific preparation needs. Whether you need to focus on General Studies, an Optional Subject, Essay writing, or CSAT, you can select the course that best fits your requirements."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>{"Are there any online options available for these courses?"}</Accordion.Header>
              <Accordion.Body>{"Yes, we offer LIVE-Online programs for all our courses, providing a flexible option for students who prefer or require remote learning. These online courses are designed to deliver the same level of comprehensive preparation as our classroom programs."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>{"Can I change my batch timing after enrolling in the G.S (PCM) Classroom Program?"}</Accordion.Header>
              <Accordion.Body>{"Yes, you can request a change in your batch timing. To do so, submit an application to the administrative officer at our office. The possibility of accommodating your request depends on the availability of seats in the desired batch."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="22">
              <Accordion.Header>{"What should I do if I encounter slow internet or the website crashes when trying to register for a classroom course?"}</Accordion.Header>
              <Accordion.Body>
                {"Here are some steps you can follow to mitigate this issue:"}
                <ul>
                  <li>Ensure you are connected to a stable internet connection before starting the online registration process.</li>
                  <li>If you experience slowness, try refreshing the page and attempt to register again.</li>
                  <li>Have all your basic educational details and required documents prepared in advance in the specified format to expedite the registration process.</li>
                  <li>If technical issues persist, such as slow internet or webpage problems, please contact our office directly for assistance.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>{"How can I switch from a Classroom/Offline batch to an Online mode?"}</Accordion.Header>
              <Accordion.Body>{"To switch your learning mode from Classroom/Offline to Online, you need to submit an application along with your I-Card to the administrative officer. Once your application is approved, your portal will be updated to reflect the change within 24-48 hours."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="21">
              <Accordion.Header>{"What happens if my application for a batch change is not approved?"}</Accordion.Header>
              <Accordion.Body>{"If your application for a batch change is not approved due to reasons such as full capacity in the desired batch, we encourage you to discuss alternative arrangements with the administrative officer. Our team will do our best to find a satisfactory solution that accommodates your needs."}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="23">
              <Accordion.Header>{"Are there any tips for ensuring a smoother online registration process?"}</Accordion.Header>
              <Accordion.Body>
                {"Yes, to ensure a smoother registration process, follow these tips:"}
                <ul>
                  <li>Use a reliable and fast internet connection.</li>
                  <li>Prepare all necessary information and documents in advance.</li>
                  <li>Be patient and try refreshing the page if the server appears slow.</li>
                  <li>Contact our support team immediately if you encounter any significant issues during the process.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>


          </Accordion>

        </div>
      </div>
    </div>


  )
}

export default FAQPage

