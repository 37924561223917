import React, { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Footer from "components/footer/Footer";
import NormalHeader from "components/header/Normal/Header";
import UserHeader from "components/header/User/Header";
import Announcement from "pages/annoucement/Annoucement";
import CenterLanding from "pages/center-landing/CenterPage";
import CompareChosenCourse from "pages/course/CompareCourse";
import CourseDetails from "pages/course/CourseDetail";
import CourseListing from "pages/course/CourseListing";
import Login from "pages/forms/Login";
import ResetPassword from "pages/forms/RestPassword";
import SignUp from "pages/forms/SignUp";
import Articles from "pages/free-resources/articles/Articles";
import FreeResource from "pages/free-resources/FreeResource";
import ClasseSubject from "pages/free-resources/PDF-Notes/ClasseSubject";
import ClassesSubjectPdf from "pages/free-resources/PDF-Notes/ClasseSubjectPdf";
import DownloadCourse from "pages/free-resources/DownloadCourse";
import PDFNotes from "pages/free-resources/PDF-Notes/Notes";
import NewsArticlesDetail from "pages/free-resources/shubh-bharat/NewsArticlesDetail";
import NewsArticlesListing from "pages/free-resources/shubh-bharat/NewsArticlesListing";
import ShubhBharatTimes from "pages/free-resources/shubh-bharat/ShubhBharatTimes";
import VideoResource from "pages/free-resources/video-resource/VideoResource";
import Home from "pages/home/Home";
import OurStory from "pages/ourstory/OurStory";


import FreeResources from "../components/free-resources/FreeResources";

import FRAboutUPSC from "../components/free-resources/AboutUPSC/AboutUPSC";
import FRAboutUPSCContent from "../components/free-resources/AboutUPSC/Content";
import FRAboutUPSCEligibility from "../components/free-resources/AboutUPSC/Eligibility";

import FRPrelims from "../components/free-resources/Prelims/Prelims";
import FRPrelimsSyllabus from "../components/free-resources/Prelims/Syllabus";
import FRPrelimsPYQPrelims from "../components/free-resources/Prelims/PYQPrelims";
import FRPrelimsPrelimsStrategy from "../components/free-resources/Prelims/PrelimsStrategy";
import FRPrelimsHotTopicsforPrelims from "../components/free-resources/Prelims/HotTopicsforPrelims";

import FRMains from "../components/free-resources/Mains/Mains";
import FRMainsSyllabus from "../components/free-resources/Mains/Syllabus";
import FRMainsEssay from "../components/free-resources/Mains/Essay";
import FRMainsPYQMains from "../components/free-resources/Mains/PYQMains";
import FRMainsHotTopicsforMains from "../components/free-resources/Mains/HotTopicsforMains";
import FRMainsGS1 from "../components/free-resources/Mains/GS1";
import FRMainsGS2 from "../components/free-resources/Mains/GS2";
import FRMainsGS3 from "../components/free-resources/Mains/GS3";
import FRMainsGS4 from "../components/free-resources/Mains/GS4";

import FROptional from "../components/free-resources/Optional/Optional";
import FROptionalPSIR from "../components/free-resources/Optional/PSIR";
import FROptionalHistory from "../components/free-resources/Optional/History";
import FROptionalSociology from "../components/free-resources/Optional/Sociology";
import FROptionalGeography from "../components/free-resources/Optional/Geography";

import FRInterview from "../components/free-resources/Interview/Interview";
import FRInterviewToppersInterview from "../components/free-resources/Interview/ToppersInterview";
import FRInterviewTopInterviews from "../components/free-resources/Interview/TopInterviews";
import FRInterviewToppersList from "../components/free-resources/Interview/ToppersList";
import FRInterviewPanelList from "../components/free-resources/Interview/PanelList";



import Offline from "pages/Offline/Offline";
import TeacherProfile from "pages/teacher-profile/TeacherProfile";
import Workshop from "pages/workshop/Workshop";
import ArticlesPDFListing from "pages/free-resources/articles/ArticlesPDFListing";
import ToppersCopies from "pages/free-resources/toppers-copies/ToppersCopies";
import Magazines from "pages/free-resources/magazines/Magazine";
import Blogs from "pages/blogs/Blogs";
import BlogPost from "pages/blogs/BlogPost";
import DailyQuiz from "pages/free-resources/daily-quiz/DailyQuiz";
import PreviousPaper from "pages/free-resources/previous-paper/PreviousPaper";
import Dashboard from "dashboard/user-dashboard/Dashbaord";

import EditProfile from "dashboard/edit-profile/EditProfile";
import UpdateUserProfile from "dashboard/edit-profile/UpdateUserProfile";
import UpdateKYCDetails from "dashboard/edit-profile/UpdateKYCDetails";
import Lecture from "dashboard/lecture/Lecture";
import TestSeries from "dashboard/test-series/TestSeries";
import SubjectPage from "pages/subject-page/SubjectPage";
import TermsCondition from "pages/other-pages/TermsCondition";
import PrivacyPolicy from "pages/other-pages/PrivacyPolicy";
import FAQPage from "pages/other-pages/FAQPage";
import CourseSubscription from "pages/course-subscription/CourseSubscription";
import { isLoggedIn } from "config/utils";
import LectureVideo from "dashboard/lecture/lecture-video/LectureVideo";
import ThankYou from "pages/ThankYou/ThankYou";
import TestPage from "dashboard/test-series/testPage/TestPage";
import TestUnit from "dashboard/test-series/test/TestUnit";
import TestResultSummery from "dashboard/test-series/testPage/TestResultSummery";
import ShubhraMadam from "pages/teacher-profile/ShubharaMadam";
import PaymentSuccess from "pages/payment-msg/PaymentSuccess";
import PaymentFailed from "pages/payment-msg/PaymentFailed";
import EnquiryForm from "components/enquiry-form/EnquiryForm";
import Implementscript from "components/implementscript/Implementscript";
import LiveLectureVideo from "dashboard/liveLecture/LiveLectureVideo";
import Success from "pages/payment-msg/Success";
import Failed from "pages/payment-msg/Failed";
import OnDemandLectureDetail from "dashboard/lecture/on-demand/OnDemandLectureDetail";
import CourseReport from "dashboard/course-report/CourseReport";
import ReUploadAnswerSheet from "dashboard/reupload-answer-sheet/ReUploadAnswerSheet";
import PostalCourseListing from "dashboard/postal-course-document/PostalFolder";
import PostalCourseSubListing from "dashboard/postal-course-document/PostalFile";
import PostalFolderList from "dashboard/postal-course-document/PostalFolder";
import NotFound from "pages/not-found/NotFound";
import { OnDemandViewedList } from "services/lecture";
import OnDemandUpcomingList from "dashboard/lecture/on-demand/section/OnDemandUpcomingList";
import DeleteAccount from "pages/other-pages/DeleteAccount";

import ICICIresponse from "pages/icici-response/ICICIresponse";
import PsirOptionalClassroomCourse from "pages/coursepages/PsirOptionalClassroomCourse";
import PSIRAnswerWritingModule from "pages/coursepages/PSIRAnswerWritingModule";
import PoliticalScienceInternationalRelationsTabletCourse from "pages/coursepages/PoliticalScienceInternationalRelationsTabletCourse";
import PoliticalScienceIROnDemandTabletCourse from "pages/coursepages/PoliticalScienceIROnDemandTabletCourse";
import PSIRPostalCorrespondence from "pages/coursepages/PSIRPostalCorrespondence";
import HistoryOptionalClassroomCourse from "pages/coursepages/HistoryOptionalClassroomCourse";
import HistoryOptionalTabletCourse from "pages/coursepages/HistoryOptionalTabletCourse";
import SociologyClassroomCoursewithTestSeries from "pages/coursepages/SociologyClassroomCoursewithTestSeries";
import SociologyTabletCoursewithTestSeries from "pages/coursepages/SociologyTabletCoursewithTestSeries";
import SociologyOnDemandTabletCourse from "pages/coursepages/SociologyOnDemandTabletCourse";
import SociologyTestSeries from "pages/coursepages/SociologyTestSeries";
import GSPrelimscumMainsClassroomCourse from "pages/coursepages/GSPrelimscumMainsClassroomCourse";
import GSPrelimscumMainsOnlineCourse from "pages/coursepages/GSPrelimscumMainsOnlineCourse";
import QEPGSCrashCourseforPrelims2024ClassroomCourse from "pages/coursepages/QEPGSCrashCourseforPrelims2024ClassroomCourse";
import QEPGSCrashCourseforPrelims2024OnlineCourse from "pages/coursepages/QEPGSCrashCourseforPrelims2024OnlineCourse";
import IntegratedGSandPSIROptionalClassroomCourse from "pages/coursepages/IntegratedGSandPSIROptionalClassroomCourse";
import NCERTFoundationCourse from "pages/coursepages/NCERTFoundationCourse";
import CSATFoundation from "pages/coursepages/CSATFoundation";
import EssayProgram from "pages/coursepages/EssayProgram";
import IntegratedGSandSociologyOptionalClassroomCourse from "pages/coursepages/IntegratedGSandSociologyOptionalClassroomCourse";
import IntegratedGSandHistoryOptionalClassroomCourse from "pages/coursepages/IntegratedGSandHistoryOptionalClassroomCourse";
import IntegratedGSandPSIROptionalTabletCourse from "pages/coursepages/IntegratedGSandPSIROptionalTabletCourse";
import IntegratedGSandSociologyOptionalTabletCourse from "pages/coursepages/IntegratedGSandSociologyOptionalTabletCourse";
import IntegratedGSandHistoryOptionalTabletCourse from "pages/coursepages/IntegratedGSandHistoryOptionalTabletCourse";
import GSMentorshipProgramme from "pages/coursepages/GSMentorshipProgramme";
import GSMentorshipOnlineProgramme from "pages/coursepages/GSMentorshipOnlineProgramme";
import PoliticalScienceandIRMentorshipProgramme from "pages/coursepages/PoliticalScienceandIRMentorshipProgramme";
import PoliticalScienceandIRMentorshipOnlineProgramme from "pages/coursepages/PoliticalScienceandIRMentorshipOnlineProgramme";
import PoliticalScienceandInternationalRelationsVideoBatch from "pages/coursepages/PoliticalScienceandInternationalRelationsVideoBatch";
import PolitythroughPYQsbyShubhraRanjan from "pages/coursepages/PolitythroughPYQsbyShubhraRanjan";

import OurToppers from "dashboard/our-toppers/OurToppers";
import MyToken from "pages/GenerateToken/MyToken";
import PaymentLinkSubscription from "pages/PaymentLinkSubs/PaymentLinkSubscription";
import PaymentLinkExpired from "pages/PaymentLinkSubs/PaymentLinkExpired";
import LectureByNotesNew from "dashboard/lecture/Section/LectureByNotesNew";
//import { Freeresources } from "assets/images";
// import ApexLecture from "../../src/components/video-player/ApexLecture";

//protected routes
const ProtectedRoute = ({ children }) => {
  if (!isLoggedIn()) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
};

const NormalHeaderOutlet = () => {
  return (
    <>
      <div>
        <NormalHeader />
        <Outlet />
        {/* button for enquiry */}
        <EnquiryForm />

        <Implementscript />
        <Footer />
      </div>
    </>
  );
};

const NormalHeaderwithoutFooterOutlet = () => {
  return (
    <>
      <div>
        <NormalHeader />
        <Outlet />
        {/* button for enquiry */}
        <EnquiryForm />

        <Implementscript />
      </div>
    </>
  );
};

const UserHeaderOutlet = ({ courseDrawerShow, setCourseDrawerShow }) => {
  return (
    <>
      <div>
        <UserHeader
          courseDrawerShow={courseDrawerShow}
          setCourseDrawerShow={setCourseDrawerShow}
        />
        <Outlet />
        {/* <Footer /> */}
      </div>
    </>
  );
};

const WithoutHeader = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const Routing = () => {
  const [courseDrawerShow, setCourseDrawerShow] = useState(false);
  return (
    <section className="routing">
      <Routes basename="/">
        {/* normal Header routes */}
        <Route exact path="/" element={<NormalHeaderOutlet />}>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          {/* <Route
            path="/:centerName/:type/:centerId"
            element={<CenterLanding />}
          /> */}
          <Route
            path="/:centerId"
            element={<CenterLanding />}
          />
          <Route
            path="/course-listing/:type?/:categoryName?/:categoryId?/:subCategoryName?/:subCategoryId?"
            element={<CourseListing />}
          />
          <Route path="/teacher/:name/:id" element={<TeacherProfile />} />
          <Route path="/course-detail/:name/:id/:centerid?" element={<CourseDetails />} />
          <Route path="/daily-quiz" element={<DailyQuiz />} />
          <Route path="/ourstory" element={<OurStory />} />

          <Route path="/offline" element={<Offline />} />

          <Route path="/my-token" element={<MyToken />} />
          <Route path="icici-transaction-response" element={<ICICIresponse />} />
          <Route path="terms-and-condition" element={<TermsCondition />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faqs" element={<FAQPage />} />
          {/* <Route path="/delete-account" element={<DeleteAccount/>}/> */}

          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/success" element={<Success />} />
          <Route path="/payment-failed" element={<PaymentFailed />} />
          <Route path="/failed" element={<Failed />} />
          <Route path="/affiliate-payment-link/:btransactionid/:admissionid" element={<PaymentLinkSubscription />} />
          <Route path="/payment-link-expired" element={<PaymentLinkExpired />} />


          <Route path="/psir-optional-classroom-course" element={<PsirOptionalClassroomCourse />} />
          <Route path="/psir-answer-writing-module" element={<PSIRAnswerWritingModule />} />
          <Route path="/psir-optional-online-course" element={<PoliticalScienceInternationalRelationsTabletCourse />} />
          <Route path="/psir-on-demand-tablet-course" element={<PoliticalScienceIROnDemandTabletCourse />} />
          <Route path="/psir-postal-correspondence-course" element={<PSIRPostalCorrespondence />} />
          <Route path="/history-optional-classroom-course" element={<HistoryOptionalClassroomCourse />} />
          <Route path="/history-optional-online-course" element={<HistoryOptionalTabletCourse />} />
          <Route path="/sociology-optional-classroom-course" element={<SociologyClassroomCoursewithTestSeries />} />
          <Route path="/sociology-optional-online-course" element={<SociologyTabletCoursewithTestSeries />} />
          <Route path="/sociology-on-demand-tablet-course" element={<SociologyOnDemandTabletCourse />} />
          <Route path="/sociology-test-series" element={<SociologyTestSeries />} />
          <Route path="/gs-foundation-classroom-course" element={<GSPrelimscumMainsClassroomCourse />} />
          <Route path="/gs-foundation-online-course" element={<GSPrelimscumMainsOnlineCourse />} />
          <Route path="/qep-gs-crash-course-classroom" element={<QEPGSCrashCourseforPrelims2024ClassroomCourse />} />
          <Route path="/qep-gs-crash-course-online" element={<QEPGSCrashCourseforPrelims2024OnlineCourse />} />
          <Route path="/ncert-foundation-course" element={<NCERTFoundationCourse />} />
          <Route path="/csat-foundation-course" element={<CSATFoundation />} />
          <Route path="/essay-program" element={<EssayProgram />} />

          <Route path="/Integrated-GS-and-PSIR-Optional-Classroom-Course" element={<IntegratedGSandPSIROptionalClassroomCourse />} />
          <Route path="/Integrated-GS-and-Sociology-Optional-Classroom-Course" element={<IntegratedGSandSociologyOptionalClassroomCourse />} />
          <Route path="/Integrated-GS-and-History-Optional-Classroom-Course" element={<IntegratedGSandHistoryOptionalClassroomCourse />} />
          <Route path="/Integrated-GS-and-PSIR-Optional-Online-Course" element={<IntegratedGSandPSIROptionalTabletCourse />} />
          <Route path="/Integrated-GS-and-Sociology-Optional-Online-Course" element={<IntegratedGSandSociologyOptionalTabletCourse />} />
          <Route path="/Integrated-GS-and-History-Optional-Online-Course" element={<IntegratedGSandHistoryOptionalTabletCourse />} />
          <Route path="/gs-mentorship-program" element={<GSMentorshipProgramme />} />
          <Route path="/gs-mentorship-online-program" element={<GSMentorshipOnlineProgramme />} />
          <Route path="/psir-mentorship-program" element={<PoliticalScienceandIRMentorshipProgramme />} />
          <Route path="/psir-mentorship-online-program" element={<PoliticalScienceandIRMentorshipOnlineProgramme />} />
          <Route path="/psir-optional-video-batch" element={<PoliticalScienceandInternationalRelationsVideoBatch />} />
          <Route path="/polity-pyq-course" element={<PolitythroughPYQsbyShubhraRanjan />} />

          {/* <Route path="/qep-gs-crash-course-online" element={<IntegratedGSandPSIROptionalClassroomCourse />} />
  */}

          {/* <Route path="/compare-course" element={<CompareChosenCourse />} /> */}
          {/* <Route path="/teacher/shubhra-madam" element={<ShubhraMadam />} />
          <Route path="/free-resources" element={<FreeResource />} />
          <Route path="/free-resources/pdfnotes" element={<PDFNotes />} />
          <Route
            path="/free-resources/pdfnotes/class-subjects"
            element={<ClasseSubject />}
          />
          <Route
            path="/free-resources/pdfnotes/class-subjects/subjectpdf"
            element={<ClassesSubjectPdf />}
          />
          <Route
            path="/free-resources/pdfnotes/class-subjects/subjectpdf/download"
            element={<DownloadCourse />}
          />
          <Route path="/free-resources/videos" element={<VideoResource />} />
          <Route
            path="/free-resources/bhubhbharat-times"
            element={<ShubhBharatTimes />}
          />
          <Route
            path="/free-resources/bhubhbharat-times/listing"
            element={<NewsArticlesListing />}
          />
          <Route
            path="/free-resources/bhubhbharat-times/listing/detail"
            element={<NewsArticlesDetail />}
          />
          <Route path="/free-resources/articles" element={<Articles />} />
          <Route
            path="/free-resources/articles/listing"
            element={<ArticlesPDFListing />}
          />
          <Route
            path="/free-resources/toppers-copies"
            element={<ToppersCopies />}
          />
          <Route path="/free-resources/magazines" element={<Magazines />} /> */}
          {/* <Route
            path="/free-resources/previous-papers"
            element={<PreviousPaper />}
          />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/announcement" element={<Announcement />} /> */}
          {/* <Route path="/blogs" element={<Blogs />} /> */}
          {/* <Route path="/blog/detail" element={<BlogPost />} />
          <Route path="subjectpage" element={<SubjectPage />} /> */}

          <Route
            exact
            path="/course-subscription/:courseName/:courseId/:centerid?"
            element={
              <ProtectedRoute>
                <CourseSubscription />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route exact path="/" element={<NormalHeaderwithoutFooterOutlet />}>
          <Route path="/freeresources" element={<FreeResources />}>
            {/* Free Resources first sections Prelims */}

            <Route path="FRAboutUPSC" element={< FRAboutUPSC />} />
            <Route path="FRAboutUPSCContent" element={< FRAboutUPSCContent />} />
            <Route path="FRAboutUPSCEligibility" element={< FRAboutUPSCEligibility />} />

            <Route path="FRPrelims" element={<FRPrelims />} />
            <Route path="FRPrelimsSyllabus" element={<FRPrelimsSyllabus />} />
            <Route path="FRPrelimsPYQPrelims" element={<FRPrelimsPYQPrelims />} />
            <Route path="FRPrelimsPrelimsStrategy" element={<FRPrelimsPrelimsStrategy />} />
            <Route path="FRPrelimsHotTopicsforPrelims" element={<FRPrelimsHotTopicsforPrelims />} />

            {/* Free Resources second sections Mains */}
            <Route path="FRMains" element={<FRMains />} />
            <Route path="FRMainsSyllabus" element={<FRMainsSyllabus />} />
            <Route path="FRMainsEssay" element={<FRMainsEssay />} />
            <Route path="FRMainsPYQMains" element={<FRMainsPYQMains />} />
            <Route path="FRMainsHotTopicsforMains" element={<FRMainsHotTopicsforMains />} />
            <Route path="FRMainsGS1" element={<FRMainsGS1 />} />
            <Route path="FRMainsGS2" element={<FRMainsGS2 />} />
            <Route path="FRMainsGS3" element={<FRMainsGS3 />} />
            <Route path="FRMainsGS4" element={<FRMainsGS4 />} />

            {/* Free Resources third sections Optional */}
            <Route path="FROptional" element={<FROptional />} />
            <Route path="FROptionalPSIR" element={<FROptionalPSIR />} />
            <Route path="FROptionalHistory" element={<FROptionalHistory />} />
            <Route path="FROptionalSociology" element={<FROptionalSociology />} />
            <Route path="FROptionalGeography" element={<FROptionalGeography />} />

            <Route path="FRInterview" element={<FRInterview />} />
            <Route path="FRInterviewToppersInterview" element={<FRInterviewToppersInterview />} />
            <Route path="FRInterviewTopInterviews" element={<FRInterviewTopInterviews />} />
            <Route path="FRInterviewToppersList" element={<FRInterviewToppersList />} />
            <Route path="FRInterviewPanelList" element={<FRInterviewPanelList />} />

          </Route>
        </Route>



        {/* user header routes */}
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute>
              <UserHeaderOutlet
                courseDrawerShow={courseDrawerShow}
                setCourseDrawerShow={setCourseDrawerShow}
              />
            </ProtectedRoute>
          }
        >




          <Route
            exact
            path="/dashboard/home"
            element={
              <ProtectedRoute>
                <Dashboard
                  courseDrawerShow={courseDrawerShow}
                  setCourseDrawerShow={setCourseDrawerShow}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/profile"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/update-user-profile"
            element={
              <ProtectedRoute>
                <UpdateUserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/update-kyc-details"
            element={
              <ProtectedRoute>
                <UpdateKYCDetails />
              </ProtectedRoute>
            }
          />

          {/* <Route
            exact
            path="/dashboard/lecture/:sectionId?"
            element={
              <ProtectedRoute>
                <Lecture />
              </ProtectedRoute>
            }
          /> */}
          <Route
            exact
            path="/dashboard/lecture/:courseid?"
            element={
              <ProtectedRoute>
                <Lecture />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/lecture-notes"
            element={
              <ProtectedRoute>
                <LectureByNotesNew />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/lecture/detail/:lectureId"
            element={
              <ProtectedRoute>
                <LectureVideo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/live-lecture/detail/:lectureId"
            element={
              <ProtectedRoute>
                <LiveLectureVideo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/demand-lecture/detail/:sectionId/:lectureId"
            element={
              <ProtectedRoute>
                <OnDemandLectureDetail />
              </ProtectedRoute>
            }
          />


          <Route
            exact
            path="/dashboard/test-series"
            element={
              <ProtectedRoute>
                <TestSeries />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/dashboard/test-series/detail/:testId"
            element={
              <ProtectedRoute>
                <TestPage />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/dashboard/test-series/detail/:testId/test-overview"
            element={
              <ProtectedRoute>
                <TestResultSummery />
              </ProtectedRoute>
            }
          />

          {/* Postal Course Documents */}

          <Route
            exact
            path="/dashboard/postal-folder-listing"
            element={
              <ProtectedRoute>
                <PostalFolderList />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/postal-folder-listing/:id/"
            element={
              <ProtectedRoute>
                <PostalCourseSubListing />
              </ProtectedRoute>
            }
          />

          <Route exact path="/dashboard/report" element={<CourseReport />} />
          <Route
            exact
            path="/dashboard/re-upload-answer-sheet"
            element={<ReUploadAnswerSheet />}
          />
          <Route exact path="/dashboard/toppers-copies" element={<OurToppers />} />
          {/* <Route exact path="/dashboard/videos" element={<ApexLecture />} /> */}
        </Route>



        <Route exact path="/" element={<WithoutHeader />}>
          <Route
            path="/dashboard/test-series/detail/:testId/test"
            element={<TestUnit />}
          />
        </Route>

        {/* <Route path="/delete-account" element={<DeleteAccount/>} /> */}
        <Route exact path="/" element={<WithoutHeader />}>
          <Route
            path="/delete-account"
            element={<DeleteAccount />}
          />
        </Route>

      </Routes>



    </section>
  );
};

export default Routing;
