import React from "react";
import LectureCalendarEvents from "components/calendar-events/LectureCalenderEvent";

const CalenderViewLectures = () => {
  return (
    <>
      <LectureCalendarEvents />
    </>
  );
};

export default CalenderViewLectures;
