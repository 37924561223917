import React from "react";
import CalendarEvents from "components/calendar-events/CalendarEvents";

const CalenderViewTest = () => {
  return (
    <div>
      {/* Calendar */}
      <CalendarEvents />
    </div>
  );
};

export default CalenderViewTest;
