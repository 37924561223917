import { charLimit } from "config/utils";
import React, { useRef, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import {
  getMasterCourseCategories,
  getMasterCourseSubCategories,
} from "services/master";
import { Foundation } from "assets/icons/index";
import { errorResponse, failResponse } from "config/config";
import { getAllCourses } from "services/courses/courses";


const CourseMenu = () => {
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });

  const [onHover, setOnHover] = useState(false);
  const menuRef = useRef();

  //  all category
  React.useEffect(() => {
    // get course category
    const getCourseCategory = async () => {
      try {
        const res = await getMasterCourseCategories();
        if (res.code === 200) {
          setAllCategories(res.data.courseCategories || []);
          const { id, name } = res.data?.courseCategories?.[0];
          setSelectedCategory({ id, name });
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    getCourseCategory();
  }, []);

  // //   subcategory dependent filter
  // React.useEffect(() => {
  //   // get sub categories
  //   const getSubCategory = async () => {
  //     try {
  //       const res = await getMasterCourseSubCategories({
  //         courseTypeId: selectedCategory?.id,
  //       });
  //       if (res.code === 200) {
  //         setAllSubCategory(res.data.courseSubCategories || []);
  //       } else {
  //         failResponse(res);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //     }
  //   };
  //   selectedCategory?.id && getSubCategory();
  // }, [selectedCategory]);

  React.useEffect(() => {
    // get courses
    const getCourses = async () => {
      try {
        const res = await getAllCourses({
          language: "EN",
          page: 1,
          examType: [],
          courseType: [],
          stateId: [],
          courseCategory: selectedCategory.id,
          CourseSubCategory: '', // No need to specify CourseSubCategory
          search: "",
          platformType: "chrome",
          //courseTypeId: selectedCategory?.id,
        });
        if (res.code === 200) {
          setAllSubCategory(res.data.MaincourseSubCategories.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    selectedCategory?.id && getCourses();
  }, [selectedCategory]);


  return (
    <>
      <li
        className={`has-menu ${onHover ? "active" : ""}`}
        ref={menuRef}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <NavLink to="" className="nav_link">
        Recommended Courses
          <span className="icon">
            <MdKeyboardArrowDown />
          </span>
        </NavLink>

        <div className="megamenu course">
          <Tab.Container id="left-tabs-example">
            <Row>
              <Col sm={3} className="menu_left">
                <Nav variant="pills" className="flex-column">
                   
                  {                  
                  allCategories &&
                    allCategories.map(({ name, id }) => {
                      return (
                        <Nav.Item
                          key={id}
                          onClick={() => setSelectedCategory({ id, name })}
                        >
                          <Nav.Link
                            className={`nav_item ${
                              id === selectedCategory.id && "active"
                            }`}
                          >
                            <img src={Foundation} alt="" loading="lazy" />
                            <p>
                              <b>{name}</b>
                             
                            </p>
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </Col>
              {selectedCategory?.id && (
                <Col sm={9} className="menu_right">
                  <div className="megamenu_content row">
                    {/* sub category listing */}
                    {allSubCategory &&
                      allSubCategory.map(
                        ({ id, name, courseCategory, description }) => {
                          return (
                            <div className="col" key={id}>
                              <h5>
                                <Link
                                  onClick={() =>
                                    menuRef.current.classList.remove("active")
                                  }
                                  to={`/course-listing/course/${selectedCategory?.name}/${selectedCategory?.id}/${name}/${id}`}
                                >
                                  {name}
                                </Link>
                                <span>{charLimit(description)}</span>
                              </h5>
                              {/* <ul>
                                          <li>
                                            <Link to="">{name}</Link>
                                          </li>
                                        </ul> */}
                            </div>
                          );
                        }
                      )}

                    <div className="button_wrap">
                      <Link
                        onClick={() =>
                          menuRef.current.classList.remove("active")
                        }
                        to={`/course-listing/course/${selectedCategory?.name}/${selectedCategory?.id}`}
                        className="button"
                      >
                        View {selectedCategory?.name} 
                        <span className="icon">
                          <BsArrowRight />
                        </span>
                      </Link>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Tab.Container>
        </div>
      </li>
    </>
  );
};

export default CourseMenu;
