import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { useCourse } from "context/context";
import React from "react";
import { Tab } from "react-bootstrap";
import { getUserProgressData } from "services/dashboard";
import DatePicker from "react-datepicker";
import { convertMinutesToHoursMinutesSeconds, countArray } from "config/utils";
import { errorResponse, failResponse } from "config/config";
import { clockBlue, clockRed } from "assets/images";

const ProgressBar = () => {
  const [loading, setLoading] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    allocateTime: "",
    totalSpent: "",
    todayConsumed: "",
  });
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const { course } = useCourse();

  // ----------------------- getting progress chart data -------------------------//
  React.useLayoutEffect(() => {
    // get users live lecture
    const getProgressData = async () => {
      try {
        setLoading(true);
        const res = await getUserProgressData({
          courseId: course.courseId,
          currentDate: selectedDate,
        });

        if (res.code === 200) {
          setChartData(
            {
              allocateTime: res.data.allocatedTime,
              totalSpent: res.data.totalspend,
              todayConsumed: res.data.todayConsumed,
            } || {}
          );
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };
    if (course.courseId) {
      getProgressData();
    }
  }, [course, selectedDate]);

  return (
    <>
      <div className="_progress">
        <div className="content">
          <Tab.Container id="left-tabs-example">
            <div className="D_flex today_spent">
              <span>Today Spent time</span>
              <b className="time_tag">
                {convertMinutesToHoursMinutesSeconds(
                  chartData.todayConsumed || 0
                )}
              </b>
            </div>
            <div className="_progress_card">
              <div className="D_flex">
                <img src={clockBlue} alt="" />
                <div className="content_inner">
                  <h6>
                    {" "}
                    {convertMinutesToHoursMinutesSeconds(
                      chartData.allocateTime || 0
                    )}
                  </h6>
                  <span className="time_tag">Total Allocated time</span>
                </div>
              </div>
              <div className="D_flex">
                <img src={clockRed} alt="" />
                <div className="content_inner">
                  <h6>
                    {" "}
                    {convertMinutesToHoursMinutesSeconds(
                      chartData.totalSpent || 0
                    )}
                  </h6>
                  <span className="time_tag">Total Spent time</span>
                </div>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
