import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// __________ live Lecture video __________
export const liveLectureList = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/live/lecture/list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// __________ live Lecture detail video __________
export const liveLectureDetail = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/live/lecture/details?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};
