import { TestpageImg } from "assets/images";
import React, { useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import "./TestPage.scss";
import { ProgressBar } from "react-bootstrap";
import { Userimg } from "assets/icons";
import { useParams } from "react-router-dom";
import {
  generateTestResult,
  prelimsTestResult,
  userTestSeriesDetail,
} from "services/test";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import {
  countArray,
  getMarkPercentage,
  momentDateFormat,
  timeFormatWith12Hour2,
} from "config/utils";
import { useCourse } from "context/context";
import useButtonLoader from "hooks/useButtonLoader";
import { errorResponse, failResponse } from "config/config";
import { getSignedUrl } from "services/master";

function TestResultSummery() {
  const { testId } = useParams();
  const [loading, setLoading] = useState(true);
  const [studentData, setStudentData] = useState({});
  const [topperData, setTopperData] = useState({});
  const [firsttopped, setfirsttopped] = useState({});

  const { course } = useCourse();

  const [testData, setTestData] = useState({});
  const [testDetails, setTestDetails] = useState({});

  const [submitButtonRef, setSubmitLoading] = useButtonLoader(
    `${!testData.resultPdfUrl ? "View Result" : "Download"} `
  );

  // ----------------------- getting test result pdf -------------------------//
  const requestResultHandler = async (status) => {
    if (status) {
      setSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: status }));
      setSubmitLoading(false);
    } else {
      try {
        setSubmitLoading(true);
        const res = await generateTestResult({
          SectionTestID: testId,
          language: "English",
        });

        if (res.code === 200) {
          getTestDetails();
          setSubmitLoading(false);
        } else {
          failResponse(res);
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    }
  };

  // get test series detail
  const getTestDetails = async () => {
    try {
      setLoading(true);
      const res = await userTestSeriesDetail({
        courseId: course.courseId,
        testId,
      });

      if (res.code === 200) {
        setTestDetails(res.data.testDetails || {});
        setTestData(res.data || {});
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // ----------------------- getting test result data -------------------------//
  React.useLayoutEffect(() => {
    // get test result
    const getTestResult = async () => {
      try {
        setLoading(true);
        const res = await prelimsTestResult({
          SectionTestID: testId,
        });

        if (res.code === 200) {
          setStudentData(res.data.student || {});
          setTopperData(res.data.topper || {});
          setfirsttopped(res.data.firsttopped || {});
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    if (course.courseId) {
      getTestDetails();
      getTestResult();
    }
    // eslint-disable-next-line
  }, [course]);

  // stop to go previous page
  React.useEffect(() => {
    const handleDisableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
      };
    };

    handleDisableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <div className="tesResult_summery testPage spacing">
      <div className="container">
        {/************** loading components **************/}
        {loading && <ListCourseSkeleton height="300" />}

        {/************** main components **************/}
        {!loading && (
          <div className="testPage_banner">
            <div className="image_wrap">
              <img src={TestpageImg} alt="" loading="lazy" />
            </div>
            <div className="content">
              <h4>{testDetails.TestTitle}</h4>

              <ul>
                <li>
                  <FaRegCalendarAlt /> {momentDateFormat(testData.scheduleDate)}
                </li>
              </ul>
              {testDetails.isDownloadable && (
                <button
                  className="dashboard_button red fill"
                  onClick={() => requestResultHandler(testData.resultPdfUrl)}
                  ref={submitButtonRef}
                >
                  {`${!testData.resultPdfUrl ? "View Result" : "Download"} `}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="testOverview_content spacing">
        <div className="container">
          <div className="overview">
            <h5>Overview</h5>

            <ul>
              {/************** loading components **************/}
              {loading &&
                countArray(8).map((data) => {
                  return (
                    <li key={data} className="p-0">
                      <ListCourseSkeleton height="90" />
                    </li>
                  );
                })}

              {/************** main components **************/}
              {!loading && (
                <>
                  <li>
                    <h6>TOTAL MARKS</h6>
                    <p>
                      <b>{studentData?.totalMark}</b> /
                      <sub>{studentData?.testMark} Marks</sub>
                    </p>
                  </li>
                  <li>
                    <h6>CORRECT</h6>
                    <p>
                      <b>{studentData?.correct}</b> /<sub>Questions</sub>
                    </p>
                  </li>
                  <li>
                    <h6>INCORRECT</h6>
                    <p>
                      <b>{studentData?.incorrect}</b> /<sub> Questions</sub>
                    </p>
                  </li>
                  <li>
                    <h6>UNANSWERED</h6>
                    <p>
                      <b>{studentData?.unattempt}</b> /<sub> Questions</sub>
                    </p>
                  </li>
                  <li>
                    <h6>PERCENTILE</h6>
                    <p>
                      <b>
                        {/* {getMarkPercentage(
                          studentData?.markGained,
                          studentData?.testMark
                        )} */}
                        {studentData?.percentile}
                        %
                      </b>
                    </p>
                  </li>
                  <li>
                    <h6>ATTEMPTED STUDENTS</h6>
                    <p>
                      <b>{studentData?.attempted}</b>
                    </p>
                  </li>
                  <li>
                    <h6>SUBMIT DATE</h6>
                    <p>
                      <b>{momentDateFormat(studentData?.submittedOn)}</b>
                    </p>
                  </li>
                  <li>
                    <h6>SUBMIT TIME</h6>
                    <p>
                      <b>{timeFormatWith12Hour2(studentData?.submittedOn)}</b>
                    </p>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="compare_progress spacing">
            {/************** loading components **************/}
            {loading &&
              countArray(2).map((data) => {
                return (
                  <div className="student_you p-0" key={data}>
                    <ListCourseSkeleton height="300" />
                  </div>
                );
              })}

            {/************** main components **************/}
            {!loading && (
              <>
                <div className="student_you">
                  <div className="image_wrap">
                    <img src={Userimg} alt="" loading="lazy" />
                    <h5>You</h5>
                  </div>
                  <ul>
                    <li>
                      <p>
                        <span>MARKS GAINED</span>{" "}
                        <span>{studentData?.markGained}</span>
                      </p>
                      <ProgressBar
                        className="success"
                        now={studentData?.markGained}
                      />
                    </li>

                    <li>
                      <p>
                        <span>MARKS LOST</span>{" "}
                        <span>{studentData?.markLost}</span>
                      </p>
                      <ProgressBar
                        className="danger"
                        now={studentData?.markLost}
                      />
                    </li>

                    <li>
                      <p>
                        <span>TOTAL MARKS</span>
                        <span>{studentData?.totalMark}</span>
                      </p>
                      <ProgressBar
                        className="danger"
                        now={studentData?.totalMark}
                      />
                    </li>
                  </ul>
                </div>
                <div className="student_topper">
                  <div className="image_wrap">
                    <img src={Userimg} alt="" loading="lazy" />
                    <h5>Topper</h5>
                  </div>
                  <ul>
                    <li>
                      <p>
                        <span>MARKS GAINED</span>
                        <span>{firsttopped?.markGained}</span>
                      </p>
                      <ProgressBar
                        className="success"
                        now={firsttopped?.markGained}
                      />
                    </li>

                    <li>
                      <p>
                        <span>MARKS LOST</span>{" "}
                        <span>{firsttopped?.markLost}</span>
                      </p>
                      <ProgressBar
                        className="danger"
                        now={firsttopped?.markLost}
                      />
                    </li>

                    <li>
                      <p>
                        <span>TOTAL MARKS</span>
                        <span>{firsttopped?.totalMark}</span>
                      </p>
                      <ProgressBar
                        className="danger"
                        now={firsttopped?.totalMark}
                      />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestResultSummery;
