import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Drawer.scss";
function Drawer({ show, handleClose, children, title, className }) {
	return (
		<div className="drawer">
			<Offcanvas className={`drawer ${className} ${ title ? "":"withoutTitle"}`} show={show} onHide={handleClose} placement="end">
				<Offcanvas.Header closeButton>{title && <Offcanvas.Title>{title}</Offcanvas.Title>}</Offcanvas.Header>
				<Offcanvas.Body>{children}</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
}

export default Drawer;
