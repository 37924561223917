import React from "react";
import { Placeholder } from "react-bootstrap";

const TeacherDetailSkeleton = () => {
  return (
    <>
      <div className="profile_banner spacing">
        <div className="container">
          <h3>Teacher profile</h3>
          <div className="teacher_card">
            <div className="teacher_image">
              <Placeholder
                as="div"
                animation="wave"
                aria-hidden="true"
                className="h-100 w-100"
              >
                <Placeholder
                  xs={12}
                  style={{ height: `330px` }}
                  className="rounded"
                />
              </Placeholder>
            </div>
            <div className="content">
              <h4>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
              </h4>
              <p>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
                <Placeholder as="div" animation="wave" aria-hidden="true">
                  <Placeholder xs={12} className="rounded" />
                </Placeholder>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherDetailSkeleton;
