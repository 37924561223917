import React, { useEffect } from "react";
import { useNavigate, useNavigationType } from "react-router-dom";

const Success = () => {
  let navigate = useNavigate();
  const navType = useNavigationType();

  useEffect(() => {
    if (navType !== "PUSH") {
      navigate("/payment-success", { state: { paymentStatus: true } });
    }
  }, [navType, navigate]);
  return <></>;
};

export default Success;
