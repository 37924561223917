import CommonModal from "components/modal-box/CommonModal";
import { encryptData, errorResponse, normalDecryptData } from "config/config";
import {
  getBrowserName,
  getLocalTrackingTimeLecture,
  setLocalTrackingTimeLecture,
} from "config/utils";
import { useCourse } from "context/context";
import useCookie from "hooks/useCookie";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getUserIp } from "services/third-party";
 

import io from "socket.io-client";

let API_HIT_TIME = 60000;

const CipherTimeTracking = ({ videoRef, isAPIReady, lastSeekBar = 0, lctSession }) => {
  const [socket, setSocket] = useState(null);
 
  const { cookies, removeCookie } = useCookie();

  const { lectureId } = useParams();
  const { course } = useCourse();
  const navigate = useNavigate();

  let localTrackingData =
    getLocalTrackingTimeLecture(course.courseId, lectureId, "shub-playtime") ||
    0;

  let localTrackingCoverData =
    getLocalTrackingTimeLecture(
      course.courseId,
      lectureId,
      "shub-cover-time"
    ) || 0;

  const ipRef = useRef(0);

  const trackingRef = useRef(0);
  const timeRef = useRef(+localTrackingData || 0);

  const localDataRef = useRef(0);

  const trackingCoverRef = useRef(0);
  const timeCoverRef = useRef(+localTrackingCoverData || 0);

  const [, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);

  const [stopModal, setStopModal] = useState(false);

  //   time tracking api
  const timeTrackerHandler = async (
    playtime,
    coverTime,
    lastSeekBar,
    play = 1
  ) => {
    try {
      let finalPlayTime = 0;
      // removed previous saved time from actual time
      if (play === 0) {
        finalPlayTime = +playtime - +localDataRef.current;
      }
      // if time is getting negative value then assign this to positive
      if (finalPlayTime <= 0) {
        finalPlayTime = finalPlayTime * -finalPlayTime;
      }

      if (!ipRef.current) {
        const { data: ip } = await getUserIp();
        ipRef.current = ip.ip;
      }

      const payload = encryptData({
        Platform: "web",
        courseId: course.courseId,
        IPAddress: ipRef.current,
        playTime: finalPlayTime >= 0 ? finalPlayTime : 0,
        TotalCoverdTime: coverTime,
        play,
        TotalPlayedTime: finalPlayTime >= 0 ? finalPlayTime : 0,
        lastPlay: lastSeekBar,
        lectureId: normalDecryptData(lectureId, "route"),
        platformType: getBrowserName(),
        access_token: cookies,
        lctSession,
      });

       // send data to db
      socket.emit("lectureTimeTracking", payload);
      // assign play time if video is paused
      if (play === 0) {
        localDataRef.current = playtime;
      }
      // clear all states n values
      trackingRef.current = 0;
      trackingCoverRef.current = 0;
      setLocalTrackingTimeLecture(
        course.courseId,
        lectureId,
        "shub-playtime",
        0
      );
      setLocalTrackingTimeLecture(
        course.courseId,
        lectureId,
        "shub-cover-time",
        0
      );

      socket.on("response", (data) => {
        if (data.code === 402) {
          removeCookie();
          sessionStorage.removeItem("course");
          navigate("/");
          toast.error(data.message, { id: "fail" });
        }
        if (data.code === 401) {
          setStopModal(true);
          player.video.pause();
          return;
        }
        if (data?.data?.VideoPercantage >= 100) {
          toast.success(`You consumed your 100% allocated time.`);
          navigate("/dashboard/lecture");
        }
      });

      // const res = await lectureTimeTracking({
      //   Platform: "web",
      //   courseId: course.courseId,
      //   IPAddress: ip.ip,
      //   playTime: finalPlayTime >= 0 ? finalPlayTime : 0,
      //   TotalCoverdTime: coverTime,
      //   TotalPlayedTime: finalPlayTime >= 0 ? finalPlayTime : 0,
      //   lastPlay: lastSeekBar,
      //   lectureId: normalDecryptData(lectureId, "route"),
      // });

      // if (res.code === 200) {
      //   localDataRef.current = playtime;
      //   trackingRef.current = 0;
      //   trackingCoverRef.current = 0;
      //   setLocalTrackingTimeLecture(
      //     course.courseId,
      //     lectureId,
      //     "shub-playtime",
      //     0
      //   );
      //   setLocalTrackingTimeLecture(
      //     course.courseId,
      //  chatUserList   lectureId,
      //     "shub-cover-time",
      //     0
      //   );
      //   if (res.data.VideoPercantage >= 100) {
      //     toast.success(`You consumed your 100% allocated time.`);
      //     navigate("/dashboard/lecture");
      //   }
      // } else {
      //   failResponse(res);
      // }
    } catch (err) {
      errorResponse(err);
    }
  };

  //   load the player
  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      setPlayer(null);
      return;
    }

    let timer = null;

    // play event
    const playEvent = () => {
      setStatus("Playing");
      timeTrackerHandler(
        +trackingRef.current + +timeRef.current,
        +trackingCoverRef.current + +timeCoverRef.current,
        player.video.currentTime,
        1
      );
      // player.video.currentTime = lastSeekBar;
    };

    // pause event
    const pauseEvent = () => {
      setStatus("Paused");
      timeTrackerHandler(
        +trackingRef.current + +timeRef.current,
        +trackingCoverRef.current + +timeCoverRef.current,
        player.video.currentTime,
        0
      );
    };

    // time update event
    const timeUpdateEvent = () => {
      // playtime
      window?.player.api.getTotalPlayed().then((time) => {
        trackingRef.current = time;
        setLocalTrackingTimeLecture(
          course.courseId,
          lectureId,
          "shub-playtime",
          trackingRef.current
        );
      });

      // cover time
      window?.player.api.getTotalCovered().then((time) => {
        trackingCoverRef.current = time;
        setLocalTrackingTimeLecture(
          course.courseId,
          lectureId,
          "shub-cover-time",
          trackingCoverRef.current
        );
      });

      // call event for token checking
      socket.emit("checkUser", {
        reqData: encryptData({ access_token: cookies }),
      });

      socket.on("userResponse", (data) => {
        if (data.code === 402) {
          removeCookie();
          sessionStorage.removeItem("course");
          navigate("/");
          toast.error(data.message, { id: "fail" });
        }
      });

      if (!timer) {
        timer = setTimeout(() => {
          timeTrackerHandler(
            +trackingRef.current + +timeRef.current,
            +trackingCoverRef.current + +timeCoverRef.current,
            player.video.currentTime,
            0
          );
          timer = null;
        }, API_HIT_TIME);
      }

      // timeTrackerHandler(
      //   +trackingRef.current + +timeRef.current,
      //   +trackingCoverRef.current + +timeCoverRef.current,
      //   player.video.currentTime
      // );
    };

    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    player.video.addEventListener("play", playEvent);
    player.video.addEventListener("pause", pauseEvent);

    // player.video.addEventListener("canplay", () => setStatus("Ready"));
    player.video.addEventListener("timeupdate", timeUpdateEvent);
    window.player = player;

    return () => {
      player.video.removeEventListener("pause", pauseEvent);
      player.video.removeEventListener("play", playEvent);
      player.video.removeEventListener("timeupdate", timeUpdateEvent);
    };

    // eslint-disable-next-line
  }, [isAPIReady, videoRef]);

  // ----------------------- disabled inspect and right click-------------------------//
  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };

  // event for disable right click
  useEffect(() => {
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    return () =>
      document.removeEventListener(
        "contextmenu",
        (e) => {
          e.preventDefault();
        },
        false
      );
  }, []);

  // setup socket instance
  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(socketInstance);

    // listen for events emitted by the server

    socketInstance.on("connect", () => {
      // console.log("Connected to server");
    });

    // socketInstance.on("message", (data) => {
    //   // console.log(`Received message: ${data}`);
    // });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  // call api every 30 sec
  // useEffect(() => {
  //   const intervalId = setInterval(
  //     () =>
  //       timeTrackerHandler(
  //         +trackingRef.current + +timeRef.current,
  //         +trackingCoverRef.current + +timeCoverRef.current,
  //         player?.video?.currentTime
  //       ),
  //     API_HIT_TIME
  //   );
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  return player && player.video ? (
    <>
      <div className="api-controls inline">
        {/* <div>Status: {status}</div> */}
        {/* <div>CurrentTime: {currentTime}</div> */}
      </div>

      {/* stop modal */}
      <CommonModal
        className=""
        show={stopModal}
        handleClose={() => {
          navigate("/dashboard/lecture");
        }}
        size="md"
        closeIcon={false}
      >
        <div className="logout">
          <span className="logout_icon">
            <FaExclamationCircle />
          </span>
          <h3>Alert !</h3>
          <p>this lecture is already playing</p>

          <div className="button_wrap center">
            <button
              className="dashboard_button green"
              onClick={() => navigate("/dashboard/lecture")}
            >
              OK
            </button>
          </div>
        </div>
      </CommonModal>
    </>
  ) : null;
};

export default CipherTimeTracking;
