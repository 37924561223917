import React from "react";
import { VideoLectures } from "assets/images";

const LectureNotFound = ({ title = "", image, note = "" }) => {
  return (
    <>
      <div className="listing_card not_found p-0">
        <div className="not_fount_image">
          {image ? (
            <img src={image} alt="" loading="lazy" />
          ) : (
            <img src={VideoLectures} alt="" loading="lazy" />
          )}
        </div>
        {note ? (
          <h5 style={{fontWeight:"bold"}}>{note}</h5>
        ) : (
          <h5 style={{fontWeight:"bold"}}>{title} are not available </h5>
        )}
      </div>
    </>
  );
};

export default LectureNotFound;
