import React, { useState } from "react";

import { Link } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { toast } from "react-hot-toast";
import {
  countArray,
  momentDateFormat,
  timeFormatWith12Hour,
} from "config/utils";
import {
  applyExceptionalLecture,
  exceptionalLectureList,
} from "services/lecture";
import { useCourse } from "context/context";
import CommonModal from "components/modal-box/CommonModal";
import useButtonLoader from "hooks/useButtonLoader";
import { FcVideoFile } from "react-icons/fc";
import Pagination from "react-js-pagination";
import { errorResponse, failResponse } from "config/config";

const ExceptionalList = () => {
  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);
  const [availableLectures, setAvailableLectures] = useState({
    total: 0,
    applied: 0,
  });
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState("");

  const [buttonRef, setButtonLoading] = useButtonLoader("Request");

  // get course lectures
  const getLecturesList = async () => {
    try {
      setLoading(true);
      const res = await exceptionalLectureList({
        type: 1,
        course_id: course.courseId,
        page: activePage,
      });

      if (res.code === 200) {
        setListLectures(res.data.courseLectures || []);
        setTotal(res.data.count);
        setAvailableLectures({
          total: res.data.totalExceptionalCount ?? 0,
          applied: res.data.appliedCount ?? 0,
        });
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  // this function for confirm modal
  const openConfirmModal = (id, isApplied) => {
    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (+availableLectures?.applied === +availableLectures?.total) {
      toast.error("You exceed the limit of exceptional");
      return;
    }

    if (isApplied === 1) {
      toast.error("you can't request again for this lecture");
    } else {
      setSelectedLecture(id);
      setConfirmModal(true);
    }
  };

  // apply exceptional lecture
  const applyLectureHandler = async () => {
    try {
      setButtonLoading(true);
      const res = await applyExceptionalLecture({
        course_id: course.courseId,
        lecture_id: selectedLecture,
      });
      if (res.code === 200) {
        toast.success(res.message);
        getLecturesList();
        setConfirmModal(false);
      } else {
        failResponse(res);
        setButtonLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setButtonLoading(false);
    }
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useLayoutEffect(() => {
    if (course.courseId) {
      getLecturesList();
    }
    // eslint-disable-next-line
  }, [course, activePage]);
  return (
    <>
      <div className="daily_lecture">
        <p className="w-100 text-right">
          Your Exceptional lecture summary: &nbsp;
          <span className="red">
            {availableLectures?.applied}/{availableLectures?.total}
          </span>
        </p>
        <div className="meeting_content">
          <ul>
            {/* ************ Loading Component *********** */}
            <li className="lecture-card heading">
              <h6>Lecture Name</h6>

              <div className="date">
                <b>Start Date</b>
              </div>
              <div className="date">
                <b>End Date</b>
              </div>

              <div className="date">
                <b>Class Notes</b>
              </div>
            </li>
            {loading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <li key={data} className="p-0">
                      <ListCourseSkeleton height="90" />
                    </li>
                  );
                })}
              </>
            )}
            {/* iterate lectures */}

            {!loading && listLectures.length > 0 ? (
              listLectures.map(
                ({
                  LectureDetail,
                  scheduledate,
                  lectureenddate,
                  isAppliedForException,
                  lecturetime,
                  lecturetitle,
                }) => {
                  return (
                    <li key={LectureDetail?.LectureID}>
                      <h6>
                        <Link
                        // to={`/dashboard/lecture/detail/${normalEncryptData(
                        //   LectureDetail?.LectureID,
                        //   "route"
                        // )}`}
                        >
                          {lecturetitle || "N/A"}
                        </Link>
                      </h6>
                      <div className="play_btn">
                        {/* <Link
                          to={`/dashboard/lecture/detail/${normalEncryptData(
                            LectureDetail?.LectureID,
                            "route"
                          )}`}
                        >
                          <span className="play-icon">
                            <BsFillPlayFill />
                          </span>
                        </Link> */}
                      </div>
                      <div className="date">
                        <b>{`${momentDateFormat(scheduledate)}`}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>
                      <div className="date red">
                        <b>{`${momentDateFormat(lectureenddate)} `}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>

                      <div className="date">
                        <button
                          className="button py-2"
                          disabled={
                            isAppliedForException === 1 ||
                            +availableLectures?.applied ===
                              +availableLectures?.total
                          }
                          onClick={() =>
                            openConfirmModal(
                              LectureDetail?.LectureID,
                              isAppliedForException
                            )
                          }
                        >
                          Request
                        </button>
                      </div>
                    </li>
                  );
                }
              )
            ) : (
              <>
                <LectureNoteFound title="Lectures" />
              </>
            )}
          </ul>
        </div>
      </div>
      {/* pagination */}
      {total > 10 ? (
        <div className="pagination_wrap">
          <Pagination
            activePage={activePage}
            previousLabel={"previous"}
            nextLabel={"next"}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            activeLinkClass={"active"}
            onChange={(page) => setActivePage(page)}
          />
        </div>
      ) : null}

      {/* confirm modal */}
      <CommonModal
        show={confirmModal}
        handleClose={() => setConfirmModal(false)}
        size="md"
      >
        <div className="logout">
          <span className="logout_icon">
            {/* <BsPersonVideo3 /> */}
            <FcVideoFile />
          </span>
          <h4>Request to exceptional lecture</h4>
          <p>
            Note:Exceptional Access for the lecture is valid for 24 hr after
            access is taken
          </p>

          <div className="button_wrap center">
            <button
              className="dashboard_button"
              onClick={() => setConfirmModal(false)}
            >
              No
            </button>
            <button
              className="dashboard_button green"
              onClick={applyLectureHandler}
              ref={buttonRef}
            >
              Request
            </button>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default ExceptionalList;
