import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import React from "react";
import { MapMarker, Phoneicon } from "assets/icons";

const containerStyle = {
  width: "100%",
  height: "100%",
};

var myStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];
const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  styles: myStyles,
  zoom: 15,

};

const CenterMap = ({ centerData }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    // googleMapsApiKey: 'AIzaSyBaDsfclrKAGL6vT8ObDgRPqWDKs4Lc1Xg',
  });

  const [map, setMap] = React.useState(null);

  const onMapLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: +centerData?.latitude || 28.535517,
      lng: +centerData?.longitude || 77.391029,
    });
    map.fitBounds(bounds);

    setMap(map);
    // eslint-disable-next-line
  }, []);

  const onMarkerLoad = (marker) => {};
  return (
    <>
      <div className="cetner_address_section spacing">
        <div className="container">
          <div className="page_title padding">
            <h3>Centre Address</h3>
          </div>

          <div className="center_address">
            <div className="address_card">
              <div className="map">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: +centerData?.latitude || 28.535517,
                      lng: +centerData?.longitude || 77.391029,
                    }}
                    zoom={mapOptions.zoom}
                  onLoad={(map) => {
                    map.setOptions({styles: myStyles});
                  }}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <MarkerF
                      onLoad={onMarkerLoad}
                      position={{
                        lat: +centerData?.latitude || 28.535517,
                        lng: +centerData?.longitude || 77.391029,
                      }}
                    />
                  </GoogleMap>
                ) : (
                  <CourseSkeleton height={300} />
                )}
              </div>
              <div className="address">
                <h3>{centerData.CenterName}</h3>

                <h5>{centerData.City}</h5>
                <ul>
                  <li>
                    <img src={MapMarker} alt="" loading="lazy" />
                    <span>{centerData.Address}</span>
                  </li>
                  <li>
                    <img src={Phoneicon} alt="" loading="lazy" />
                    <a href={`tel:${centerData.Phone1}`}>{centerData.Phone1}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CenterMap;
