import React, { Component } from "react";

class VideoCipherPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoObject: null,
    };
  }

  componentDidMount() {
    if (window.VdoPlayer) {
      return this.loadPlayer();
    }
    const playerScript = document.createElement("script");
    playerScript.src =
      "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js";
    document.body.appendChild(playerScript);
    playerScript.addEventListener("load", () => {
      return this.loadPlayer();
    });
  }

  loadPlayer() {
    window.playerContainer = document.getElementById("cipher-player");
    window.playerInstance = new window.VdoPlayer({
      otp: this.props.otp,
      playbackInfo: this.props.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      container: document.getElementById("cipher-player"),
    });

    // // Access the VdoPlayer instance and attach the timeupdate event

    // // Access the VdoPlayer instance and attach the timeupdate event
    // playerInstance.play("timeupdate", (currentTime) => {
    //   this.setState({ currentTime }); // Update the state with the current time
    // });
  }

  handleContextMenu = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div
        className="player"
        id="cipher-player"
        onContextMenu={this.handleContextMenu} // Add event listener to disable context menu
      ></div>
    );
  }
}

export default VideoCipherPlayer;