import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// get test series
// export const userTestSeries = async (data = {}) => {
//   const token = isLoggedIn();
//   return decryptData(
//     await axios.get(
//       `/test/series/list?reqData=${encryptData({
//         ...data,
//         platformType: getBrowserName(),

//         access_token: token,
//       })}`
//     )
//   );
// };
export const userTestSeries = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/series/list-new?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),

        access_token: token,
      })}`
    )
  );
};


// get test series types
export const userTestType = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/type?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),

        access_token: token,
      })}`
    )
  );
};

// get test series detail
export const userTestSeriesDetail = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/series/details?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get test series questions
export const userTestSeriesQuestions = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/series/question?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// attempt mcq test series questions
export const userAttemptMCQTestSeries = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/test/series/mcq/answer`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// attempt mains test questions
export const userAttemptMainsTest = async (data = {}, options) => {
  return decryptData(
    await axios.post(`/test/series/upload/answer`, data, options)
  );
};

// prelims test question result
export const prelimsTestResult = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `test/series/mcq/result?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// prelims test question result generate pdf
export const generateTestResult = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `test/series/mcq/result/pdf?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};
