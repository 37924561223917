import React from "react";
import { Accordion } from "react-bootstrap";
import "./FAQ.scss";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { countArray } from "config/utils";

const FAQ = ({ faqs = [], loading }) => {
  return (
    <div className="faqs_section spacing">
      <div className="container">
        <div className="faqs">
          <div className="page_title padding">
            <h3>FAQ’S</h3>
          </div>
          <Accordion defaultActiveKey="0">
            {/* loading skeleton */}
            {loading &&
              countArray(4).map((data) => {
                return (
                  <Accordion.Item key={data}>
                    <ListCourseSkeleton height="80" />
                  </Accordion.Item>
                );
              })}

            {/* faq unavailable */}
            {!loading && faqs.length <= 0 && (
              <div className="mw-100 justify-content-center">
                <h5>No FAQ's Available</h5>
              </div>
            )}

            {/* iterate all faqs */}
            {!loading &&
              faqs.length > 0 &&
              faqs.map(({ question, answer }, i) => {
                return (
                  <Accordion.Item eventKey={i} key={i}>
                    <Accordion.Header>{question}</Accordion.Header>
                    <Accordion.Body>{answer}</Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
