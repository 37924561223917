import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { errorResponse, failResponse, customMessage, normalEncryptData } from "config/config";
import {
  countArray,
  lectureAccess,
  lectureIsExpired,
  lectureIsStarted,
  momentDateFormat,
  stringAvatar,
  timeFormatWith12Hour,
} from "config/utils";
import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { AiOutlineDownload } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { userLectureSubjectView } from "services/dashboard";
import LectureNotFound from "components/Not-Found/LectureNotFound";
import { useCourse } from "context/context";
import Pagination from "react-js-pagination";
import { getSignedUrl } from "services/master";

const SubjectByLectures = () => {
  const [allSubjects, setAllSubjects] = useState([]);
  const [allSubSubjects, setAllSubSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedSubSubject, setSelectedSubSubject] = useState({});
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();
  const { course } = useCourse();

  const [submitLoading, setSubmitLoading] = useState(false);

  // navigation handler
  const navigationHandler = (id, startDate, endDate, time) => {
    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (lectureIsStarted(startDate, time)) {
      toast.error("Lecture is not started");
      return;
    }
    if (lectureIsExpired(endDate, time)) {
      toast.error("Lecture is expired");
      return;
    }
    if (lectureAccess(startDate, endDate, time)) {
      navigate(`/dashboard/lecture/detail/${normalEncryptData(id, "route")}`, {
        state: { auth: true },
      });
    }
  };

  // notes download handler
  const notesDownloadHandler = async (notes, startDate, endDate, time) => {
    if (lectureAccess(startDate, endDate, time)) {
      setSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: notes }));
      setSubmitLoading(false);
    } else {
      toast.error("you can't download notes");
    }
  };

  const subjectSelection = (id, name, subSubjects) => {
    setSelectedSubject({
      name,
      id,
    });
    setAllSubSubjects(subSubjects || []);
    setSelectedSubSubject({
      id: subSubjects?.[0]?.SubjectID,
      name: subSubjects?.[0]?.SubjectTitle,
    });
  };

  //   api call with subject id
  React.useEffect(() => {
    // get subjects
    const getSubjectsLecture = async () => {
      try {
        setLoading(true);
        const res = await userLectureSubjectView({
          subjectId: selectedSubject.id,
          subSubjectId: selectedSubSubject.id,
          courseId: course.courseId,
          page: activePage,
        });

        setLoading(false);

        if (res.code === 200) {
          setLectures(res.data.lectures.rows || []);
          setTotal(res.data.lectures.count || 0);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    selectedSubject?.id && getSubjectsLecture();

    // eslint-disable-next-line
  }, [selectedSubject, selectedSubSubject, activePage]);

  React.useEffect(() => {
    // get subjects
    const getAllSubjects = async () => {
      try {
        setSectionLoading(true);
        const res = await userLectureSubjectView({ courseId: course.courseId });

        setSectionLoading(false);
        if (res.code === 200) {
          if (res.data.lectures.count > 0) {
              setLectures(res.data.lectures || []);
              setAllSubjects(res.data.allSubjectList || []);
              const subject = res.data.allSubjectList?.[0];
              setSelectedSubject(
                  { id: subject?.SubjectID, name: subject?.SubjectTitle } || {}
              );
              const subSubjects = subject?.subSubjects || [];
              if (subSubjects.length > 0) {
                  const subSubject = subSubjects[0];
                  setAllSubSubjects(subSubjects);
                  setSelectedSubSubject(
                      { id: subSubject?.SubjectID, name: subSubject?.SubjectTitle } || {}
                  );
              } else {
                  // Handle case where subSubjects is empty
                  setSelectedSubSubject({});
                  return null;
              }
          } else {
              customMessage("There are no lectures defined for this course");
          }
      } else {
          failResponse(res);
      }
      
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    course.courseId && getAllSubjects();

    // eslint-disable-next-line
  }, [course]);

  return (
    <>
      <Tab.Container id="SubjectList" className="">
        <div className="dashboard_tab subject_list">
          <Nav variant="pills">
            {/* {/ loading component /} */}
            {sectionLoading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <Nav.Item key={data} className="w-25">
                      <Nav.Link className="p-0">
                        <ListCourseSkeleton height={40} />
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </>
            )}

            {/* {/ main component /} */}
            {!sectionLoading && allSubjects?.length > 0 && (
              <>
                {
                  // iterate subjects
                  allSubjects.map(
                    ({ SubjectID, SubjectTitle, subSubjects }) => {
                      return (
                        <Nav.Item
                          key={SubjectID}
                          onClick={() => {
                            subjectSelection(
                              SubjectID,
                              SubjectTitle,
                              subSubjects
                            );
                          }}
                        >
                          <Nav.Link
                            eventKey={SubjectID}
                            className={
                              selectedSubject.id === SubjectID && "active"
                            }
                          >
                            {SubjectTitle}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    }
                  )
                }
              </>
            )}

            {/* {/ not found component /} */}
            {!sectionLoading && allSubjects?.length <= 0 && (
              <>
                <LectureNotFound title="Lectures" />
              </>
            )}
          </Nav>

          <Nav variant="pills">
            {/* {/ main component /} */}
            {!sectionLoading && allSubSubjects?.length > 0 && (
              <>
                {/* {/ iterate sub subject /} */}
                {allSubSubjects.map(({ SubjectID, SubjectTitle }) => {
                  return (
                    <Nav.Item
                      key={SubjectID}
                      onClick={() =>
                        setSelectedSubSubject({
                          name: SubjectTitle,
                          id: SubjectID,
                        })
                      }
                    >
                      <Nav.Link
                        eventKey={SubjectID}
                        className={
                          selectedSubSubject.id === SubjectID && "active"
                        }
                      >
                        {SubjectTitle}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </>
            )}
          </Nav>

          {selectedSubject.id && (
            <div className="meeting_content">
              <div className="subject_name">
                <strong>
                  {stringAvatar(
                    selectedSubSubject.name || selectedSubject.name
                  )}
                </strong>
                <h5>
                  {selectedSubSubject.name || selectedSubject.name}
                  {/* {/ <span>10 Chapter</span> /} */}
                </h5>
              </div>
              <ul className="listings">
                {/* {/ *********** Loading Component ********** /} */}
                {loading && (
                  <>
                    {countArray(3).map((data) => {
                      return (
                        <li key={data} className="p-0">
                          <ListCourseSkeleton height={90} />
                        </li>
                      );
                    })}
                  </>
                )}
                {/* {/ iterate lectures /} */}
                {!loading &&
                  lectures.length > 0 &&
                  lectures.map(
                    ({
                      LectureID,
                      LectureTitle,
                      Duration,
                      teacherName,
                      IsYoutube,
                      vdocipherDetail,
                      Notes,
                      displaydays,
                      startDate,
                      endDate,
                      lecturetime,
                    }) => {
                      return (
                        <li
                          key={LectureID}
                          className={`lecture-card ${
                            !lectureAccess(startDate, endDate, lecturetime) &&
                            "disabled"
                          }`}
                        >
                          {/* <Link
                        to={`/dashboard/lecture/detail/${LectureID}`}
                        className={`image ${IsYoutube ? "has_youtube" : ""}`}
                      >
                        {!IsYoutube ? (
                          <img
                            src={
                              IsYoutube ? IsYoutube : vdocipherDetail?.poster
                            }
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        {IsYoutube ? (
                          <span className="icon">
                            <BsYoutube />
                          </span>
                        ) : (
                          <BsPlayCircleFill />
                        )}
                      </Link> */}

                          <h6
                            onClick={() =>
                              navigationHandler(
                                LectureID,
                                startDate,
                                endDate,
                                lecturetime
                              )
                            }
                          >
                            {LectureTitle || "N/A"}
                          </h6>
                          <div className="play_btn">
                            {/* {/ <Link /} */}
                            <span
                              onClick={() =>
                                navigationHandler(
                                  LectureID,
                                  startDate,
                                  endDate,
                                  lecturetime
                                )
                              }
                            >
                              <span className="play-icon">
                                <BsFillPlayFill />
                              </span>
                              </span>
                            {/* {/ </Link> /} */}
                          </div>
                          <div className="date">
                            <span>Start Date</span>
                            <b>{`${momentDateFormat(startDate)}`}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          <div className="date red">
                            <span>End Date</span>

                            <b>{`${momentDateFormat(endDate)} `}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          {/* <div className="date">
                        <span>Educator</span>
                        <b>{teacherName || "N/A"}</b>
                      </div> */}
                          <div className="date">
                            <span>Duration</span>
                            <b>{Duration} mins</b>
                          </div>
                          <div className="date">
                            <span>Class Notes</span>
                            {Notes ? (
                              <button
                                className="dashboard_button"
                                onClick={() =>
                                  notesDownloadHandler(
                                    Notes,
                                    startDate,
                                    endDate,
                                    lecturetime
                                  )
                                }
                                disabled={
                                  !lectureAccess(
                                    startDate,
                                    endDate,
                                    lecturetime
                                  ) ||
                                  !Notes ||
                                  submitLoading
                                }
                              >
                                <AiOutlineDownload /> Download
                              </button>
                            ) : (
                              <b>N/A</b>
                            )}
                          </div>
                        </li>
                      );
                    }
                  )}

                {/* {/ Not Found /} */}
                {!loading && lectures.length <= 0 && (
                  <>
                    <LectureNotFound title="Lectures" />
                  </>
                )}
              </ul>
            </div>
          )}
          {/* {/ pagination /} */}
          {/* {total > 10 ? (
            <div className="pagination_wrap">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                activeLinkClass={"active"}
                onChange={(page) => setActivePage(page)}
              />
            </div>
          ) : null} */}
        </div>
      </Tab.Container>
    </>
  );
};

export default SubjectByLectures;
