import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from "react-hot-toast";


// Import Paytm and ICICI icons (You can use your preferred method to import icons)
import {
    iciclogo,
    paytmlogo,
  } from "../../../assets/images";

const PaymentPopup = ({ visible, onClose, onSelectPaymentMethod }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handleRadioChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleConfirmPayment = () => {
    if (!selectedPaymentMethod) {
      // Show a toast notification if no payment method is selected
      toast.error('Please select a payment method');
      return;
    }

    onSelectPaymentMethod(selectedPaymentMethod);
  };

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{border:"1px solid #ddd"}}>
        <p>How would you like to pay?</p>
        <ul style={{ listStyleType: 'none', padding: '10px', backgroundColor:"#f9f9f9", border:"1px solid #ddd", borderRadius:'5px' }}>
        <li style={{ display: 'flex', alignItems: 'center', padding: '8px 0', }}>
          <label style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span>
              <input
                type="radio"
                value="Paytm"
                checked={selectedPaymentMethod === 'Paytm'}
                onChange={handleRadioChange}
                style={{ marginRight: '8px' }}
              />
              Paytm
            </span>
            <img
              src={paytmlogo}
              alt="Paytm"
              style={{ width: '70px' }}
            />
          </label>
          </li>
          <li style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
          <label style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span>
              <input
                type="radio"
                value="ICICI"
                checked={selectedPaymentMethod === 'ICICI'}
                onChange={handleRadioChange}
                style={{ marginRight: '8px' }}
              />
              ICICI
            </span>
            <img
              src={iciclogo}
              alt="ICICI"
              style={{ width: '70px' }}
              
            />
          </label>
        </li>
        </ul>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="primary" onClick={handleConfirmPayment} style={{backgroundColor:"#3A69B0", border:"2px solid #3A69B0"}}>
          Proceed Payment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentPopup;
