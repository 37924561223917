import React from "react";
import "./CommonModal.scss";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

function CommonModal({
  show,
  handleClose,
  title,
  children,
  size = "md",
  className,
  onHide,
  closeIcon = true,
}) {
  // const SIZE = { sm: "100px", md: "200px" };

  return (
    <div className="common_modal">
      <Modal
        backdrop="static"
        size={size}
        centered
        show={show}
        onHide={handleClose}
        className={`common_modal ${className}`}
      >
        <div className="modal_content">
          {closeIcon && (
            <span
              className="modal_close_button"
              onClick={onHide || handleClose}
            >
              <FaTimes />
            </span>
          )}
          {title && (
            <div className="modal_title">
              <h4>{title}</h4>
            </div>
          )}
          {children}
        </div>
      </Modal>
    </div>
  );
}

export default CommonModal;
