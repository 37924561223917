import React from "react";

const ApexVideo = ({ src, height = "300", width = "100%" }) => {
  // const handleFullscreen = () => {
  //   const player = document.getElementById("youtube-player");
  //   player.requestFullscreen(); // Enter fullscreen mode
  // };
  // alert("inside the design")
  return (
    <>
      <iframe
        id="youtub-player"
        className="rounded"
        title="Youtube"
        width={width}
        height={height}
        src={src}
        allowFullScreen
      ></iframe>
    </>
  );
};

export default ApexVideo;
