import React from "react";
import { Placeholder } from "react-bootstrap";

const BannerSkeleton = () => {
  return (
    <Placeholder as="div" animation="wave" aria-hidden="true">
      <Placeholder xs={12} style={{ height: "80vh" }} />
    </Placeholder>
  );
};

export default BannerSkeleton;
