import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./payment-msg.scss";
import { SuccessImg } from "assets/images";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useLayoutEffect(() => {
    if (!location?.state?.paymentStatus) {
      navigate("/dashboard/home");
    }
  }, [navigate, location]);

  return (
    <>
      <div className="payment_status spacing success">
        <div className="content">
          <div>
            {/* <div className="icon">
            <FaCheckCircle />
          </div> */}

            <div className="image_wrap">
              <img src={SuccessImg} alt="payment failed" loading="lazy" />
            </div>
            <div className="text-center">
              <h3>Payment Successful !!</h3>
              <p>
                You can <Link to="/dashboard/profile">check invoice</Link> in
                the profile section
              </p>
              <Link to="/dashboard/home" className="button">
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
