import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import { userSendOTP, userVerifyOTP } from "services/auth";
import { Spinner } from "react-bootstrap";
import useButtonLoader from "hooks/useButtonLoader";
import useCookie from "hooks/useCookie";
import { errorResponse, failResponse } from "config/config";
import { fetchToken } from "config/firebase";
import { getBrowserName, getOSDetail } from "config/utils";
import { getUserIp } from "services/third-party";

const OTPVerify = ({ setOtpVerified, signUpData, isEmailVerified }) => {
  const [time, setTime] = useState(30);
  const [time2, setTime2] = useState(30);

  const [mobileOTP, setMobileOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");

  const [mobileLoading, setMobileLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Continue", true);
  const { setCookie } = useCookie();

  // mobile otp handler
  const resendMobileOTPHandler = async () => {
    setMobileOTP("");
    const data = {
      mobile: signUpData.mobile,
      otpType: "mobile",
      type: "signup",
    };
    setMobileLoading(true);
    try {
      const res = await userSendOTP(data);

      if (res.code === 200) {
        toast.success(res.message);
        setMobileLoading(false);
        setTime2(30);
      } else {
        failResponse(res);
        setMobileLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setMobileLoading(false);
    }
  };

  // email otp handler
  const resendEmailOTPHandler = async () => {
    setEmailOTP("");
    const data = {
      email: signUpData.email,
      otpType: "email",
      type: "signup",
    };
    setEmailLoading(true);
    try {
      const res = await userSendOTP(data);

      if (res.code === 200) {
        toast.success(res.message);
        setEmailLoading(false);
        setTime(30);
      } else {
        failResponse(res);
        setEmailLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setEmailLoading(false);
    }
  };

  // verify otp handler
  const verifyOTpHandler = async (e) => {
    e.preventDefault();
    if (mobileOTP.length !== 4 && emailOTP.length !== 4) {
      toast.error("Please Enter OTP");
    } else {
      setSubmitLoading(true);
      try {
        // generating firebase push notification token
        const fcmToken = await fetchToken();

        const { data: ip } = await getUserIp();

        const verifyData = {
          type: "signup",
          UserID: signUpData.userId,
          platformType: getBrowserName(),
          os: getOSDetail(),
          IPaddress: ip.ip,
          mobileOTP,
          fcmToken,
        };
        // if user selected social account
        if (!isEmailVerified) {
          verifyData.emailOTP = emailOTP;
        }
        const res = await userVerifyOTP(verifyData);

        if (res.code === 200) {
          toast.success(res.message);
          setSubmitLoading(false);
          setOtpVerified(true);
          setCookie(res.data.access_token);
          // toast.success("Registration Successfully");
          sessionStorage.removeItem("course");
          // navigate("/");
        } else {
          failResponse(res);
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time2 > 0) {
        setTime2(time2 - 1);
      }

      if (time2 === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time2]);

  // alert before refresh
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      {/* OTP verification */}
      <div className="form_title padding">
        <h3>Help us verify your account </h3>
        <p>Complete the double verification to proceed </p>
      </div>

      {/* hide email otp verification if user selected social signup */}
      {!isEmailVerified && (
        <div className="enter_otp">
          <p className="text">Enter 4-digit OTP received on your email ID</p>
          <OtpInput
            numInputs={4}
            placeholder="0000"
            value={emailOTP}
            onChange={(data) => setEmailOTP(data.replace(/\D/g, ""))}
            className="optINput"
          />
          <p className="resend_button">
            {time === 0 &&
              (emailLoading ? (
                <Spinner animation="border" variant="danger" size="sm" />
              ) : (
                <Link to="" onClick={resendEmailOTPHandler}>
                  Resend OTP
                </Link>
              ))}
            {time !== 0 && <span>{time} secs</span>}
          </p>
        </div>
      )}

      <hr />
      <div className="enter_otp">
        <p className="text">Enter 4-digit OTP received on your mobile number</p>
        <OtpInput
          numInputs={4}
          placeholder="0000"
          value={mobileOTP}
          onChange={(data) => setMobileOTP(data.replace(/\D/g, ""))}
          className="optINput"
        />
        <p className="resend_button">
          {time === 0 &&
            (mobileLoading ? (
              <Spinner animation="border" variant="danger" size="sm" />
            ) : (
              <Link to="" onClick={resendMobileOTPHandler}>
                Resend OTP
              </Link>
            ))}
          {time !== 0 && <span>{time2} secs</span>}
        </p>
      </div>

      <div className="button_wrap">
        <button
          ref={submitButtonRef}
          onClick={verifyOTpHandler}
          type="button"
          className="button"
          disabled={mobileOTP.length !== 4 && emailOTP.length !== 4}
        >
          Continue
          <span className="icon">
            <BsArrowRight />
          </span>
        </button>
      </div>
    </>
  );
};

export default React.memo(OTPVerify);
